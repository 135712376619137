import React, { Component } from "react";
import useFetch from "../hooks/useFetch";
import { makeRequest } from "../MakeReq/makeRequest";
import { useState } from "react";
import "./anim.css"

const About = () => {
  const header_image = useFetch(`/header-image?populate=*`);
  console.log(header_image, "header_image");

  const our_story = useFetch(`/our-story`);
  console.log(our_story, "our_story");

  const who_we_are = useFetch(`/who-we-are`);
  console.log(who_we_are, "who_we_are");

  const what_we_do = useFetch(`/what-we-do`);
  console.log(what_we_do, " what_we_do");

  const mission = useFetch(`/mission`);
  console.log(mission, "mission");

  const core_value = useFetch(`/core-value`);
  console.log(core_value, "core-value");

  const brief_history = useFetch(`/brief-history`);
  console.log(brief_history, "brief_history");

  const company_establishiment = useFetch(`/company-establishiment`);
  console.log(company_establishiment, "company_establishiment");

  const testimonials = useFetch(`/testimonials?populate=*`);
  console.log(testimonials, "testimonials");

  const home_image = useFetch(`/home-image?populate=*`);
  console.log(home_image, "home-image");

  return (
    <div>
      <>
        <main id="main">
          {/* ======= Breadcrumbs ======= */}
          <div
            className="breadcrumbs d-flex align-items-center"
            style={{
              backgroundImage: `url(${
                process.env.REACT_APP_UPLOAD_URL +
                header_image.data?.attributes?.image?.data?.attributes?.url
              })`,
            }}>
            <div
              className="container position-relative d-flex flex-column align-items-center"
              data-aos="fade">
              <h2>About</h2>
              <ol>
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li>About</li>
              </ol>
            </div>
          </div>
          {/* End Breadcrumbs */}
          {/* ======= About Section ======= */}
          <section id="about" className="about">
            <div className="container" data-aos="fade-up">
              <div className="row position-relative">
                <div
                  className="col-lg-7 about-img gallery_anim"
                  style={{
                    backgroundImage: `url(${
                      process.env.REACT_APP_UPLOAD_URL +
                      home_image?.data?.attributes?.image1?.data?.attributes
                        ?.url
                    })`,
                  }}
                />
                <div className="col-lg-7 ">
                  <h2>World Class Quality Award</h2>
                  <div className="our-story">
                    <h4>{our_story?.data?.attributes?.year}</h4>
                    <h3>{our_story?.data?.attributes?.title}</h3>
                    <p style={{ textAlign: "justify" }} className="left_anime">
                      {our_story?.data?.attributes?.desc}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End About Section */}
          {/* ======= Stats Counter Section ======= */}
          <section id="stats-counter" className="stats-counter section-bg">
            <div className="container">
              <div className="row gy-4">
                <div className="col-lg-3 col-md-6 gallery_anim">
                  <div className="stats-item d-flex align-items-center w-100 h-100">
                    <i className="bi bi-emoji-smile color-blue flex-shrink-0" />
                    <div>
                      <span
                        data-purecounter-start={0}
                        data-purecounter-end={232}
                        data-purecounter-duration={1}
                        className="purecounter"
                      />
                      <p>Total nominee’s</p>
                    </div>
                  </div>
                </div>
                {/* End Stats Item */}
                <div className="col-lg-3 col-md-6 gallery_anim">
                  <div className="stats-item d-flex align-items-center w-100 h-100">
                    <i className="bi bi-journal-richtext color-orange flex-shrink-0" />
                    <div>
                      <span
                        data-purecounter-start={0}
                        data-purecounter-end={521}
                        data-purecounter-duration={1}
                        className="purecounter"
                      />
                      <p>Awards</p>
                    </div>
                  </div>
                </div>
                {/* End Stats Item */}
                <div className="col-lg-3 col-md-6 gallery_anim">
                  <div className="stats-item d-flex align-items-center w-100 h-100">
                    <i className="bi bi-headset color-green flex-shrink-0" />
                    <div>
                      <span
                        data-purecounter-start={0}
                        data-purecounter-end={1463}
                        data-purecounter-duration={1}
                        className="purecounter"
                      />
                      <p>Hours Of Support</p>
                    </div>
                  </div>
                </div>
                {/* End Stats Item */}
                <div className="col-lg-3 col-md-6 gallery_anim">
                  <div className="stats-item d-flex align-items-center w-100 h-100">
                    <i className="bi bi-people color-pink flex-shrink-0" />
                    <div>
                      <span
                        data-purecounter-start={0}
                        data-purecounter-end={15}
                        data-purecounter-duration={1}
                        className="purecounter"
                      />
                      <p>Staffs</p>
                    </div>
                  </div>
                </div>
                {/* End Stats Item */}
              </div>
            </div>
          </section>
          {/* End Stats Counter Section */}
          {/* ======= Alt Services Section 2 ======= */}
          <section id="alt-services-2" className="alt-services section-bg">
            <div className="container" data-aos="fade-up">
              <div className="row justify-content-around gy-4">
                <div className="col-lg-5 d-flex flex-column justify-content-center ">
                  <h3>Who We Are</h3>
                  <p style={{ textAlign: "justify" }} className="left_anime">
                    {who_we_are?.data?.attributes?.text1}
                  </p>
                  <div
                    className="icon-box d-flex position-relative left_anime"
                    data-aos="fade-up"
                    data-aos-delay={100}>
                    <i className="bi  bi-award flex-shrink-0" />
                    <div>
                      <p style={{ textAlign: "justify" }}>
                        {who_we_are?.data?.attributes?.text2}
                      </p>{" "}
                    </div>
                  </div>
                  {/* End Icon Box */}
                  <div
                    className="icon-box d-flex position-relative left_anime"
                    data-aos="fade-up"
                    data-aos-delay={100}>
                    <i className="bi  bi-award flex-shrink-0" />
                    <div>
                      <p style={{ textAlign: "justify" }}>
                        {who_we_are?.data?.attributes?.text3}
                      </p>{" "}
                    </div>
                  </div>
                  {/* End Icon Box */}
                  <div
                    className="icon-box d-flex position-relative left_anime"
                    data-aos="fade-up"
                    data-aos-delay={100}>
                    <i className="bi  bi-award flex-shrink-0" />
                    <div>
                      <p style={{ textAlign: "justify" }}>
                        {who_we_are?.data?.attributes?.text4}
                      </p>{" "}
                    </div>
                  </div>
                  {/* End Icon Box */}
                  <div
                    className="icon-box d-flex position-relative left_anime"
                    data-aos="fade-up"
                    data-aos-delay={100}>
                    <i className="bi  bi-award flex-shrink-0" />
                    <div>
                      <p style={{ textAlign: "justify" }}>
                        {who_we_are?.data?.attributes?.text5}
                      </p>{" "}
                    </div>
                  </div>
                  {/* End Icon Box */}
                </div>
                <div
                  className="col-lg-6 img-bg right_anime"
                  style={{
                    backgroundImage: `url(${
                      process.env.REACT_APP_UPLOAD_URL +
                      home_image?.data?.attributes?.image2?.data?.attributes
                        ?.url
                    })`,
                  }}
                  data-aos="zoom-in"
                  data-aos-delay={100}
                />
              </div>
            </div>
          </section>
          {/* End Alt Services Section 2 */}
          {/* ======= Alt Services Section ======= */}
          <section id="alt-services" className="alt-services">
            <div className="container" data-aos="fade-up">
              <div className="row justify-content-around gy-4">
                <div
                  className="col-lg-6 img-bg left_anime"
                  style={{
                    backgroundImage: `url(${
                      process.env.REACT_APP_UPLOAD_URL +
                      home_image?.data?.attributes?.image3?.data?.attributes
                        ?.url
                    })`,
                  }}
                  data-aos="zoom-in"
                  data-aos-delay={100}
                />
                <div className="col-lg-5 d-flex flex-column justify-content-center">
                  <h3>What We Do</h3>
                  <p style={{ textAlign: "justify" }} className="right_anime">
                    {what_we_do?.data?.attributes?.text1}
                  </p>
                  <div
                    className="icon-box d-flex position-relative right_anime"
                    data-aos="fade-up"
                    data-aos-delay={100}>
                    <i className="bi  bi-award  flex-shrink-0 " />
                    <div>
                      <p>{what_we_do?.data?.attributes?.text2}</p>
                    </div>
                  </div>
                  {/* End Icon Box */}
                  <div
                    className="icon-box d-flex position-relative right_anime"
                    data-aos="fade-up"
                    data-aos-delay={200}>
                    <i className="bi bi-award flex-shrink-0" />
                    <div>
                      <p> {what_we_do?.data?.attributes?.text3}</p>
                    </div>
                  </div>
                  {/* End Icon Box */}
                </div>
              </div>
            </div>
          </section>
          {/* End Alt Services Section */}
          {/* ======= Alt Services Section 2 ======= */}
          <section id="alt-services-2" className="alt-services section-bg">
            <div className="container" data-aos="fade-up">
              <div className="row justify-content-around gy-4">
                <div className="col-lg-5 d-flex flex-column justify-content-center">
                  <h3>Mission</h3>
                  <p style={{ textAlign: "justify" }} className="left_anime">
                    {mission?.data?.attributes?.text1}
                  </p>
                  <div
                    className="icon-box d-flex position-relative left_anime"
                    data-aos="fade-up"
                    data-aos-delay={100}>
                    <i className="bi  bi-award flex-shrink-0" />
                    <div>
                      <p style={{ textAlign: "justify" }}>
                        {mission?.data?.attributes?.text2}
                        <br />
                        {mission?.data?.attributes?.text3}
                        <br />
                        {mission?.data?.attributes?.text4}
                        <br /> {mission?.data?.attributes?.text5}
                      </p>{" "}
                    </div>
                  </div>
                  {/* End Icon Box */}
                </div>
                <div
                  className="col-lg-6 img-bg right_anime"
                  style={{
                    backgroundImage: `url(${
                      process.env.REACT_APP_UPLOAD_URL +
                      home_image?.data?.attributes?.image4?.data?.attributes
                        ?.url
                    })`,
                  }}
                  data-aos="zoom-in"
                  data-aos-delay={100}
                />
              </div>
            </div>
          </section>
          {/* End Alt Services Section 2 */}
          {/* ======= Alt Services Section ======= */}
          <section id="alt-services" className="alt-services">
            <div className="container" data-aos="fade-up">
              <div className="row justify-content-around gy-4">
                <div className="col-lg-5 d-flex flex-column justify-content-center">
                  <h3>Core Values</h3>
                  <div
                    className="icon-box d-flex position-relative left_anime"
                    data-aos="fade-up"
                    data-aos-delay={100}>
                    <i className="bi  bi-award  flex-shrink-0" />
                    <div>
                      <p>
                        {core_value?.data?.attributes?.text1} <br />
                        {core_value?.data?.attributes?.text2}
                        <br />
                        {core_value?.data?.attributes?.text3}
                        <br /> {core_value?.data?.attributes?.text4}
                      </p>
                    </div>
                  </div>
                  {/* End Icon Box */}
                </div>
                <div
                  className="col-lg-6 img-bg right_anime"
                  style={{
                    backgroundImage: `url(${
                      process.env.REACT_APP_UPLOAD_URL +
                      home_image?.data?.attributes?.image5?.data?.attributes
                        ?.url
                    })`,
                  }}
                  data-aos="zoom-in"
                  data-aos-delay={100}
                />
              </div>
            </div>
          </section>
          {/* End Alt Services Section */}
          <section id="alt-services" className="alt-services">
            <div className="container" data-aos="fade-up">
              <div className="row justify-content-around gy-4">
                <div
                  className="col-lg-6 img-bg left_anime"
                  style={{
                    backgroundImage: `url(${
                      process.env.REACT_APP_UPLOAD_URL +
                      home_image?.data?.attributes?.image6?.data?.attributes
                        ?.url
                    })`,
                  }}
                  data-aos="zoom-in"
                  data-aos-delay={100}
                />
                <div className="col-lg-5 d-flex flex-column justify-content-center">
                  <h3>Brief History</h3>
                  <p style={{ textAlign: "justify" }} className="right_anime">
                    {brief_history?.data?.attributes?.text1}
                  </p>
                  <div
                    className="icon-box d-flex position-relative right_anime"
                    data-aos="fade-up"
                    data-aos-delay={100}>
                    <i className="bi  bi-award  flex-shrink-0" />
                    <div>
                      <p> {brief_history?.data?.attributes?.text2}</p>
                    </div>
                  </div>
                  {/* End Icon Box */}
                  <div
                    className="icon-box d-flex position-relative right_anime"
                    data-aos="fade-up"
                    data-aos-delay={200}>
                    <i className="bi bi-award flex-shrink-0" />
                    <div>
                      <p> {brief_history?.data?.attributes?.text3}</p>
                    </div>
                  </div>
                  {/* End Icon Box */}
                  <div
                    className="icon-box d-flex position-relative right_anime"
                    data-aos="fade-up"
                    data-aos-delay={300}>
                    <i className="bi bi-award flex-shrink-0" />
                    <div>
                      <p>{brief_history?.data?.attributes?.text4}</p>
                    </div>
                  </div>
                  {/* End Icon Box */}

                  {/* End Icon Box */}
                </div>
              </div>
            </div>
          </section>
          {/* End Alt Services Section */}
          {/* ======= Alt Services Section 2 ======= */}
          <section id="alt-services-2" className="alt-services section-bg">
            <div className="container" data-aos="fade-up">
              <div className="row justify-content-around gy-4">
                <div className="col-lg-5 d-flex flex-column justify-content-center">
                  <h3>Company Establishiment</h3>
                  <p style={{ textAlign: "justify" }} className="left_anime">
                    {company_establishiment?.data?.attributes?.text1}
                  </p>
                  <div
                    className="icon-box d-flex position-relative left_anime"
                    data-aos="fade-up"
                    data-aos-delay={100}>
                    <i className="bi  bi-award flex-shrink-0" />
                    <div>
                      <h4>
                        <a href="" className="stretched-link">
                          {company_establishiment?.data?.attributes?.text2}
                        </a>
                      </h4>
                      <p style={{ textAlign: "justify" }}>
                        {company_establishiment?.data?.attributes?.text3}
                      </p>{" "}
                    </div>
                  </div>
                  {/* End Icon Box */}
                  <div
                    className="icon-box d-flex position-relative left_anime"
                    data-aos="fade-up"
                    data-aos-delay={200}>
                    <i className="bi  bi-award flex-shrink-0" />
                    <div>
                      <h4>
                        <a href="" className="stretched-link">
                          {company_establishiment?.data?.attributes?.text4}
                        </a>
                      </h4>
                      <p style={{ textAlign: "justify" }}>
                        {company_establishiment?.data?.attributes?.text5}
                      </p>{" "}
                    </div>
                  </div>
                  {/* End Icon Box */}
                </div>
                <div
                  className="col-lg-6 img-bg right_anime"
                  style={{
                    backgroundImage: `url(${
                      process.env.REACT_APP_UPLOAD_URL +
                      home_image?.data?.attributes?.image7?.data?.attributes
                        ?.url
                    })`,
                  }}
                  data-aos="zoom-in"
                  data-aos-delay={100}
                />
              </div>
            </div>
          </section>
          {/* End Alt Services Section 2 */}
          {/* ======= Testimonials Section ======= */}
          <section id="testimonials" className="testimonials section-bg">
            <div className="container" data-aos="fade-up">
              <div className="section-header">
                <h2>Testimonials</h2>
                <p>
                  Here are some testimonials from previous award winners, who
                  have achieved great success in their careers after winning the
                  award
                </p>{" "}
              </div>
              <div className="slides-2 swiper">
                <div className="swiper-wrapper">
                  {testimonials?.data?.map((d) => (
                    <div key={d.id}>
                      <div className="swiper-slide right_anime">
                        <div className="testimonial-wrap">
                          <div className="testimonial-item">
                            <img
                              src={
                                process.env.REACT_APP_UPLOAD_URL +
                                d.attributes?.image?.data?.attributes?.url
                              }
                              className="testimonial-img"
                              alt=""
                            />
                            <h3>{d.attributes?.title}</h3>
                            <h4>{d.attributes?.category}</h4>
                            <div className="stars">
                              <i className="bi bi-star-fill" />
                              <i className="bi bi-star-fill" />
                              <i className="bi bi-star-fill" />
                              <i className="bi bi-star-fill" />
                              <i className="bi bi-star-fill" />
                            </div>
                            <p>
                              <i className="bi bi-quote quote-icon-left" />
                              {d.attributes?.desc}
                              <i className="bi bi-quote quote-icon-right" />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* End testimonial item */}
                </div>
                <div className="swiper-pagination" />
              </div>
            </div>
          </section>
          {/* End Testimonials Section */}
        </main>
        {/* End #main */}
      </>
    </div>
  );
};

export default About;
