import React, { Component } from "react";
import useFetch from "../hooks/useFetch";
import { makeRequest } from "../MakeReq/makeRequest";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const Abissiniyaawards = () => {

  const [PER_PAGE, setPER_PAGE] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();
  const our_award_gallery_text = useFetch(
    `/our-award-gallery-text?populate=*&`
  );
  const header_image = useFetch(`/header-image?populate=*`);
  console.log(header_image, "header_image");

  const higher_honer_award = useFetch(`/higher-honer-awards?populate=*`);
  console.log(higher_honer_award, "higher_honer_award");

  const abissiniya_award_winners_list = useFetch(`/abissiniya-award-winners-lists?&populate=*`) ;
  console.log(abissiniya_award_winners_list, "abissiniya_award_winners_list");

  const event_video = useFetch(`/abisiniya-videos?populate=*`);
  console.log(event_video, "aaa event_video");



  const pageCount = Math.ceil(event_video ?.data?.length / PER_PAGE);
  const offset = currentPage * PER_PAGE;
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  //console.log(selectedCats, "the search");
  //console.log(subcateg, "the search");
  const previousPage = () => {
    if (currentPage !== 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== pageCount - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const currenteventvideo = event_video?.data?.slice(offset, offset + PER_PAGE);
  return (
    <div>
      <main id="main">
        {/* ======= Breadcrumbs ======= */}
        <div
          className="breadcrumbs d-flex align-items-center"
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_UPLOAD_URL +
              header_image.data?.attributes?.image?.data?.attributes?.url
            })`,
          }}>
          <div
            className="container position-relative d-flex flex-column align-items-center"
            data-aos="fade">
            <h2>Abissiniya Awards</h2>
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li>Abissiniya Awards</li>
            </ol>
          </div>
        </div>
        {/* End Breadcrumbs */}
        {/* ======= Constructions Section ======= */}
        <section id="constructions" className="constructions">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Higher Honer Award</h2>
              <p>
                Our company has prepared the quality award measurement document
                based on the practice of the great institutions that have the
                quality award measurement in the world and in a way that is
                suitable and useful for our country's institutions.
              </p>
            </div>
            <div className="row gy-4">
              {higher_honer_award?.data?.map((x) => (
                <div
                  className="col-lg-6"
                  data-aos="fade-up"
                  data-aos-delay={400}
                  key={x.id}>
                  <div className="card-item">
                    <div className="row">
                      <div className="col-xl-5">
                        <div
                          className="card-bg"
                          style={{
                            backgroundImage: `url(${
                              process.env.REACT_APP_UPLOAD_URL +
                              x.attributes?.image?.data?.attributes?.url
                            })`,
                          }}
                        />
                      </div>
                      <div className="col-xl-7 d-flex align-items-center">
                        <div className="card-body">
                          <h4 className="card-title">{x.attributes?.text1}</h4>
                          <p>{x.attributes?.text2}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>


        <section id="projects" className="projects">
            <div className=" " data-aos="fade-up">
              <div className="section-header">
                <h2>Some Of Our Events</h2>
                <p>{our_award_gallery_text?.data?.attributes?.title} </p>
              </div>
              <div className="row" style={{paddingLeft:'5%',paddingRight:"5%"}}>
              {
                      currenteventvideo?.map(x =>
                        <div key={x.id}  className="col-sm-4 col-md-4 col-lg-4 col-12"  >
                          {/* <video src={process.env.REACT_APP_UPLOAD_URL + x.attributes?.video?.data[0]?.attributes?.url}></video> */}
                          {x.attributes?.video?.data?.attributes?.url ? (
                            <div>
                              <video width={320} height={240} controls loop muted  preload="metadata" >
                                <source src={process.env.REACT_APP_UPLOAD_URL + x.attributes?.video?.data?.attributes?.url + '#t=4'} type="video/mp4" 
                                
                                />

                              </video>
                              <p>
                              </p></div>
                          ) : ("")}
{x.attributes?.title}

  </div>
                        )
                }


<div className="row" style={{ paddingLeft: "15%" }}>
                  <div  >
                  <div className="row tm-row tm-mt-100 tm-mb-75">
        <div className="tm-prev-next-wrapper  ">
          <button
            href="#"
            className="mb-2 tm-btn tm-btn-primary tm-prev-next   tm-mr-20" onClick={previousPage}
          >
            Prev
          </button>
          <button href="#" className="mb-2 tm-btn tm-btn-primary tm-prev-next" onClick={nextPage}>
            Next
          </button>
        </div>
        <div className="tm-paging-wrapper  ">
          <span className="d-inline-block mr-3">    </span>
          <nav className="tm-paging-nav d-inline-block">
            <ul>
            Page
              <li className="tm-paging-item active">
                <a href="#" className="mb-2 tm-btn tm-paging-link" style={{margin:"10px"}}>
                {currentPage + 1}/{pageCount}
                </a>
              </li>


            </ul>
          </nav>
            </div>


      </div>
                  </div>
                </div>


                </div>
                            </div></section>
        {/* End Constructions Section */}
        {/* ======= Footer ======= */}

        {/* End Footer */}
        <a
          href="#"
          className="scroll-top d-flex align-items-center justify-content-center">
          <i className="bi bi-arrow-up-short" />
        </a>
        <div id="preloader" />
        {/* Vendor JS Files */}
        {/* Template Main JS File */}
      </main>
    </div>
  );
};

export default Abissiniyaawards;
