import React, { useEffect } from "react";
 import { useNavigate } from "react-router-dom";
import Profile from "./pages/Profile";


export const storeUser = (data) => {
    localStorage.setItem(
        "user",
        JSON.stringify({

            company_name: data.company_name,
            email: data.user.email,
            phone_number: data.user.phone_number,
            jwt: data.jwt
        })
    )
}

export const UserData = () => {
    const stringifiedUser = localStorage.getItem("user")  || '""';
    return JSON.parse(stringifiedUser || {});
}


export const Protector = ({component}) => {


    const navigate = useNavigate();
    const { jwt} = UserData();

    useEffect(() =>{


        if(!jwt) {

            navigate("/login")
        }

    },[navigate, jwt])
    return  <Profile/>


}