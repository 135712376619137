import React, { Component } from "react";
import useFetch from "../hooks/useFetch";
import { makeRequest } from "../MakeReq/makeRequest";
import { useState } from "react";

const Gallary = () => {
  const header_image = useFetch(`/header-image?populate=*`);
  console.log(header_image, "header_image");

  const [selectedYear, setSelectedYear] = useState("2012");

  console.log(selectedYear, "selected year");
  const our_award_gallery = useFetch(
    `/our-award-galleries?populate=*&filters[year][$eq]=${selectedYear}`
  );
  console.log(our_award_gallery, "our-award_gallery");

  const filter = useFetch(`/our-award-galleries?populate=*`);
  console.log(filter, "filter");

  return (
    <div>
      <>
        <main id="main">
          {/* ======= Breadcrumbs ======= */}
          <div
            className="breadcrumbs d-flex align-items-center"
            style={{
              backgroundImage: `url(${
                process.env.REACT_APP_UPLOAD_URL +
                header_image.data?.attributes?.image?.data?.attributes?.url
              })`,
            }}>
            {" "}
            <div
              className="container position-relative d-flex flex-column align-items-center"
              data-aos="fade">
              <h2>Awards</h2>
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Awards</li>
              </ol>
            </div>
          </div>
          {/* End Breadcrumbs */}
          {/* ======= Our Projects Section ======= */}
          <section id="projects" className="projects">
            <div className="container" data-aos="fade-up">
              <div
                className="portfolio-isotope"
                data-portfolio-filter="*"
                data-portfolio-layout="masonry"
                data-portfolio-sort="original-order">
                <ul
                  className="portfolio-flters"
                  data-aos="fade-up"
                  data-aos-delay={100}>
                  <li data-filter="*" className="filter-active">
                    All
                  </li>
                  {filter?.data?.map((x) => (
                    <li
                      data-filter=".filter-remodeling"
                      onClick={() => [setSelectedYear(x.attributes?.year)]}>
                      Award {x.attributes?.year}
                    </li>
                  ))}
                </ul>
                {/* End Projects Filters */}

                <div>
                  {our_award_gallery?.data?.map((x) => (
                    <div
                      key={x.id}
                      className="row gy-4 portfolio-container "
                      data-aos="fade-up"
                      data-aos-delay={200}>
                      {x.attributes?.image?.data?.map((y) => (
                        <div
                          key={y.id}
                          className="col-lg-4 col-md-6 portfolio-item filter-remodeling gallery_anim">
                          <div className="portfolio-content h-100">
                            <img
                              src={
                                process.env.REACT_APP_UPLOAD_URL +
                                y.attributes?.url
                              }
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                {/* End Projects Container */}
              </div>
            </div>
          </section>
          {/* End Our Projects Section */}
        </main>
        {/* End #main */}
      </>
    </div>
  );
};

export default Gallary;
