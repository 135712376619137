import React, { Component } from "react";
import useFetch from "../hooks/useFetch";
import { makeRequest } from "../MakeReq/makeRequest";
import { useState } from "react";
import "./asd.css";

// import useFetch from "../hooks/useFetch";

import axios from "axios";
import { Link } from "react-router-dom";
// import { makeRequest } from '../MakeReq/makeRequest';
/* import 'react-toastify/dist/ReactToastify.css'; */
import { useNavigate } from "react-router-dom";
import { storeUser } from "../helper";
import { useEffect } from "react";
// import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
//  import './new.css';
const intialUser = { password: "", identifier: "" };
import "./register_asset/vendor/bootstrap/css/bootstrap.min.css";
import "./register_asset/fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "./register_asset/vendor/animate/animate.css";
import "./register_asset/vendor/css-hamburgers/hamburgers.min.css";
import "./register_asset/vendor/animsition/css/animsition.min.css";
import "./register_asset/vendor/select2/select2.min.css";
import "./register_asset/vendor/daterangepicker/daterangepicker.css";
import "./register_asset/css/util.css";
import "./register_asset/css/main.css";

const Login = () => {
  const header_image = useFetch(`/header-image?populate=*`);
  // console.log(header_image, "header_image");

  const login_image = useFetch(`/login-image?populate=*`);
  // console.log(login_image, "login_image");
  const [user, SetUser] = useState(intialUser);
  // console.log(user)

  const navigate = useNavigate();
  const handleChange = ({ target }) => {
    SetUser((currentUser) => ({
      ...currentUser,
      [target.name]: target.value,
    }));
  };
  const handleLogin = async () => {
    const url = process.env.REACT_APP_API_URL + `/auth/local`;
    try {
      if (user.identifier && user.password) {
        const { data } = await axios.post(url, user, {
          headers: {
            Authorization: "bearer " + process.env.REACT_APP_API_TOKEN,
          },
        });
        if (data) {
          console.log(data);

          storeUser(data);
          SetUser(intialUser);
          navigate("/");
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  return (
    <div>
      <>
        <main id="main">
          {/* ======= Breadcrumbs ======= */}
          <div
            className="breadcrumbs d-flex align-items-center"
            style={{
              backgroundImage: `url(${
                process.env.REACT_APP_UPLOAD_URL +
                header_image.data?.attributes?.image?.data?.attributes?.url
              })`,
            }}>
            <div
              className="container position-relative d-flex flex-column align-items-center"
              data-aos="fade">
              <h2>Login</h2>
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Login</li>
              </ol>
            </div>
          </div>
          {/* End Breadcrumbs */}
          {/* ======= Contact Section ======= */}
          <section id="contact" className="contact body ">
            <div
              className="limiter"
              style={{ paddingLeft: "10%", paddingRight: "10%" }}>
              <div className="container-login100">
                <div className="wrap-login100">
                  <form className="login100-form validate-form">
                    <span className="login100-form-title p-b-43">
                      Login to continue
                    </span>
                    <div
                      className="wrap-input100 validate-input"
                      data-validate="Valid email is required: ex@abc.xyz">
                      <input
                        className="input100"
                        type="email"
                        name="identifier"
                        value={user.identifier}
                        onChange={handleChange}
                        required
                      />
                      <span className="focus-input100" />
                      <span className="label-input100">Email</span>
                    </div>
                    <div
                      className="wrap-input100 validate-input"
                      data-validate="Password is required">
                      <input
                        className="input100"
                        type="password"
                        name="password"
                        value={user.password}
                        onChange={handleChange}
                      />
                      <span className="focus-input100" />
                      <span className="label-input100" required>
                        Password
                      </span>
                    </div>
                    <div className="flex-sb-m w-full p-t-3 p-b-32">
                      {/* <div className="contact100-form-checkbox">
                        <input
                          className="input-checkbox100"
                          id="ckb1"
                          type="checkbox"
                          name="remember-me"
                        />
                        <label className="label-checkbox100" htmlFor="ckb1">
                          Remember me
                        </label>
                      </div> */}
                      <div>
                        <Link to="/register">
                          <div className="txt1">
                            Don't have an account? Sign up
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="container-login100-form-btn">
                      <button
                        className="login100-form-btn"
                        type="button"
                        onClick={handleLogin}>
                        Login
                      </button>
                    </div>
                  </form>
                  <div
                    className="login100-more"
                    style={{
                      backgroundImage: `url(${
                        process.env.REACT_APP_UPLOAD_URL +
                        login_image?.data?.attributes?.image?.data?.attributes
                          ?.url
                      })`,
                    }}></div>
                </div>
              </div>
            </div>
          </section>
          {/* End Contact Section */}
        </main>
        {/* End #main */}
      </>
    </div>
  );
};

export default Login;
