import React, { Component } from "react";
import useFetch from "../hooks/useFetch";
import { makeRequest } from "../MakeReq/makeRequest";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import Advert from "../assets/img/photo_2024-07-19_05-19-19.jpg";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActions,
} from "@mui/material";

import Donate from "../assets/img/donate.png";

const Abissiniyaindustryaward = () => {

  const navigation = useNavigate()
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const header_image = useFetch(`/header-image?populate=*`);
  console.log(header_image, "header_image");

  const abissiniya_industry_awards_for_companies_txt = useFetch(
    `/abissiniya-industry-awards-for-companies-txt?populate=*`
  );
  console.log(
    abissiniya_industry_awards_for_companies_txt,
    "abissiniya_industry_awards_for_companies_txt"
  );

  const abissiniya_industry_awards_for_companies_categ = useFetch(
    `/abissiniya-industry-awards-for-companies-categs?populate=*`
  );
  console.log(
    abissiniya_industry_awards_for_companies_categ,
    "abissiniya_industry_awards_for_companies_categ"
  );
  return (
    <div>
      <div>


      </div>


      <main id="main">
        {/* ======= Breadcrumbs ======= */}
        <div
          className="breadcrumbs d-flex align-items-center"
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_UPLOAD_URL +
              header_image.data?.attributes?.image?.data?.attributes?.url
            })`,
          }}>
          <div
            className="container position-relative d-flex flex-column align-items-center"
            data-aos="fade">
            <h2>Abissiniya Industry Awards</h2>
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li>Abissiniya Industry Awards</li>
            </ol>
          </div>
        </div>
        {/* End Breadcrumbs */}
        {/* ======= Constructions Section ======= */}
        <section id="constructions" className="constructions">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Abissiniya Industry Awards For Companies</h2>
              <p>
                {
                  abissiniya_industry_awards_for_companies_txt?.data?.attributes
                    ?.text
                }
              </p>
            </div>
            <div className="row gy-4">
              {abissiniya_industry_awards_for_companies_categ?.data?.map(
                (x) => (
                  <div
                    className={
                      x.id % 2 == 0
                        ? "col-lg-6 right_anime"
                        : "col-lg-6 left_anime "
                    }
                    data-aos="fade-up"
                    data-aos-delay={400}
                    key={x.id}>
                    <div className="card-item">
                      <div className="row">
                        <div className="col-xl-5">
                          <div
                            className="card-bg"
                            style={{
                              backgroundImage: `url(${
                                process.env.REACT_APP_UPLOAD_URL +
                                x.attributes?.image?.data?.attributes?.url
                              })`,
                            }}
                          />
                        </div>
                        <div className="col-xl-7 d-flex align-items-center">
                          <div className="card-body">
                            <h4 className="card-title">
                              {x.attributes?.text1}
                            </h4>
                            <p>{x.attributes?.text2}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </section>
        {/* End Constructions Section */}
        {/* ======= Footer ======= */}

        {/* End Footer */}
        <a
          href="#"
          className="scroll-top d-flex align-items-center justify-content-center">
          <i className="bi bi-arrow-up-short" />
        </a>
        <div id="preloader" />
        {/* Vendor JS Files */}
        {/* Template Main JS File */}
      </main>
    </div>
  );
};

export default Abissiniyaindustryaward;
