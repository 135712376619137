import React, { Component } from "react";
import useFetch from "../hooks/useFetch";
import { makeRequest } from "../MakeReq/makeRequest";
import { useState } from "react";

const Contact = () => {
  const header_image = useFetch(`/header-image?populate=*`);
  console.log(header_image, "header_image");

  return (
    <div>
      <>
        <main id="main">
          {/* ======= Breadcrumbs ======= */}
          <div
            className="breadcrumbs d-flex align-items-center"
            style={{
              backgroundImage: `url(${
                process.env.REACT_APP_UPLOAD_URL +
                header_image.data?.attributes?.image?.data?.attributes?.url
              })`,
            }}>
            <div
              className="container position-relative d-flex flex-column align-items-center"
              data-aos="fade">
              <h2>Contact</h2>
              <ol>
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li>Contact</li>
              </ol>
            </div>
          </div>
          {/* End Breadcrumbs */}
          {/* ======= Contact Section ======= */}
          <section id="contact" className="contact">
            <div className="container" data-aos="fade-up" data-aos-delay={100}>
              <div className="row gy-4">
                <div className="col-lg-6">
                  <div className="info-item  d-flex flex-column justify-content-center align-items-center">
                    <i className="bi bi-map" />
                    <h3>Our Address</h3>
                    <p>Alemnesh Plaza, Bole, Addis Ababa</p>
                  </div>
                </div>
                {/* End Info Item */}
                <div className="col-lg-3 col-md-6">
                  <div className="info-item d-flex flex-column justify-content-center align-items-center">
                    <i className="bi bi-envelope" />
                    <h3>Email Us</h3>
                    <p>contact@example.com</p>
                  </div>
                </div>
                {/* End Info Item */}
                <div className="col-lg-3 col-md-6">
                  <div className="info-item  d-flex flex-column justify-content-center align-items-center">
                    <i className="bi bi-telephone" />
                    <h3>Call Us</h3>
                    <p>+1 5589 55488 55</p>
                  </div>
                </div>
                {/* End Info Item */}
              </div>
              <div className="row gy-4 mt-1">
                <div className="col-lg-6 ">
                  <div className="mapouter">
                    <div className="gmap_canvas">
                      <iframe
                        className="gmap_iframe"
                        frameBorder={0}
                        scrolling="no"
                        marginHeight={0}
                        marginWidth={0}
                        src="https://maps.google.com/maps?width=600&height=384&hl=en&q=edna mall addis ababa&t=&z=15&ie=UTF8&iwloc=B&output=embed"
                      />
                      <a href="https://gachanox.io/">Gacha Nox</a>
                    </div>
                    <style
                      dangerouslySetInnerHTML={{
                        __html:
                          ".mapouter{position:relative;text-align:right;width:100%;height:384px;}.gmap_canvas {overflow:hidden;background:none!important;width:100%;height:384px;}.gmap_iframe {width:100%!important;height:384px!important;}",
                      }}
                    />
                  </div>
                </div>
                {/* End Google Maps */}
                <div className="col-lg-6">
                  <form
                    action="forms/contact.php"
                    method="post"
                    role="form"
                    className="php-email-form">
                    <div className="row gy-4">
                      <div className="col-lg-6 form-group">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          id="name"
                          placeholder="Your Name"
                          required=""
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder="Your Email"
                          required=""
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        id="subject"
                        placeholder="Subject"
                        required=""
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        name="message"
                        rows={5}
                        placeholder="Message"
                        required=""
                        defaultValue={""}
                      />
                    </div>
                    <div className="my-3">
                      <div className="loading">Loading</div>
                      <div className="error-message" />
                      <div className="sent-message">
                        Your message has been sent. Thank you!
                      </div>
                    </div>
                    <div className="text-center">
                      <button type="submit">Send Message</button>
                    </div>
                  </form>
                </div>
                {/* End Contact Form */}
              </div>
            </div>
          </section>
          {/* End Contact Section */}
        </main>
        {/* End #main */}
      </>
    </div>
  );
};

export default Contact;
