import React, { Component } from "react";
import useFetch from "../hooks/useFetch";
import { makeRequest } from "../MakeReq/makeRequest";
import { useState } from "react";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";

import { generate, count } from "random-words";
import { useNavigate } from "react-router-dom";
import { faker } from "@faker-js/faker";

import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { styled } from "@mui/system";
const defaultTheme = createTheme();

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: "#ffbf00",

    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: "#ffbf00",
    },
  },
  "&:not(.Mui-selected)": {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: theme.palette.grey[400],
    },
  },
}));
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const Donate = () => {
  const [alignment, setAlignment] = useState("center");

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const navigation = useNavigate();
  const [error, setError] = useState({
    currency: "",
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    tx_ref: "",
    amount: "",
  });

  console.log(error?.amount, "this is error");
  console.log(error, "this is error");
  const header_image = useFetch(`/header-image?populate=*`);
  //   console.log(header_image, "header_image");
  const return_url = process.env.REACT_APP_CURRENT_URL + `/Certificate`;
  // console.log(return_url, "return_url");

  const [data, setdata] = useState({
    currency: "ETB",
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    tx_ref: faker.string.uuid(),
    amount: "50000",
    return_url : process.env.REACT_APP_CURRENT_URL + `/Certificate`
  });

  console.log(data, "data amount");

  const url = process.env.REACT_APP_CHAPA_PAY_BACKEND + `/chapas/acceptpayment`;


  const handleChange = (event) => {
    const { name, value } = event.target;
    setdata({
      ...data,
      [name]: value,
    });
  };
  // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IndvcmxkY2xhc3Nmcm9udDU1IiwiaWF0IjoxNzIyMTY3NjgyfQ.H9Mf02qeRW_fxWVLm690sJ-BmGRhQt-eYcQk-xlPAQ8

  const validate = () => {
    let newErrors = {};
    if (!data.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      newErrors.email = "Email is invalid";
    }

    // Phone number regex: Matches formats like (123) 456-7890, 123-456-7890, 1234567890, 123.456.7890
    const phoneRegex = /^(?:\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;

    const nameRegex = /^[A-Za-z]+/

    if (!data.phone_number) {
      newErrors.phone_number = "Phone number is required";
    } else if (!phoneRegex.test(data.phone_number)) {
      newErrors.phone_number = "Phone number is invalid";
    }
    if (!data.first_name) {
      newErrors.first_name = "First Name is required";
    } else if (!nameRegex.test(data.first_name)) {
      newErrors.first_name = "First Name is invalid";
    }
    if (!data.last_name) {
      newErrors.last_name = "Last Name is required";
    } else if (!nameRegex.test(data.last_name)) {
      newErrors.last_name = "Last Name is invalid";
    }
    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  let config = {
    headers: {
      'Authorization': 'Bearer ' + process.env.REACT_APP_CHAPA_PAY_BACKEND_TOKEN
    }
  }
  const handleSubmit = async () => {
    // const data = new FormData(event.currentTarget);
    if (validate()) {
      localStorage.setItem("tx_ref", data.tx_ref);
      try {
        const response = await axios.post(
          url,
          data,

          config
        );

        console.log(response.data, "this is chapa req");
        window.open(response.data);
      } catch (error) {
        console.error("An error occurred:", error);

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError({
            amount: error.response?.data?.error?.message?.amount
              ? error.response?.data?.error?.message?.amount[0]
              : null,
          }),
            console.error("Response error:", error.response);

          console.error("wewewewe:", error.response);
          // console.error("Status:", error.response.status);
          // console.error("Headers:", error.response.headers);

          if (error.response.status === 500) {
            console.error("Internal Server Error");
          }

          // setError(error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Request error:", error.request);
          setError("No response received from the server.");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error message:", error.message);
          setError(error.message);
        }
      }
    }
  };
  return (
    <div>
      <main id="main">
        {/* ======= Breadcrumbs ======= */}
        <div
          className="breadcrumbs d-flex align-items-center"
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_UPLOAD_URL +
              header_image.data?.attributes?.image?.data?.attributes?.url
            })`,
          }}>
          <div
            className="container position-relative d-flex flex-column align-items-center"
            data-aos="fade">
            <h2>Donation Form </h2>
            <ol>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>About</li>
            </ol>
          </div>
        </div>
        <div>
          <ThemeProvider theme={defaultTheme}>
            <Grid container component="main">
              <CssBaseline />
              <Grid
                item
                xs={12}
                sm={4}
                md={7}
                sx={{
                  backgroundImage: `url(${
                    process.env.REACT_APP_UPLOAD_URL +
                    header_image.data?.attributes?.image?.data?.attributes?.url
                  })`,
                  backgroundColor: (t) =>
                    t.palette.mode === "light"
                      ? t.palette.grey[50]
                      : t.palette.grey[900],
                  backgroundSize: "cover",
                  backgroundPosition: "left",
                }}
              />
              <Grid
                item
                xs={12}
                sm={8}
                md={5}
                component={Paper}
                elevation={6}
                square>
                <Box
                  sx={{
                    my: 8,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  <Typography component="h1" variant="h5">
                    Donate
                  </Typography>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 1 }}>
                    <div
                      style={{
                        color: "red",
                        fontWeight: "200",
                        fontSize: "12px",
                      }}>
                      {error.email}
                    </div>
                    <TextField
                      margin="normal"

                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      onChange={handleChange}
                    />
                    <div
                      style={{
                        color: "red",
                        fontWeight: "200",
                        fontSize: "12px",
                      }}>
                      {error.first_name}
                    </div>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="first_name"
                      label="First Name"
                      name="first_name"
                      autoComplete="first_name"
                      autoFocus
                      onChange={handleChange}
                    />
                    <div
                      style={{
                        color: "red",
                        fontWeight: "200",
                        fontSize: "12px",
                      }}>
                      {error.last_name}
                    </div>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="last_name"
                      label="Last Name"
                      name="last_name"
                      autoComplete="last_name"
                      autoFocus
                      onChange={handleChange}
                    />
                    <div
                      style={{
                        color: "red",
                        fontWeight: "200",
                        fontSize: "12px",
                      }}>
                      {error.phone_number}
                    </div>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="phone_number"
                      label="Phone Number"
                      name="phone_number"
                      autoComplete="phone_number"
                      autoFocus
                      onChange={handleChange}
                    />
                    <div
                      style={{
                        color: "red",
                        fontWeight: "200",
                        fontSize: "12px",
                      }}>
                      {error.amount}
                    </div>
                    <div
                      style={{
                        color: "#ffbf00",
                        fontWeight: "500",
                        textTransform: "capitalize",
                        marginTop: "10px",
                        fontSize: "24px",
                      }}>
                      donation amount
                    </div>
                    <ToggleButtonGroup
                      style={{ marginTop: "20px" }}
                      value={alignment}
                      exclusive
                      onChange={handleAlignment}
                      aria-label="text alignment">
                         <StyledToggleButton
                        onClick={() => {
                          setdata({ ...data,amount: "5000" });
                        }}
                        value="left"
                        aria-label="left aligned">
                        5,000 ETB
                      </StyledToggleButton>
                      <StyledToggleButton
                        onClick={() => {
                          setdata({ ...data,amount: "10000" });
                        }}
                        value="centered"
                        aria-label="centered  ">
                        25,000 ETB
                      </StyledToggleButton>
                      <StyledToggleButton
                        onClick={() => {
                          setdata({ ...data,amount: "50000" });
                        }}
                        value="center"
                        aria-label="centered">
                        50,000 ETB
                      </StyledToggleButton>
                      <StyledToggleButton
                        onClick={() => {
                          setdata({ ...data,amount: "75000" });
                        }}
                        value="right"
                        aria-label="right aligned">
                        75,000 ETB
                      </StyledToggleButton>
                      <StyledToggleButton
                        onClick={() => {
                          setdata({...data, amount: "100000" });
                        }}



                        value="justify"
                        aria-label="justified">
                        100,000 ETB
                      </StyledToggleButton>
                    </ToggleButtonGroup>

                    <Button
                      style={{
                        backgroundColor: "#ffbf00",
                        fontWeight: "500",
                        textTransform: "capitalize",
                        marginTop: "40px",
                        fontSize: "24px",
                      }}
                      type="button"
                      onClick={handleSubmit}
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}>
                      Donate
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </ThemeProvider>
        </div>
        {/* End Breadcrumbs */}
      </main>
    </div>
  );
};

export default Donate;
