import axios from "axios";
import React, { Component } from "react";
import useFetch from "../hooks/useFetch";
import { makeRequest } from "../MakeReq/makeRequest";
import { useEffect } from "react";
import { useState } from "react";
import "./certificate.css";
import { useRef } from "react";
// import generatePDF from 'react-to-pdf';
import cert from "../assets/img/donationcertificate.jpg";

import "./canvas.css";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
const Certificate = () => {
  const targetRef = useRef();

  const options = {
    page: {

      margin: 2, // margin in mm
      width: 148, // width in mm (custom width for A6 landscape)
      height: 105, // height in mm (custom height for A6 landscape)
      orientation: "landscape", // page orientation
    },
  };

  const header_image = useFetch(`/header-image?populate=*`);
  //   console.log(header_image, "header_image");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const tx_ref = localStorage.getItem("tx_ref");
  console.log(tx_ref, "tx_ref");

  const [data, setData] = useState({});
  const [certificate, setCertificate] = useState({});
  const url = process.env.REACT_APP_CHAPA_PAY_BACKEND + `/chapas/verify`;
  let config = {
    headers: {
      Authorization: "Bearer " + process.env.REACT_APP_CHAPA_PAY_BACKEND_TOKEN,
    },
  };
  useEffect(() => {
    // This code runs after the component mounts
    setLoading(true);

    try {
      axios
        .post(
          url,
          {
            tx_ref: tx_ref,
          },
          config
        )
        .then(
          (response) => (
            console.log(response.data?.data, "verify response"),
            setData(response.data?.data),
            setLoading(false)
          )
        );
    } catch (error) {
      console.log(error, "error"), setError(error);
    }
    return () => {
      // This code runs when the component unmounts
    };
  }, []);

  return loading ? (
    <div>
      <main id="main">
        {/* ======= Breadcrumbs ======= */}
        <div
          className="breadcrumbs d-flex align-items-center"
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_UPLOAD_URL +
              header_image.data?.attributes?.image?.data?.attributes?.url
            })`,
          }}>
          <div
            className="container position-relative d-flex flex-column align-items-center"
            data-aos="fade">
            <h2>Donation Certificate </h2>
            <ol>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>Donation Certificate </li>
            </ol>
          </div>
        </div>
        <div></div>
      </main>
      verifing the payment . . .
    </div>
  ) : (
    <div>
      {/* <div> textInComponent </div> */}
      {/* {data.status} */}
      {/* first_name */}
      {/* last_name
     email
     phone_number
     currency
     amount
     charge
     mode
     method
     type
     status
     reference
     tx_ref


     created_at
     updated_at   */}
      <>
        <main id="main">
          {/* ======= Breadcrumbs ======= */}
          <div
            className="breadcrumbs d-flex align-items-center"
            style={{
              backgroundImage: `url(${
                process.env.REACT_APP_UPLOAD_URL +
                header_image.data?.attributes?.image?.data?.attributes?.url
              })`,
            }}>
            <div
              className="container position-relative d-flex flex-column align-items-center"
              data-aos="fade">
              <h2>Donation Certificate </h2>
              <ol>
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li>Donation Certificate </li>
              </ol>
            </div>
          </div>
          <div></div>

          <div className="xbayy" ref={targetRef}>
            <div className="xbaxx">
              <div
                data-layer="5b2a46ea-0b59-40ca-a841-71a5e68a2ec5"
                className="canvas1">
                {" "}
                <div
                  data-layer="8aa69c07-590c-4fec-a68b-5493e81f1f99"
                  className="background"></div>
                <div
                  data-layer="520132d8-8b56-4161-a33f-9503a7fe20a9"
                  className="pattern850ce7b5">
                  {" "}
                  <div
                    data-layer="227e16ef-0bbe-4467-94a9-95197f0700f4"
                    className="pattern"></div>
                </div>
                <div
                  data-layer="ce57d067-a502-4eef-a1f4-bd6122cc91e8"
                  className="lines">
                  {" "}
                  <div
                    data-layer="b70e55c3-532d-4e6f-ae40-06bb75b42086"
                    className="rectangle233720405"></div>
                  <div
                    data-layer="b1c2cae3-6d29-438c-b881-e0c27482d6a4"
                    className="rectangle3"></div>
                  <div
                    data-layer="7ceadfd9-b71d-4106-99d3-e1657a92628c"
                    className="rectangle2"></div>
                  <div
                    data-layer="f76ce8be-2352-4ea4-b8f2-169912d40564"
                    className="rectangle1"></div>
                </div>
                <div
                  data-layer="8accc235-1b42-442b-b064-c56bcc36aa2b"
                  className="rightSideShape">
                  {" "}
                  <svg
                    data-layer="5673446e-61ed-4ead-8e83-13f006b756d8"
                    preserveAspectRatio="none"
                    viewBox="2697.094482421875 -147.54888916015625 280.8203125 737.4581298828125"
                    className="shape336b546fb">
                    <path d="M 2791.612060546875 -146.7988891601562 C 2684.27685546875 -13.09681701660156 2591.558837890625 249.5642547607422 2977.164794921875 589.1592407226562 L 2974.801025390625 -146.3026733398438 L 2791.612060546875 -146.7988891601562 Z" />
                  </svg>
                  <svg
                    data-layer="c1d7906d-0e56-4f86-bcd1-7054c6deed96"
                    preserveAspectRatio="none"
                    viewBox="2758.196044921875 -129.8534393310547 260.31689453125 632.7041015625"
                    className="shape29d213230">
                    <defs>
                      <pattern
                        id="img-shape29d213230"
                        patternContentUnits="userSpaceOnUse"
                        width="100%"
                        height="100%">
                        <image
                          xlinkHref={require("./assets/shape29d213230.png")}
                          x="0"
                          y="0"
                          width="258.82px"
                          height="631.20px"
                          style={{ position: "abolute" }}
                        />
                      </pattern>
                    </defs>
                    <path
                      d="M 2834.010986328125 -129.1034393310547 C 2767.634033203125 -22.42455291748047 2640.84765625 266.2979736328125 3017.762939453125 502.1007080078125 L 3016.162353515625 -129.1034393310547 L 2834.010986328125 -129.1034393310547 Z"
                      fill="url(#img-shape29d213230)"
                    />
                  </svg>
                  <svg
                    data-layer="605c5779-844e-4d9a-905a-318670c7a939"
                    preserveAspectRatio="none"
                    viewBox="2853.095947265625 -134.65521240234375 225.0380859375 626.9156494140625"
                    className="shape172bbdd21">
                    <path d="M 2941.765625 -130.9052124023438 C 2867.435302734375 -22.46371459960938 2746.0087890625 239.3418273925781 3072.8447265625 488.5104370117188 L 3074.3837890625 -130.9052124023438 L 2941.765625 -130.9052124023438 Z" />
                  </svg>
                </div>
                <div
                  data-layer="e37957ac-2ebe-47da-aa24-6645d8eb0cf8"
                  className="ribbon">
                  {" "}
                  <svg
                    data-layer="8610c50f-93aa-49ff-b7dd-464c3e92187a"
                    preserveAspectRatio="none"
                    viewBox="1175.3397216796875 745.7543334960938 428.48486328125 82.14410400390625"
                    className="shape1">
                    <path d="M 1176.089721679688 746.5043334960938 L 1216.41162109375 786.8264770507812 L 1176.089721679688 827.1484375 L 1603.074584960938 827.1484375 L 1562.752319335938 786.8264770507812 L 1603.074584960938 746.5043334960938 L 1176.089721679688 746.5043334960938 Z" />
                  </svg>
                </div>
                <div
                  data-layer="cb6ecd78-64af-4046-99ec-2103e5384db0"
                  className="certificate">
                  CERTIFICATE{" "}
                </div>
                <div
                  data-layer="82c92830-7bce-41fa-8239-438b1e857ae9"
                  className="ofDonation">
                  OF DONATION
                </div>
                <div
                  data-layer="17bb3886-4864-4416-8a27-7645152fa886"
                  className="thisCertificateIsProudlyPresentedTo">
                  THIS CERTIFICATE IS PROUDLY PRESENTED TO
                </div>
                <div
                  data-layer="aaf01859-3fd8-4933-b91f-148ca6178d87"
                  className="group1">
                  {" "}
                  <div
                    data-layer="ab8c78c0-b4fd-477c-b67d-aa8706d44188"
                    className="signature">
                    SIGNATURE
                  </div>
                  <div
                    data-layer="37a38e09-6419-41b1-b305-60d1f4670da4"
                    className="date">
                    DATE
                  </div>
                  <div
                    data-layer="7968bb03-5489-4dec-94b9-eba191c8b1aa"
                    className="x000000">
                    {data.created_at}
                  </div>
                </div>
                <div
                  data-layer="dfe502a6-b981-43bc-bb83-5edde716a6d9"
                  className="yohannesZemene">
                  yohannes zemene
                </div>
                <div
                  data-layer="54e79574-6556-4ea5-9bf9-b6e96f41cb7d"
                  className="amount25000Etb">
                    <small>for donating </small>{data.amount} {data.currency}
                    <small > to our organization</small>
                </div>
                <div
                  data-layer="05285a43-0503-4e07-9a2f-6bb8c296c1d6"
                  className="maskGroup1"></div>
                <div
                  data-layer="11c3e0ea-072d-4492-aa20-8b29c6308f0a"
                  className="img20230325121704017"></div>
              </div>
            </div>
          </div>

          <button onClick={() => generatePDF(targetRef, options)}>
            <div
              style={{
                background:
                  "linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C)",
                padding: "20px",
                fontSize: "20px",
                fontWeight: "bold",
                color: "black",
                marginBottom: "20px",
                marginLeft: "50px",
                borderRadius: "20px",
                marginTop: "20px",
              }}>
              Download Certificate
            </div>
          </button>
        </main>
      </>
    </div>
  );
};

export default Certificate;
