import React, { Component } from 'react'
import useFetch from "../hooks/useFetch";
import { Link } from 'react-router-dom';
import { makeRequest } from "../MakeReq/makeRequest";
import { useState } from "react";
import "./news.css"
const News = () => {
  const [PER_PAGE, setPER_PAGE] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  console.log(search, 'search')

    const header_image = useFetch(`/header-image?populate=*`);
  console.log(header_image, "header_image");

  const newsletter_highlight = useFetch(`/newsletters?sort=createdAt:desc&populate=*`) ;
  console.log(newsletter_highlight, "newsletter_highlight");


  const abissiniya_award_winners_list = useFetch(`/abissiniya-award-winners-lists?&populate=*`) ;
  console.log(abissiniya_award_winners_list, "abissiniya_award_winners_list");

  const abissiniya_industry_award_winners_list = useFetch(`/abisiniya-industry-award-winner-lists?&populate=*`) ;
  console.log(abissiniya_industry_award_winners_list, "abissiniya_industry_award_winners_list");

  const world_class_winners_list = useFetch(`/world-class-winners-lists?&populate=*`) ;
  console.log(world_class_winners_list, "world_class_winners_list");


  const pageCount = Math.ceil(newsletter_highlight ?.data?.length / PER_PAGE);
  const offset = currentPage * PER_PAGE;
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  //console.log(selectedCats, "the search");
  //console.log(subcateg, "the search");
  const previousPage = () => {
    if (currentPage !== 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== pageCount - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const currentPageData = newsletter_highlight?.data?.slice(offset, offset + PER_PAGE);
    return (
        <div>
                 <div
            className="breadcrumbs d-flex align-items-center"
            style={{
              backgroundImage: `url(${
                process.env.REACT_APP_UPLOAD_URL +
                header_image.data?.attributes?.image?.data?.attributes?.url
              })`,
            }}>
            <div
              className="container position-relative d-flex flex-column align-items-center"
              data-aos="fade">
              <h2>Newsletter</h2>
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Newsletter</li>
              </ol>
            </div>
          </div>
          {/* End Breadcrumbs */}
          {/* ======= About Section ======= */}
<div style={{paddingLeft:"10%", paddingRight:"10%",paddingTop:"4%"}}>
  {/* Topbar Start */}
  <div className="container-fluid d-none d-lg-block" >
    <div className="row align-items-center bg-dark px-lg-5">


    </div>

  </div>
  {/* Topbar End */}
  {/* Navbar Start */}
          <div className="container-fluid p-0" >
          <form method="GET" className="form-inline tm-mb-80 tm-search-form"   >
            <input
              className="form-control tm-search-input"
             name="search"
              type="text"
              onChange={(e) => setSearch(e.target.value)}
              placeholder=" Search News Here..."
              aria-label="Search"
            />
            <button className="tm-search-button" type="button">
              <i className="fas fa-search tm-search-icon" aria-hidden="true" />
            </button>
          </form>


  </div>
  {/* Navbar End */}
  {/* Main News Slider Start */}
  <div className="container-fluid">
    <div className="row">
      <div className="col-lg-7 px-0">
        <div className="owl-carousel main-carousel position-relative">
          <div
            className="position-relative overflow-hidden"
            style={{ height: 500 }}
          >
            <img
              className="img-fluid h-100"
              src= { newsletter_highlight?.data? (process.env.REACT_APP_UPLOAD_URL + newsletter_highlight?.data[0]?.attributes?.image1?.data?.attributes?.url):("")}
              style={{ objectFit: "cover" }}
            />
            <div className="overlay">
              <div className="mb-2">
                <a
                  className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2"
                  href=""
                >
                  {newsletter_highlight?.data? newsletter_highlight?.data[0]?.attributes?.category : "Loading"}
                </a>
                <a className="text-white" href="">
                {Date(newsletter_highlight?.data? newsletter_highlight?.data[0]?.attributes?.createdAt : "Loading")}
                </a>
              </div>
              <a
                className="h2 m-0 text-white text-uppercase font-weight-bold"
                href=""
              >
                {newsletter_highlight?.data? newsletter_highlight?.data[0]?.attributes?.title : "Loading"}
              </a>
            </div>
          </div>


        </div>
      </div>
      <div className="col-lg-5 px-0">
        <div className="row mx-0">
          <div className="col-md-6 px-0">
            <div
              className="position-relative overflow-hidden"
              style={{ height: 250 }}
            >
              <img
                className="img-fluid w-100 h-100"
                src= { newsletter_highlight?.data? (process.env.REACT_APP_UPLOAD_URL + newsletter_highlight?.data[1]?.attributes?.image1?.data?.attributes?.url):("")}
                style={{ objectFit: "cover" }}
              />
              <div className="overlay">
                <div className="mb-2">
                  <a
                    className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2"
                    href=""
                  >
                    {newsletter_highlight?.data? newsletter_highlight?.data[1]?.attributes?.category : "Loading"}
                  </a>
                  <a className="text-white" href="">
                    <small>   {Date(newsletter_highlight?.data? newsletter_highlight?.data[1]?.attributes?.createdAt : "Loading")}</small>
                  </a>
                </div>
                <a
                  className="h6 m-0 text-white text-uppercase font-weight-semi-bold"
                  href=""
                >
                  Lorem ipsum dolor sit amet elit...
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6 px-0">
            <div
              className="position-relative overflow-hidden"
              style={{ height: 250 }}
            >
              <img
                className="img-fluid w-100 h-100"
                src= { newsletter_highlight?.data? (process.env.REACT_APP_UPLOAD_URL + newsletter_highlight?.data[2]?.attributes?.image1?.data?.attributes?.url):("")}
                style={{ objectFit: "cover" }}
              />
              <div className="overlay">
                <div className="mb-2">
                  <a
                    className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2"
                    href=""
                  >
                   {newsletter_highlight?.data? newsletter_highlight?.data[2]?.attributes?.category : "Loading"}
                  </a>
                  <a className="text-white" href="">
                    <small>   {Date(newsletter_highlight?.data? newsletter_highlight?.data[2]?.attributes?.createdAt : "Loading")}</small>
                  </a>
                </div>
                <a
                  className="h6 m-0 text-white text-uppercase font-weight-semi-bold"
                  href=""
                >
                  Lorem ipsum dolor sit amet elit...
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6 px-0">
            <div
              className="position-relative overflow-hidden"
              style={{ height: 250 }}
            >
              <img
                className="img-fluid w-100 h-100"
                src= { newsletter_highlight?.data? (process.env.REACT_APP_UPLOAD_URL + newsletter_highlight?.data[3]?.attributes?.image1?.data?.attributes?.url):("")}
                style={{ objectFit: "cover" }}
              />
              <div className="overlay">
                <div className="mb-2">
                  <a
                    className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2"
                    href=""
                  >
                   {newsletter_highlight?.data? newsletter_highlight?.data[3]?.attributes?.category : "Loading"}
                  </a>
                  <a className="text-white" href="">
                    <small>   {Date(newsletter_highlight?.data? newsletter_highlight?.data[3]?.attributes?.createdAt : "Loading")}</small>
                  </a>
                </div>
                <a
                  className="h6 m-0 text-white text-uppercase font-weight-semi-bold"
                  href=""
                >
                  Lorem ipsum dolor sit amet elit...
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6 px-0">
            <div
              className="position-relative overflow-hidden"
              style={{ height: 250 }}
            >
              <img
                className="img-fluid w-100 h-100"
                src= { newsletter_highlight?.data? (process.env.REACT_APP_UPLOAD_URL + newsletter_highlight?.data[4]?.attributes?.image1?.data?.attributes?.url):("")}
                style={{ objectFit: "cover" }}
              />
              <div className="overlay">
                <div className="mb-2">
                  <a
                    className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2"
                    href=""
                  >
                   {newsletter_highlight?.data? newsletter_highlight?.data[4]?.attributes?.category : "Loading"}
                  </a>
                  <a className="text-white" href="">
                    <small>   {Date(newsletter_highlight?.data? newsletter_highlight?.data[4]?.attributes?.createdAt : "Loading")}</small>
                  </a>
                </div>
                <a
                  className="h6 m-0 text-white text-uppercase font-weight-semi-bold"
                  href=""
                >
                  Lorem ipsum dolor sit amet elit...
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Main News Slider End */}
  {/* Breaking News Start */}

  {/* Breaking News End */}

  {/* News With Sidebar Start */}
  <div className="container-fluid" style={{marginTop:"5%"}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h4 className="m-0 text-uppercase font-weight-bold">
                  Latest News
                </h4>
                <a
                  className="text-secondary font-weight-medium text-decoration-none"
                  href=""
                >
                  View All
                </a>
              </div>
                    </div>{
                      currentPageData?.filter((item) => {
                      return search.toLowerCase() === " "
                        ? item
                        : item.attributes?.title?.toLowerCase().includes(search);
                    })?.map(x =>
                        <div className="col-lg-4 gallery_anim" key={x.id}>
                          <Link to={`/Tnews/${x.id}`}>

              <div className="position-relative mb-3">
                <img
                  className="img-fluid w-100"
                  src={x.attributes?.image1?.data ? process.env.REACT_APP_UPLOAD_URL + x.attributes?.image1?.data?.attributes?.url : ""}
                  style={{ objectFit: "cover",height:"200px" }}
                />
                <div className="bg-white border border-top-0 p-4">
                  <div className="mb-2">
                    <a
                      className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-3"
                      href=""
                    >
                     {x.attributes?.category}
                    </a>
                    <a className="text-body" href="">
                      <small>{ Date( x.attributes?.createdAt)}</small>
                    </a>
                  </div>
                  <a
                    className="h6 d-block mb-3 text-secondary text-uppercase font-weight-bold"
                    href=""
                  >
                     {x.attributes?.title}
                  </a>
                  <p className="m-0">
                  {x.attributes?.desc1?.slice(0,100)}
                  </p>
                </div>

                            </div>
                            </Link>
            </div>
                        )
            }

<div className="row" style={{ paddingLeft: "15%" }}>
                  <div  >
                  <div className="row tm-row tm-mt-100 tm-mb-75">
        <div className="tm-prev-next-wrapper  ">
          <a
            href="#"
            className="mb-2 tm-btn tm-btn-primary tm-prev-next   tm-mr-20" onClick={previousPage}
          >
            Prev
          </a>
          <a href="#" className="mb-2 tm-btn tm-btn-primary tm-prev-next" onClick={nextPage}>
            Next
          </a>
        </div>
        <div className="tm-paging-wrapper  ">
          <span className="d-inline-block mr-3">    </span>
          <nav className="tm-paging-nav d-inline-block">
            <ul>
            Page
              <li className="tm-paging-item active">
                <a href="#" className="mb-2 tm-btn tm-paging-link" style={{margin:"10px"}}>
                {currentPage + 1}/{pageCount}
                </a>
              </li>


            </ul>
          </nav>
            </div>


      </div>
                  </div>
                </div>

          </div>
        </div>
        <div className="col-lg-4">
          {/* Social Follow Start */}

          {/* Social Follow End */}
          {/* Ads Start */}

          {/* Ads End */}
            {/* Popular News Start */}
            <div className="mb-3 right_anime">
            <div className="section-title mb-0  ">
              <h4 className="m-0 text-uppercase font-weight-bold">
            World Class Quality Award Winners List
              </h4>
            </div>
                    <div className="bg-white border border-top-0 p-3">
                      {
                        world_class_winners_list?.data?.slice(0,3).map((item) => (
<div key={item.id}
                className="d-flex align-items-center bg-white mb-3 "
                style={{ height: 110 }}
                          >


                <img
                  className="img-fluid"
                  src={process.env.REACT_APP_UPLOAD_URL + item?.attributes?.image?.data[0]?.attributes?.url}  style={{height:"60%"}}
                  alt=""
                />      <Link to='/worldclass'>
                <div className="w-100 h-100 px-3 d-flex flex-column justify-content-center border border-left-0">
                  <div className="mb-2">
                    <a
                      className="badge badge-primary text-uppercase font-weight-semi-bold p-1 mr-2"
                      href=""
                    >
                    { item?.attributes?.category}
                    </a>
                    <a className="text-body" href="">
                      <small>   {Date( item?.attributes?.year)}</small>
                    </a>
                                </div>

                  <a
                    className="h6 m-0 text-secondary text-uppercase font-weight-bold"
                    href=""
                  >
                     { item?.attributes?.name}
                  </a>
                              </div>
                              </Link>
              </div>
                        ))
                      }
                      <a   className="badge badge-secondary text-uppercase font-weight-semi-bold p-1 mr-2"  href='/worldclass' style={{color:"white"}}>Read More</a>

            </div>
          </div>
                  {/* Popular News End */}
                         {/* Popular News Start */}
            <div className="mb-3 right_anime">
            <div className="section-title mb-0">
              <h4 className="m-0 text-uppercase font-weight-bold">
            Abisiniya Industry Award Winners List
              </h4>
            </div>
                    <div className="bg-white border border-top-0 p-3">
                      {
                        abissiniya_industry_award_winners_list?.data?.slice(0,3).map((item) => (
<div key={item.id}
                className="d-flex align-items-center bg-white mb-3"
                style={{ height: 110 }}
              >
                <img
                  className="img-fluid"
                  src={process.env.REACT_APP_UPLOAD_URL + item?.attributes?.image?.data[0]?.attributes?.url}  style={{height:"60%"}}
                  alt=""
                            />
                            <Link to='/abisiniyaindustrywinners'>

                <div className="w-100 h-100 px-3 d-flex flex-column justify-content-center border border-left-0">
                  <div className="mb-2">
                    <a
                      className="badge badge-primary text-uppercase font-weight-semi-bold p-1 mr-2"
                      href=""
                    >
                    { item?.attributes?.category}
                    </a>
                    <a className="text-body" href="">
                      <small>   {Date( item?.attributes?.year)}</small>
                    </a>
                  </div>
                  <a
                    className="h6 m-0 text-secondary text-uppercase font-weight-bold"
                    href=""
                  >
                     { item?.attributes?.name}
                  </a>
                </div> </Link>
              </div>
                        ))
                      }
                      <a   className="badge badge-secondary text-uppercase font-weight-semi-bold p-1 mr-2"  href='/abisiniyaindustrywinners' style={{color:"white"}}>Read More</a>

            </div>
          </div>
                  {/* Popular News End */}
                       {/* Popular News Start */}
            <div className="mb-3 right_anime">
            <div className="section-title mb-0">
              <h4 className="m-0 text-uppercase font-weight-bold">
            Abisiniya Award Winners List
              </h4>
            </div>
                    <div className="bg-white border border-top-0 p-3">
                      {
                        abissiniya_award_winners_list?.data?.slice(0,3).map((item) => (
<div key={item.id}
                className="d-flex align-items-center bg-white mb-3"
                style={{ height: 110 }}
              >
                <img
                  className="img-fluid"
                  src={process.env.REACT_APP_UPLOAD_URL + item?.attributes?.image?.data[0]?.attributes?.url}  style={{height:"60%"}}
                  alt=""
                            />
                            <Link  to='/abisiniyaawardwinners'>
                <div className="w-100 h-100 px-3 d-flex flex-column justify-content-center border border-left-0">
                  <div className="mb-2">
                    <a
                      className="badge badge-primary text-uppercase font-weight-semi-bold p-1 mr-2"
                      href=""
                    >
                    { item?.attributes?.category}
                    </a>
                    <a className="text-body" href="">
                      <small>   {Date( item?.attributes?.year)}</small>
                    </a>
                  </div>
                  <a
                    className="h6 m-0 text-secondary text-uppercase font-weight-bold"
                    href=""
                  >
                     { item?.attributes?.name}
                  </a>
                </div></Link>
              </div>
                        ))
                      }
                      <a   className="badge badge-secondary text-uppercase font-weight-semi-bold p-1 mr-2"  href='/abisiniyaawardwinners' style={{color:"white"}}>Read More</a>

            </div>
          </div>
          {/* Popular News End */}


 </div>
      </div>
    </div>
  </div>

</div>

      </div>
    )

}

export default News
