import React, { Component } from "react";
import useFetch from "../hooks/useFetch";
import { makeRequest } from "../MakeReq/makeRequest";
import { useState } from "react";
import "./blog.css"
import {Link, useParams} from "react-router-dom"

const Blog = () => {
    const {id} = useParams()
     console.log(id , "id")
    const header_image = useFetch(`/header-image?populate=*`);
    console.log(header_image, "header_image");
    const blog = useFetch(`/blogs/${id}?populate=*`);
    console.log(blog, "blog");

    return (
        <div>
              <main id="main">
          {/* ======= Breadcrumbs ======= */}
          <div
            className="breadcrumbs d-flex align-items-center"
            style={{
              backgroundImage: `url(${
                process.env.REACT_APP_UPLOAD_URL +
                header_image.data?.attributes?.image?.data?.attributes?.url
              })`,
            }}>
            <div
              className="container position-relative d-flex flex-column align-items-center"
              data-aos="fade">
              <h2>Blog</h2>
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                            <li ><a href="/blogs">Blogs</a></li>
                            <li style={{ textTransform:"capitalize"}}>  {blog?.data?.attributes?.title}</li>
              </ol>
            </div>
          </div>
     <div className="container-fluid">
  <main className="tm-main">
    {/* Search form */}

    <div className="row  ">


        {/* Video player 1422x800 */}


                                    {
                                        blog.data?.attributes?.images?.data?.map(x => (

                                      <img src={process.env.REACT_APP_UPLOAD_URL + x.attributes?.url} className="col-md-4" style={{height:"300px",margin:"5px"}} ></img>

))
                                    }



      <div className="col-lg-8 tm-post-col">
        <div className="tm-post-full">
          <div className="mb-4">
            <h2 className="pt-2 tm-color-primary tm-post-title">
               {blog?.data?.attributes?.title}
            </h2>
            <p className="tm-mb-40"> {Date(blog?.data?.attributes?.createdAt)}</p>
            <p>
              {blog?.data?.attributes?.desc}
            </p>

            {/* <span className="d-block text-right tm-color-primary">
              Creative . Design . Business
            </span> */}
          </div>
          {/* Comments */}

        </div>
      </div>
      <aside className="col-lg-4 tm-aside-col">
        {/* <div className="tm-post-sidebar">
          <hr className="mb-3 tm-hr-primary" />
          <h2 className="mb-4 tm-post-title tm-color-primary">Categories</h2>
          <ul className="tm-mb-75 pl-5 tm-category-list">
            <li>
              <a href="#" className="tm-color-primary">
                Visual Designs
              </a>
            </li>
            <li>
              <a href="#" className="tm-color-primary">
                Travel Events
              </a>
            </li>
            <li>
              <a href="#" className="tm-color-primary">
                Web Development
              </a>
            </li>
            <li>
              <a href="#" className="tm-color-primary">
                Video and Audio
              </a>
            </li>
            <li>
              <a href="#" className="tm-color-primary">
                Etiam auctor ac arcu
              </a>
            </li>
            <li>
              <a href="#" className="tm-color-primary">
                Sed im justo diam
              </a>
            </li>
          </ul>
          <hr className="mb-3 tm-hr-primary" />
          <h2 className="tm-mb-40 tm-post-title tm-color-primary">
            Related Posts
          </h2>
          <a href="#" className="d-block tm-mb-40">
            <figure>
              <img
                src="img/img-02.jpg"
                alt="Image"
                className="mb-3 img-fluid"
              />
              <figcaption className="tm-color-primary">
                Duis mollis diam nec ex viverra scelerisque a sit
              </figcaption>
            </figure>
          </a>
          <a href="#" className="d-block tm-mb-40">
            <figure>
              <img
                src="img/img-05.jpg"
                alt="Image"
                className="mb-3 img-fluid"
              />
              <figcaption className="tm-color-primary">
                Integer quis lectus eget justo ullamcorper ullamcorper
              </figcaption>
            </figure>
          </a>
          <a href="#" className="d-block tm-mb-40">
            <figure>
              <img
                src="img/img-06.jpg"
                alt="Image"
                className="mb-3 img-fluid"
              />
              <figcaption className="tm-color-primary">
                Nam lobortis nunc sed faucibus commodo
              </figcaption>
            </figure>
          </a>
        </div> */}
      </aside>
    </div>

  </main>
</div>
</main>
      </div>
    )

}

export default Blog
