import React, { Component } from "react";
import useFetch from "../hooks/useFetch";
import { makeRequest } from "../MakeReq/makeRequest";
import { useState } from "react";
import "./blog.css"
import {Link} from "react-router-dom"
const Blogs = () => {
  const [PER_PAGE, setPER_PAGE] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  console.log(search, 'search')

  const header_image = useFetch(`/header-image?populate=*`);
  console.log(header_image, "header_image");
  const blogs = useFetch(`/blogs?populate=*`);
  console.log(blogs, "blogs");

  const pageCount = Math.ceil(blogs ?.data?.length / PER_PAGE);
  const offset = currentPage * PER_PAGE;
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  //console.log(selectedCats, "the search");
  //console.log(subcateg, "the search");
  const previousPage = () => {
    if (currentPage !== 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== pageCount - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const currentPageData = blogs?.data?.slice(offset, offset + PER_PAGE);

//  console.log(finalData, 'finalData')
  return <div><>
     <main id="main">
          {/* ======= Breadcrumbs ======= */}
          <div
            className="breadcrumbs d-flex align-items-center"
            style={{
              backgroundImage: `url(${
                process.env.REACT_APP_UPLOAD_URL +
                header_image.data?.attributes?.image?.data?.attributes?.url
              })`,
            }}>
            <div
              className="container position-relative d-flex flex-column align-items-center"
              data-aos="fade">
              <h2>Blogs</h2>
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Blogs</li>
              </ol>
            </div>
          </div>
          {/* End Breadcrumbs */}
          {/* ======= About Section ======= */}

      {/* Search form */}
      <div className="row  " style={{ marginTop: "2% ",marginLeft:"10%",marginRight:"10%" }}>

        <div className="col-sm-12 col-md-12 col-lg-6" >

          {/* <div   style={{padding:"1rem",color:"#52565E"}}>
            <h2 style={{color:"#52565E",marginLeft:"3rem"}}> Search Blogs Here</h2>
            </div> */}

        </div>
        <div className="col-sm-12 col-md-12 col-lg-6">
          <form method="GET" className="form-inline tm-mb-80 tm-search-form"   >
            <input
              className="form-control tm-search-input"
             name="search"
              type="text"
              onChange={(e) => setSearch(e.target.value)}
              placeholder=" Search Blogs Here..."
              aria-label="Search"
            />
            <button className="tm-search-button" type="button">
              <i className="fas fa-search tm-search-icon" aria-hidden="true" />
            </button>
          </form>
        </div>
      </div>
      <di className="container-fluid" >
      <div className="row  " style={{paddingLeft:"15%", paddingRight:"15%",marginTop:"-4%"}} >
          { currentPageData?.filter((item) => {
    return search.toLowerCase() === " "
      ? item
      : item.attributes?.title?.toLowerCase().includes(search);
  })?.map(x => (<div key={blogs.id}  className="col-lg-4 col-md-4 col-sm-12  gallery_anim">
            <Link to={`/blog/${x.id}`}>
          <hr className="tm-hr-primary" />
          <a href="post.html" className="effect-lily tm-post-link tm-pt-20">
            <div className="tm-post-link-inner">
                  <img src={process.env.REACT_APP_UPLOAD_URL + x.attributes?.images?.data[0]?.attributes?.url}
                    alt="Image"
                    className="img-fluid"
                     style={{height:"300px"}}
                  />
            </div>
            <h2 className="tm-pt-30 tm-color-primary tm-post-title" style={{textTransform:"capitalize"}}>
            {x.attributes?.title}
            </h2>
          </a>
          <p className="tm-pt-30">
          {(x.attributes?.desc).slice(0,200)} . . .
          </p>
          <div className="d-flex justify-content-between tm-pt-35">
            <span className="tm-color-primary">Date Posted</span>
            <span className="tm-color-primary"> {Date((x.attributes?.createdAt))} </span>
          </div>
          <hr />

        </Link>
</div>))}

      </div>
   </di>


   <div className="row" style={{ paddingLeft: "15%" }}>
                  <div  >
                  <div className="row tm-row tm-mt-100 tm-mb-75">
        <div className="tm-prev-next-wrapper  ">
          <a
            href="#"
            className="mb-2 tm-btn tm-btn-primary tm-prev-next   tm-mr-20" onClick={previousPage}
          >
            Prev
          </a>
          <a href="#" className="mb-2 tm-btn tm-btn-primary tm-prev-next" onClick={nextPage}>
            Next
          </a>
        </div>
        <div className="tm-paging-wrapper  ">
          <span className="d-inline-block mr-3">    </span>
          <nav className="tm-paging-nav d-inline-block">
            <ul>
            Page
              <li className="tm-paging-item active">
                <a href="#" className="mb-2 tm-btn tm-paging-link" style={{margin:"10px"}}>
                {currentPage + 1}/{pageCount}
                </a>
              </li>


            </ul>
          </nav>
            </div>


      </div>
                  </div>
                </div>




      </main>
</>
</div>;
};

export default Blogs;
