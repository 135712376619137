import React, { Component } from "react";
import { Link, useLocation } from "react-router-dom";
import { UserData } from "../helper";
import { useNavigate } from "react-router-dom";

const Nav = () => {
  const location = useLocation();
  const user = UserData();
  // console.log(user, "user");
  const logout = () => {
    localStorage.removeItem("user", {});
    navigate("/Home");
  };
  const navigate = useNavigate();
  return (
    <div>
      <header id="header" className="header d-flex align-items-center">
        <div className="container-fluid   d-flex align-items-center justify-content-between" style={{paddingLeft:"2%",paddingRight:"2%"}}>
          <a href="/Home" className="logo d-flex align-items-center">
            {/* Uncomment the line below if you also wish to use an image logo */}
            {/* <img src="assets/img/logo.png" alt=""> */}
            <h1>
              World Class Quality Award<span>.</span>
            </h1>
          </a>
          {/* <i className="mobile-nav-toggle mobile-nav-show bi bi-list" /> */}

          <div className="accordion  mobile-nav-show  " id="accordionExample"   >
  <div className="  accordion-item   " >
    <h2 className="accordion-header" id="headingOne">
      <button
        className="accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseOne" style={{color:"white",backgroundColor: 'rgba(52, 52, 52, 1)' }}

      >
               <i className="bi bi-list" style={{fontSize:"1rem"}}/>

      </button>
    </h2>
    <div
      id="collapseOne"
      className="accordion-collapse collapse show"
      aria-labelledby="headingOne"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body "  style={{color:"white",backgroundColor: 'rgba(52, 52, 52, 1)' }}   >
      <ul className="row" >
              <li className="col-12">
                <a className={location.pathname == "/Home" ? "active" : ""}>
                  <Link to="/Home" style={{ color: "white" }}>
                    Home
                  </Link>
                </a>
              </li>
              <li className="col-12">
                <a className={location.pathname == "/about" ? "active" : ""}>
                  <Link to="/about" style={{ color: "white" }}>
                    About
                  </Link>
                </a>
              </li>
              <li  className="col-12">
                <a
                  className={
                    location.pathname == "/"
                      ? "active"
                      : ""
                  }>
                  <Link
                    to="/"
                    style={{ color: "white" }}>
                    AbissiniyaIndustryAwards
                  </Link>
                </a>
              </li>
              <li   className="col-12">
                <a
                  className={
                    location.pathname == "/abissiniyaawards" ? "active" : ""
                  }>
                  <Link to="/abissiniyaawards" style={{ color: "white" }}>
                    AbissiniyaAwards
                  </Link>
                </a>
              </li>
              <li  className="col-12">
                <a className={location.pathname == "/Blogs" ? "active" : ""}>
                  <Link to="/Blogs" style={{ color: "white" }}>
                    Blogs
                  </Link>
                </a>
                    </li>
                    <li className="col-12">
                <a className={location.pathname == "/news" ? "active" : ""}>
                  <Link to="/news" style={{ color: "white" }}>
                    Newsletter
                  </Link>
                </a>
              </li>
              <li className="col-12">
                <a className={location.pathname == "/gallary" ? "active" : ""}>
                  <Link to="/gallary" style={{ color: "white" }}>
                    Gallary
                  </Link>
                </a>
              </li>
              <li   className="col-12">
                <a className={location.pathname == "/contact" ? "active" : ""}>
                  <Link to="/contact" style={{ color: "white" }}>
                    Contact
                  </Link>
                </a>
              </li>


              {user == "" ? (
                ""
              ) : (
                <li   className="col">
                  <Link to="/profile">
                    <div style={{   color: "white" }}>
                      {user?.email}
                    </div>
                    <i
                      class="bi bi-person-square"
                      style={{ fontSize: "1.5rem", color: "#FEB900" }}></i>{" "}
                  </Link>
                </li>
              )}
              <li   className="col">
                {user == "" ? (
                  <button
                    class="btn btn-primary"
                    style={{
                      backgroundColor: "#FEB900",
                      border: "1px solid #FEB900 ",
                    }}
                    onClick={(e) => navigate("/login")}>
                    Sign In
                  </button>
                ) : (
                  <button
                    class="btn btn-primary"
                    style={{
                      backgroundColor: "#FEB900",
                      border: "1px solid #FEB900 ",
                    }}
                    onClick={() => {
                      logout();
                    }}>
                    Sign Out
                  </button>
                )}
              </li>
            </ul>
      </div>
    </div>
  </div>
</div>

          <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x" />



          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a className={location.pathname == "/Home" ? "active" : ""}>
                  <Link to="/Home" style={{ color: "white" }}>
                    Home
                  </Link>
                </a>
              </li>
              <li>
                <a className={location.pathname == "/about" ? "active" : ""}>
                  <Link to="/about" style={{ color: "white" }}>
                    About
                  </Link>
                </a>
              </li>
              <li>
                <a
                  className={
                    location.pathname == "/"
                      ? "active"
                      : ""
                  }>
                  <Link
                    to="/"
                    style={{ color: "white" }}>
                    Abissiniya Industry Awards
                  </Link>
                </a>
              </li>
              <li>
                <a
                  className={
                    location.pathname == "/abissiniyaawards" ? "active" : ""
                  }>
                  <Link to="/abissiniyaawards" style={{ color: "white" }}>
                    Abissiniya Awards
                  </Link>
                </a>
              </li>
              <li>
                <a className={location.pathname == "/Blogs" ? "active" : ""}>
                  <Link to="/Blogs" style={{ color: "white" }}>
                    Blogs
                  </Link>
                </a>
              </li>
              <li>
                <a className={location.pathname == "/News" ? "active" : ""}>
                  <Link to="/News" style={{ color: "white" }}>
                  Newsletter
                  </Link>
                </a>
              </li>
              <li>
                <a className={location.pathname == "/gallary" ? "active" : ""}>
                  <Link to="/gallary" style={{ color: "white" }}>
                    Gallary
                  </Link>
                </a>
              </li>
              {/* <li>
                <a className={location.pathname == "/contact" ? "active" : ""}>
                  <Link to="/contact" style={{ color: "white" }}>
                    Contact
                  </Link>
                </a>
              </li> */}
              {/* <li>
                <Link to="/contact">
                  {" "}
                  <button type="button" className="btn btn-warning">
                    How To Apply
                  </button>
                </Link>
              </li> */}

              {user == "" ? (
                ""
              ) : (
                <li>
                  <Link to="/profile">
                    <div style={{ paddingLeft: "1rem", color: "white" }}>
                      {/* {user?.email} */}
                    </div>
                    <i
                      class="bi bi-person-square"
                      style={{ fontSize: "1rem", color: "#FEB900" }}></i>{" "}
                  </Link>
                </li>
              )}
              <li>
                {user == "" ? (
                  <button
                    class="btn btn-primary"
                    style={{
                      backgroundColor: "#FEB900",
                      border: "1px solid #FEB900 ",
                    }}
                    onClick={(e) => navigate("/login")}>
                    Sign In
                  </button>
                ) : (
                  <button
                    class="btn btn-primary"
                    style={{
                      backgroundColor: "#FEB900",
                      border: "1px solid #FEB900 ",
                    }}
                    onClick={() => {
                      logout();
                    }}>
                    Sign Out
                  </button>
                )}
              </li>
            </ul>
          </nav>
          {/* .navbar */}
        </div>
      </header>
    </div>
  );
};

export default Nav;
