import React, { Component } from "react";
import useFetch from "../hooks/useFetch";
import { makeRequest } from "../MakeReq/makeRequest";
import { useState } from "react";
import { Routes, Route, useParams } from 'react-router-dom';

const Tnews = () => {
    const {id} = useParams()
    console.log(id,'xxxx')
    const header_image = useFetch(`/header-image?populate=*`);
    // console.log(header_image, "header_image");
    const newsletter_highlight = useFetch(`/newsletters/${id}?populate=*`);
    console.log(newsletter_highlight, "newsletter_highlight");
    return (
        <div>
             <main id="main">
          {/* ======= Breadcrumbs ======= */}
          <div
            className="breadcrumbs d-flex align-items-center"
            style={{
              backgroundImage: `url(${
                process.env.REACT_APP_UPLOAD_URL +
                header_image.data?.attributes?.image?.data?.attributes?.url
              })`,
            }}>
            <div
              className="container position-relative d-flex flex-column align-items-center"
              data-aos="fade">
              <h2>News</h2>
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                            <li>News</li>
                            <li>{newsletter_highlight?.data?.attributes?.title}</li>
              </ol>
            </div>
                </div>
                <div>
                <>
  {/* Topbar Start */}
  <div className="container-fluid d-none d-lg-block">
    <div className="row align-items-center bg-dark px-lg-5">

      <div className="col-lg-3 text-right d-none d-md-block">

      </div>
    </div>

  </div>

  <div className="container-fluid mt-5 mb-3 pt-3">
    <div className="container">

    </div>
  </div>
  {/* Breaking News End */}
  {/* News With Sidebar Start */}
  <div className="container-fluid">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          {/* News Detail Start */}
          <div className="position-relative mb-3">
            <img
              className="img-fluid w-25 gallery_anim"
              src= { newsletter_highlight?.data? (process.env.REACT_APP_UPLOAD_URL + newsletter_highlight?.data?.attributes?.image1?.data?.attributes?.url):("")}

              style={{ objectFit: "cover"  }}
            />
            <div className="bg-white border border-top-0 p-4">
              <div className="mb-3">
                <a
                  className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2"
                  href=""
                >
             {newsletter_highlight?.data?.attributes?.category}
                </a>
                <a className="text-body" href="">
                {Date(newsletter_highlight?.data?.attributes?.createdAt)}
                </a>
              </div>
              <h1 className="mb-3 text-secondary text-uppercase font-weight-bold  ">
              {newsletter_highlight?.data?.attributes?.tile}
              </h1>
              <p>
              {newsletter_highlight?.data?.attributes?.desc1}
              </p>


              <img
                className="img-fluid w-25 float-left mr-4 mb-2 gallery_anim"
                src= { newsletter_highlight?.data? (process.env.REACT_APP_UPLOAD_URL + newsletter_highlight?.data?.attributes?.image2?.data?.attributes?.url):("")}

              />
              <p>
              {newsletter_highlight?.data?.attributes?.desc2}
              </p>

            </div>
          </div>
          {/* News Detail End */}

        </div>

      </div>
    </div>
  </div>
  {/* News With Sidebar End */}


</>

                </div>
          {/* End Breadcrumbs */}
                {/* ======= About Section ======= */}
                </main>
</div>
    )

}

export default Tnews
