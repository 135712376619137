import React, { Component } from "react";
import useFetch from "../hooks/useFetch";
import { makeRequest } from "../MakeReq/makeRequest";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Player } from "video-react";
import "./anim.css";
import "./delay.js";

const Home = () => {
  const [open, setOpen] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [PER_PAGE, setPER_PAGE] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();
  const header_image = useFetch(`/header-image?populate=*`);
  // console.log(header_image, "header_image");

  const get_world_class_quality_award = useFetch(
    `/get-world-class-quality-award`
  );
  // console.log(get_world_class_quality_award, "get_world_class_quality_award ");

  const get_a_registered = useFetch(`/get-a-registered`);
  // console.log(get_a_registered, "get_a_registered ");

  const how_world_class_quality_award_work = useFetch(
    `/how-world-class-quality-award-work`
  );
  // console.log(
  //   how_world_class_quality_award_work,
  //   "how_world_class_quality_award_work "
  // );

  const world_class_quality_award_at_a_glance = useFetch(
    `/world-class-quality-award-at-a-glances`
  );
  // console.log(
  //   world_class_quality_award_at_a_glance,
  //   "world_class_quality_award_at_a_glance "
  // );
  const what_makes_world_class_quality_award_different = useFetch(
    `/what-makes-world-class-quality-award-different`
  );
  // console.log(
  //   what_makes_world_class_quality_award_different,
  //   "world_class_quality_award_at_a_glance "
  // );

  const what_makes_different_2 = useFetch(`/what-makes-different-2s`);
  // console.log(what_makes_different_2, "what_makes_different_2");

  const what_is_performance_excellence = useFetch(
    `/what-is-performance-excellence`
  );
  // console.log(what_is_performance_excellence, "what_is_performance_excellence");

  const what_is_performance_desc = useFetch(`/what-is-performance-descs`);
  // console.log(what_is_performance_desc, "what_is_performance_desc");

  const self_assessing_your_organization = useFetch(
    `/self-assessing-your-organization`
  );
  // console.log(
  //   self_assessing_your_organization,
  //   "self_assessing_your_organization"
  // );

  const self_assessing_your_organ_text = useFetch(
    `/self-assessing-your-organ-texts`
  );
  // console.log(self_assessing_your_organ_text, "self_assessing_your_organ_text");

  const world_class_quality_award_category = useFetch(
    `/world-class-quality-award-category`
  );
  // console.log(
  //   world_class_quality_award_category,
  //   "world_class_quality_award_category"
  // );

  const world_class_quality_award_category_list = useFetch(
    `/world-class-quality-award-category-lists?populate=*&`
  );
  // console.log(
  //   world_class_quality_award_category_list,
  //   "world_class_quality_award_category_list "
  // );

  const our_award_gallery = useFetch(`/our-award-galleries?populate=*&`);
  // console.log(our_award_gallery, "our_award_gallery");

  const our_award_gallery_text = useFetch(
    `/our-award-gallery-text?populate=*&`
  );
  // console.log(our_award_gallery_text, "our_award_gallery_text");

  const world_class_testimonials = useFetch(
    `/world-class-testimonials?populate=*`
  );
  // console.log(world_class_testimonials, "world_class_testimonials");

  const carousel = useFetch(`/carousel?populate=*`);
  // console.log(carousel, "carousel");

  const home_image = useFetch(`/home-image?populate=*`);
  // console.log(home_image, "home-image");
  const event_video = useFetch(`/event-videos?populate=*`);
  console.log(event_video, "event_video");

  const pageCount = Math.ceil(event_video?.data?.length / PER_PAGE);
  const offset = currentPage * PER_PAGE;
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  //console.log(selectedCats, "the search");
  //console.log(subcateg, "the search");
  const previousPage = () => {
    if (currentPage !== 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== pageCount - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const currenteventvideo = event_video?.data?.slice(offset, offset + PER_PAGE);
  return (
    <div>
      <>
        {/* ======= Hero Section ======= */}
        <section id="hero" className="hero">
          <div className="info d-flex align-items-center">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 text-center">
                  <h2 data-aos="fade-down">
                    Welcome to <span>World Class Quality Award</span>
                  </h2>
                  <p data-aos="fade-up" className="left_anime">
                    {" "}
                    The World Class Quality Award Program educates organizations
                    in performance excellence management and administers the
                    World Class Quality Award National Quality Award. We are the
                    nation's public-private partnership dedicated to improving
                    the performance of ETHIOPIA. Organizations.
                  </p>
                  <a
                    data-aos="fade-up"
                    data-aos-delay={200}
                    href="#get-started"
                    className="btn-get-started">
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            id="hero-carousel"
            className="carousel slide"
            data-bs-ride="carousel"
            data-bs-interval={5000}>
            <div
              className="carousel-item active"
              style={{
                backgroundImage: `url(${
                  process.env.REACT_APP_UPLOAD_URL +
                  carousel?.data?.attributes?.image1?.data?.attributes?.url
                })`,
              }}
            />
            <div
              className="carousel-item"
              style={{
                backgroundImage: `url(${
                  process.env.REACT_APP_UPLOAD_URL +
                  carousel?.data?.attributes?.image2?.data?.attributes?.url
                })`,
              }}
            />
            <div
              className="carousel-item"
              style={{
                backgroundImage: `url(${
                  process.env.REACT_APP_UPLOAD_URL +
                  carousel?.data?.attributes?.image3?.data?.attributes?.url
                })`,
              }}
            />
            <div
              className="carousel-item"
              style={{
                backgroundImage: `url(${
                  process.env.REACT_APP_UPLOAD_URL +
                  carousel?.data?.attributes?.image4?.data?.attributes?.url
                })`,
              }}
            />
            <div
              className="carousel-item"
              style={{
                backgroundImage: `url(${
                  process.env.REACT_APP_UPLOAD_URL +
                  carousel?.data?.attributes?.image5?.data?.attributes?.url
                })`,
              }}
            />

            <a
              className="carousel-control-prev"
              href="#hero-carousel"
              role="button"
              data-bs-slide="prev">
              <span
                className="carousel-control-prev-icon bi bi-chevron-left"
                aria-hidden="true"
              />
            </a>
            <a
              className="carousel-control-next"
              href="#hero-carousel"
              role="button"
              data-bs-slide="next">
              <span
                className="carousel-control-next-icon bi bi-chevron-right"
                aria-hidden="true"
              />
            </a>
          </div>
        </section>
        {/* End Hero Section */}
        <main id="main">
          {/* ======= Get Started Section ======= */}
          <section id="get-started" className="get-started section-bg">
            <div className="container">
              <div className="row justify-content-between gy-4">
                <div
                  className="col-lg-6 d-flex align-items-center"
                  data-aos="fade-up">
                  <div className="content">
                    <h3>Get World Class Quality Award</h3>
                    <p className="left_anime">
                      {get_world_class_quality_award?.data?.attributes?.title}
                    </p>
                  </div>
                </div>
                <div className="col-lg-5" data-aos="fade">
                  <form>
                    <h3>Get a Registered</h3>
                    <p className="right_anime">
                      {get_a_registered?.data?.attributes?.title}
                    </p>{" "}
                    <div className="row ">
                      <div className="col-md-12 text-center">
                        <button
                          style={{
                            backgroundColor: "#FEB900",
                            padding: "0.5rem",
                            borderRadius: "0.3rem",
                            color: "white",
                          }}
                          onClick={() => {
                            navigate("/register");
                          }}>
                          Get a Registered
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                {/* End Quote Form */}
              </div>
            </div>
          </section>

          {/* End Get Started Section */}
          {/* ======= Alt Services Section ======= */}
          <section id="alt-services" className="alt-services">
            <div className="container" data-aos="fade-up">
              <div className="section-header">
                <h2>How World Class Quality Award Works ?</h2>
                <p>
                  {how_world_class_quality_award_work?.data?.attributes?.title}
                </p>
              </div>
            </div>
            <div className="container" data-aos="fade-up">
              <div className="row justify-content-around gy-4">
                <div className="col-lg-5 d-flex flex-column justify-content-center">
                  <h3>World Class Quality Award at a Glance</h3>
                  {world_class_quality_award_at_a_glance?.data?.map((x) => (
                    <div
                      key={x.id}
                      className="icon-box d-flex position-relative left_anime"
                      data-aos="fade-up"
                      data-aos-delay={100}>
                      <i className="bi  bi-award  flex-shrink-0" />
                      <div>
                        <p>{x.attributes?.title}</p>
                      </div>
                    </div>
                  ))}

                  {/* End Icon Box */}
                </div>
                <div
                  className="col-lg-6 img-bg right_anime"
                  style={{
                    backgroundImage: `url(${
                      process.env.REACT_APP_UPLOAD_URL +
                      home_image?.data?.attributes?.image1?.data?.attributes
                        ?.url
                    })`,
                  }}
                  data-aos="zoom-in"
                  data-aos-delay={100}
                />
              </div>
            </div>
          </section>
          {/* End Alt Services Section */}
          {/* ======= Alt Services Section 2 ======= */}
          <section id="alt-services-2" className="alt-services section-bg ">
            <div className="container" data-aos="fade-up">
              <div className="row justify-content-around gy-4 ">
                <div
                  className="col-lg-6 img-bg left_anime"
                  style={{
                    backgroundImage: `url(${
                      process.env.REACT_APP_UPLOAD_URL +
                      home_image?.data?.attributes?.image2?.data?.attributes
                        ?.url
                    })`,
                  }}
                  data-aos="zoom-in"
                  data-aos-delay={100}
                />
                <div className="col-lg-5 d-flex flex-column justify-content-center right_anime">
                  <h3>What Makes World Class Quality Award Different</h3>
                  <p style={{ textAlign: "justify" }}>
                    {
                      what_makes_world_class_quality_award_different?.data
                        ?.attributes?.title
                    }{" "}
                  </p>
                  <div
                    className="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay={100}>
                    <i className="bi  bi-award flex-shrink-0" />
                    <div>
                      {what_makes_different_2?.data?.map((x) => (
                        <div key={x.id}>
                          <p style={{ textAlign: "justify" }}>
                            {x.attributes?.title}
                            <br />
                          </p>{" "}
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* End Icon Box */}
                </div>
              </div>
            </div>
          </section>
          {/* End Alt Services Section 2 */}
          <section id="alt-services" className="alt-services">
            <div className="container" data-aos="fade-up"></div>
            <div className="container" data-aos="fade-up">
              <div className="row justify-content-around gy-4">
                <div className="col-lg-5 d-flex flex-column justify-content-center ">
                  <h3>What Is Performance Excellence?</h3>

                  <p style={{ textAlign: "justify" }}>
                    {what_is_performance_excellence?.data?.attributes?.title}
                  </p>
                  {what_is_performance_desc?.data?.map((x) => (
                    <div
                      key={x.id}
                      className="icon-box d-flex position-relative left_anime"
                      data-aos="fade-up"
                      data-aos-delay={100}>
                      <i className="bi  bi-award  flex-shrink-0" />
                      <div>
                        <p>{x.attributes?.title}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className="col-lg-6 img-bg right_anime"
                  style={{
                    backgroundImage: `url(${
                      process.env.REACT_APP_UPLOAD_URL +
                      home_image?.data?.attributes?.image3?.data?.attributes
                        ?.url
                    })`,
                  }}
                  data-aos="zoom-in"
                  data-aos-delay={100}
                />
              </div>
            </div>
          </section>
          {/* End Alt Services Section */}
          {/* End Alt Services Section 2 */}
          <section id="alt-services" className="alt-services">
            <div className="container" data-aos="fade-up"></div>
            <div className="container" data-aos="fade-up">
              <div className="row justify-content-around gy-4">
                <div
                  className="col-lg-6 img-bg left_anime"
                  style={{
                    backgroundImage: `url(${
                      process.env.REACT_APP_UPLOAD_URL +
                      home_image?.data?.attributes?.image4?.data?.attributes
                        ?.url
                    })`,
                  }}
                  data-aos="zoom-in"
                  data-aos-delay={100}
                />
                <div className="col-lg-5 d-flex flex-column justify-content-center">
                  <h3>Self-Assessing Your Organization</h3>
                  <p style={{ textAlign: "justify" }}>
                    {self_assessing_your_organization?.data?.attributes?.title}
                  </p>
                  <div
                    className="icon-box d-flex position-relative right_anime"
                    data-aos="fade-up"
                    data-aos-delay={100}>
                    <i className="bi  bi-award  flex-shrink-0" />
                    <div>
                      {self_assessing_your_organ_text?.data?.map((x) => (
                        <p key={x.id}> {x.attributes?.title}</p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End Alt Services Section */}
          {/* ======= Constructions Section ======= */}
          <section id="constructions" className="constructions">
            <div className="container" data-aos="fade-up">
              <div className="section-header">
                <h2>World Class Quality Award Categories</h2>

                <p>
                  {world_class_quality_award_category?.data?.attributes?.title}
                </p>
              </div>
              <div className="row gy-4">
                {world_class_quality_award_category_list?.data?.map((x) => (
                  <div
                    key={x.id}
                    className={
                      x.id % 2 == 0
                        ? "col-lg-6 right_anime"
                        : "col-lg-6 left_anime "
                    }
                    data-aos="fade-up"
                    data-aos-delay={100}>
                    <div className="card-item">
                      <div className="row">
                        <div className="col-xl-5">
                          <div
                            className="card-bg"
                            style={{
                              backgroundImage: `url(${
                                process.env.REACT_APP_UPLOAD_URL +
                                x.attributes?.image?.data?.attributes?.url
                              })`,
                            }}></div>
                        </div>

                        <div className="col-xl-7 d-flex align-items-center">
                          <div className="card-body">
                            <h4 className="card-title">
                              {x.attributes?.title}
                            </h4>
                            <p>{x.attributes?.desc}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          {/* End Constructions Section */}
          {/* ======= Our Projects Section ======= */}
          <section id="projects" className="projects">
            <div className=" " data-aos="fade-up">
              <div className="section-header">
                <h2>Some Of Our Events</h2>
                <p>{our_award_gallery_text?.data?.attributes?.title} </p>
              </div>
              <div
                className="row"
                style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                {currenteventvideo?.map((x) => (
                  <div key={x.id} className="col-sm-4 col-md-4 col-lg-4 col-12">
                    {/* <video src={process.env.REACT_APP_UPLOAD_URL + x.attributes?.video?.data[0]?.attributes?.url}></video> */}
                    {x.attributes?.video?.data[0]?.attributes?.url ? (
                      <div>
                        <video
                          width={320}
                          height={240}
                          controls
                          loop
                          muted
                          preload="metadata">
                          <source
                            src={
                              process.env.REACT_APP_UPLOAD_URL +
                              x.attributes?.video?.data[0]?.attributes?.url +
                              "#t=4"
                            }
                            type="video/mp4"
                          />
                        </video>
                        <p></p>
                      </div>
                    ) : (
                      ""
                    )}
                    {x.attributes?.title}
                  </div>
                ))}

                <div className="row" style={{ paddingLeft: "15%" }}>
                  <div>
                    <div className="row tm-row tm-mt-100 tm-mb-75">
                      <div className="tm-prev-next-wrapper  ">
                        <button
                          href="#"
                          className="mb-2 tm-btn tm-btn-primary tm-prev-next   tm-mr-20"
                          onClick={previousPage}>
                          Prev
                        </button>
                        <button
                          href="#"
                          className="mb-2 tm-btn tm-btn-primary tm-prev-next"
                          onClick={nextPage}>
                          Next
                        </button>
                      </div>
                      <div className="tm-paging-wrapper  ">
                        <span className="d-inline-block mr-3"> </span>
                        <nav className="tm-paging-nav d-inline-block">
                          <ul>
                            Page
                            <li className="tm-paging-item active">
                              <a
                                href="#"
                                className="mb-2 tm-btn tm-paging-link"
                                style={{ margin: "10px" }}>
                                {currentPage + 1}/{pageCount}
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="projects" className="projects">
            <div className="container" data-aos="fade-up">
              <div className="section-header">
                <h2>Our Award Gallery</h2>
                <p>{our_award_gallery_text?.data?.attributes?.title} </p>
              </div>
              <div
                className="portfolio-isotope"
                data-portfolio-filter="*"
                data-portfolio-layout="masonry"
                data-portfolio-sort="original-order">
                {/* End Projects Filters */}

                <div>
                  {our_award_gallery?.data?.map((Z) => (
                    <div
                      key={Z.id}
                      className="row gy-4 portfolio-container"
                      data-aos="fade-up"
                      data-aos-delay={200}>
                      {Z.attributes?.image?.data?.map((y) => (
                        <div
                          key={y.id}
                          className="col-lg-4 col-md-6 portfolio-item filter-remodeling  gallery_anim">
                          <div>
                            <div className="portfolio-content h-100">
                              <img
                                src={
                                  process.env.REACT_APP_UPLOAD_URL +
                                  y.attributes?.url
                                }
                                className="img-fluid"
                                alt=""
                              />
                              <div className="portfolio-info">
                                <a
                                  href={
                                    process.env.REACT_APP_UPLOAD_URL +
                                    y.attributes?.url
                                  }
                                  title="Remodeling 1"
                                  data-gallery="portfolio-gallery-remodeling"
                                  className="preview-link">
                                  <i className="bi bi-zoom-in" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}

                  {/* End Projects Item */}
                  <div className="col-lg-4 col-md-6 portfolio-item filter-construction">
                    <div className="portfolio-content h-100">
                      <img
                        src="assets/img/projects/design- (13).jpg"
                        className="img-fluid"
                        alt=""
                      />
                      <div className="portfolio-info">
                        <h4>Construction 1</h4>
                        <p>Lorem ipsum, dolor sit amet consectetur</p>
                        <a
                          href="assets/img/projects/design- (13).jpg"
                          title="Construction 1"
                          data-gallery="portfolio-gallery-construction"
                          className="  preview-link">
                          <i className="bi bi-zoom-in" />
                        </a>
                        <a
                          href="project-details.html"
                          title="More Details"
                          className="details-link">
                          <i className="bi bi-link-45deg" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* End Projects Item */}
                  <div className="col-lg-4 col-md-6 portfolio-item filter-repairs">
                    <div className="portfolio-content h-100">
                      <img
                        src="assets/img/projects/design- (12).jpg"
                        className="img-fluid"
                        alt=""
                      />
                      <div className="portfolio-info">
                        <h4>Repairs 1</h4>
                        <p>Lorem ipsum, dolor sit amet consectetur</p>
                        <a
                          href="assets/img/projects/design- (12).jpg"
                          title="Repairs 1"
                          data-gallery="portfolio-gallery-repairs"
                          className="  preview-link">
                          <i className="bi bi-zoom-in" />
                        </a>
                        <a
                          href="project-details.html"
                          title="More Details"
                          className="details-link">
                          <i className="bi bi-link-45deg" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* End Projects Item */}
                  <div className="col-lg-4 col-md-6 portfolio-item filter-design">
                    <div className="portfolio-content h-100">
                      <img
                        src="assets/img/projects/design- (11).jpg"
                        className="img-fluid"
                        alt=""
                      />
                      <div className="portfolio-info">
                        <h4>Design 1</h4>
                        <p>Lorem ipsum, dolor sit amet consectetur</p>
                        <a
                          href="assets/img/projects/design- (11).jpg"
                          title="Repairs 1"
                          data-gallery="portfolio-gallery-book"
                          className="  preview-link">
                          <i className="bi bi-zoom-in" />
                        </a>
                        <a
                          href="project-details.html"
                          title="More Details"
                          className="details-link">
                          <i className="bi bi-link-45deg" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* End Projects Item */}
                  <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                    <div className="portfolio-content h-100">
                      <img
                        src="assets/img/projects/design- (10).jpg"
                        className="img-fluid"
                        alt=""
                      />
                      <div className="portfolio-info">
                        <h4>Remodeling 2</h4>
                        <p>Lorem ipsum, dolor sit amet consectetur</p>
                        <a
                          href="assets/img/projects/design- (10).jpg"
                          title="Remodeling 2"
                          data-gallery="portfolio-gallery-remodeling"
                          className="  preview-link">
                          <i className="bi bi-zoom-in" />
                        </a>
                        <a
                          href="project-details.html"
                          title="More Details"
                          className="details-link">
                          <i className="bi bi-link-45deg" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* End Projects Item */}
                  <div className="col-lg-4 col-md-6 portfolio-item filter-construction">
                    <div className="portfolio-content h-100">
                      <img
                        src="assets/img/projects/design- (9).jpg"
                        className="img-fluid"
                        alt=""
                      />
                      <div className="portfolio-info">
                        <h4>Construction 2</h4>
                        <p>Lorem ipsum, dolor sit amet consectetur</p>
                        <a
                          href="assets/img/projects/design- (9).jpg"
                          title="Construction 2"
                          data-gallery="portfolio-gallery-construction"
                          className="  preview-link">
                          <i className="bi bi-zoom-in" />
                        </a>
                        <a
                          href="project-details.html"
                          title="More Details"
                          className="details-link">
                          <i className="bi bi-link-45deg" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* End Projects Item */}
                  <div className="col-lg-4 col-md-6 portfolio-item filter-repairs">
                    <div className="portfolio-content h-100">
                      <img
                        src="assets/img/projects/design- (8).jpg"
                        className="img-fluid"
                        alt=""
                      />
                      <div className="portfolio-info">
                        <h4>Repairs 2</h4>
                        <p>Lorem ipsum, dolor sit amet consectetur</p>
                        <a
                          href="assets/img/projects/design- (8).jpg"
                          title="Repairs 2"
                          data-gallery="portfolio-gallery-repairs"
                          className="  preview-link">
                          <i className="bi bi-zoom-in" />
                        </a>
                        <a
                          href="project-details.html"
                          title="More Details"
                          className="details-link">
                          <i className="bi bi-link-45deg" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* End Projects Item */}
                  <div className="col-lg-4 col-md-6 portfolio-item filter-design">
                    <div className="portfolio-content h-100">
                      <img
                        src="assets/img/projects/design- (7).jpg"
                        className="img-fluid"
                        alt=""
                      />
                      <div className="portfolio-info">
                        <h4>Design 2</h4>
                        <p>Lorem ipsum, dolor sit amet consectetur</p>
                        <a
                          href="assets/img/projects/design- (7).jpg"
                          title="Repairs 2"
                          data-gallery="portfolio-gallery-book"
                          className="  preview-link">
                          <i className="bi bi-zoom-in" />
                        </a>
                        <a
                          href="project-details.html"
                          title="More Details"
                          className="details-link">
                          <i className="bi bi-link-45deg" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* End Projects Item */}
                  <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                    <div className="portfolio-content h-100">
                      <img
                        src="assets/img/projects/design- (6).jpg"
                        className="img-fluid"
                        alt=""
                      />
                      <div className="portfolio-info">
                        <h4>Remodeling 3</h4>
                        <p>Lorem ipsum, dolor sit amet consectetur</p>
                        <a
                          href="assets/img/projects/design- (6).jpg"
                          title="Remodeling 3"
                          data-gallery="portfolio-gallery-remodeling"
                          className="  preview-link">
                          <i className="bi bi-zoom-in" />
                        </a>
                        <a
                          href="project-details.html"
                          title="More Details"
                          className="details-link">
                          <i className="bi bi-link-45deg" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* End Projects Item */}
                  <div className="col-lg-4 col-md-6 portfolio-item filter-construction">
                    <div className="portfolio-content h-100">
                      <img
                        src="assets/img/projects/design- (5).jpg"
                        className="img-fluid"
                        alt=""
                      />
                      <div className="portfolio-info">
                        <h4>Construction 3</h4>
                        <p>Lorem ipsum, dolor sit amet consectetur</p>
                        <a
                          href="assets/img/projects/design- (5).jpg"
                          title="Construction 3"
                          data-gallery="portfolio-gallery-construction"
                          className="  preview-link">
                          <i className="bi bi-zoom-in" />
                        </a>
                        <a
                          href="project-details.html"
                          title="More Details"
                          className="details-link">
                          <i className="bi bi-link-45deg" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* End Projects Item */}
                  <div className="col-lg-4 col-md-6 portfolio-item filter-repairs">
                    <div className="portfolio-content h-100">
                      <img
                        src="assets/img/projects/design- (4).jpg"
                        className="img-fluid"
                        alt=""
                      />
                      <div className="portfolio-info">
                        <h4>Repairs 3</h4>
                        <p>Lorem ipsum, dolor sit amet consectetur</p>
                        <a
                          href="assets/img/projects/design- (4).jpg"
                          title="Repairs 2"
                          data-gallery="portfolio-gallery-repairs"
                          className="  preview-link">
                          <i className="bi bi-zoom-in" />
                        </a>
                        <a
                          href="project-details.html"
                          title="More Details"
                          className="details-link">
                          <i className="bi bi-link-45deg" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* End Projects Item */}
                  <div className="col-lg-4 col-md-6 portfolio-item filter-design">
                    <div className="portfolio-content h-100">
                      <img
                        src="assets/img/projects/design- (2).jpg"
                        className="img-fluid"
                        alt=""
                      />
                      <div className="portfolio-info">
                        <h4>Design 3</h4>
                        <p>Lorem ipsum, dolor sit amet consectetur</p>
                        <a
                          href="assets/img/projects/design- (2).jpg"
                          title="Repairs 3"
                          data-gallery="portfolio-gallery-book"
                          className="  preview-link">
                          <i className="bi bi-zoom-in" />
                        </a>
                        <a
                          href="project-details.html"
                          title="More Details"
                          className="details-link">
                          <i className="bi bi-link-45deg" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* End Projects Item */}
                </div>
                {/* End Projects Container */}
              </div>
            </div>
          </section>
          {/* End Our Projects Section */}
          {/* ======= Testimonials Section ======= */}
          <section id="testimonials" className="testimonials section-bg">
            <div className="container" data-aos="fade-up">
              <div className="section-header">
                <h2>Testimonials</h2>
                <p>
                  Here are some testimonials from previous award winners, who
                  have achieved great success in their careers after winning the
                  award
                </p>{" "}
              </div>
              <div className="slides-2 swiper">
                <div className="swiper-wrapper">
                  {world_class_testimonials?.data?.map((d) => (
                    <div key={d.id}>
                      <div className="swiper-slide">
                        <div className="testimonial-wrap">
                          <div className="testimonial-item">
                            <img
                              src={
                                process.env.REACT_APP_UPLOAD_URL +
                                d.attributes?.image?.data?.attributes?.url
                              }
                              className="testimonial-img"
                              alt=""
                            />
                            <h3>{d.attributes?.title}</h3>
                            <h4>{d.attributes?.category}</h4>
                            <div className="stars">
                              <i className="bi bi-star-fill" />
                              <i className="bi bi-star-fill" />
                              <i className="bi bi-star-fill" />
                              <i className="bi bi-star-fill" />
                              <i className="bi bi-star-fill" />
                            </div>
                            <p>
                              <i className="bi bi-quote quote-icon-left" />
                              {d.attributes?.desc}
                              <i className="bi bi-quote quote-icon-right" />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* End testimonial item */}
                </div>
                <div className="swiper-pagination" />
              </div>
            </div>
          </section>
          {/* End Testimonials Section */}
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}

        {/* End Footer */}

        {/* Vendor JS Files */}
        {/* Template Main JS File */}
      </>
    </div>
  );
};

export default Home;
