import React, { Component } from 'react'

import useFetch from "../hooks/useFetch";
import { makeRequest } from "../MakeReq/makeRequest";
import { useState } from "react";
import "./news.css"

const Abisiniyaindustrywinners = () => {
  const [PER_PAGE, setPER_PAGE] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  console.log(search, 'search')


    const header_image = useFetch(`/header-image?populate=*`);
    console.log(header_image, "header_image");

    const newsletter_highlight = useFetch(`/newsletters?sort=createdAt:desc&populate=*`) ;
    console.log(newsletter_highlight, "newsletter_highlight");


    const abissiniya_award_winners_list = useFetch(`/abissiniya-award-winners-lists?&populate=*`) ;
    console.log(abissiniya_award_winners_list, "abissiniya_award_winners_list");
    const world_class_winners_list = useFetch(`/world-class-winners-lists?&populate=*`) ;
    console.log(world_class_winners_list, "world_class_winners_list");
    const abissiniya_industry_award_winners_list = useFetch(`/abisiniya-industry-award-winner-lists?&populate=*`) ;
  console.log(abissiniya_industry_award_winners_list, "abissiniya_industry_award_winners_list");



  const pageCount = Math.ceil(abissiniya_industry_award_winners_list ?.data?.length / PER_PAGE);
  const offset = currentPage * PER_PAGE;
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  //console.log(selectedCats, "the search");
  //console.log(subcateg, "the search");
  const previousPage = () => {
    if (currentPage !== 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== pageCount - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const currentPageData = abissiniya_industry_award_winners_list?.data?.slice(offset, offset + PER_PAGE);

  console.log(currentPageData,"currentPageData")
    return (
        <div>
        <div
   className="breadcrumbs d-flex align-items-center"
   style={{
     backgroundImage: `url(${
       process.env.REACT_APP_UPLOAD_URL +
       header_image.data?.attributes?.image?.data?.attributes?.url
     })`,
   }}>
   <div
     className="container position-relative d-flex flex-column align-items-center"
     data-aos="fade">
     <h2>Abisiniya Industry Award Winners Lists</h2>
     <ol>
       <li>
         <a href="/">Home</a>
       </li>
       <li>Abisiniya Industry Award Winners Lists</li>
     </ol>
   </div>
 </div>
 {/* End Breadcrumbs */}
 {/* ======= About Section ======= */}
<div style={{paddingLeft:"10%", paddingRight:"10%",paddingTop:"4%"}}>
{/* Topbar Start */}
<div className="container-fluid d-none d-lg-block" >
<div className="row align-items-center bg-dark px-lg-5">


</div>

</div>
{/* Topbar End */}
{/* Navbar Start */}

{/* Navbar End */}
{/* Main News Slider Start */}
<div className="container-fluid">
            <div className="row">
            <form method="GET" className="form-inline tm-mb-80 tm-search-form"   >
            <input
              className="form-control tm-search-input"
             name="search"
              type="text"
              onChange={(e) => setSearch(e.target.value)}
              placeholder=" Search Blogs Here..."
              aria-label="Search"
            />
            <button className="tm-search-button" type="button">
              <i className="fas fa-search tm-search-icon" aria-hidden="true" />
            </button>
          </form>
<div className="col-lg-7 px-0">

</div>

</div>
</div>
{/* Main News Slider End */}
{/* Breaking News Start */}

{/* Breaking News End */}

{/* News With Sidebar Start */}
<div className="container-fluid"  >
<div className="container">
<div className="row">
<div className="col-lg-12">
 <div className="row">
   <div className="col-12">
     <div className="section-title">
       <h4 className="m-0 text-uppercase font-weight-bold">
       Abisiniya Industry Award Winners Lists
       </h4>
       <a
         className="text-secondary font-weight-medium text-decoration-none"
         href=""
       >
         View All
       </a>
     </div>
                                    </div>
                                    {
                                       currentPageData?.filter((item) => {
                                        return search.toLowerCase() === " "
                                          ? item
                                          : item.attributes?.name?.toLowerCase().includes(search);
                                      }).map(x =>
                                            <div className="col-lg-4" key={x.id}>
                                            <div className="position-relative mb-3">
                                              <img
                                                className="img-fluid w-100"
                                                        src={x.attributes?.image?.data ? process.env.REACT_APP_UPLOAD_URL + x.attributes?.image?.data[0]?.attributes?.url : ""}

                                                style={{ objectFit: "cover",height:"200px" }}
                                              />
                                              <div className="bg-white border border-top-0 p-4">
                                                <div className="mb-2">
                                                  <a
                                                    className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2"
                                                    href=""
                                                  >
                                                  {x.attributes?.category}
                                                  </a>
                                                  <a className="text-body" href="">
                                                                <small> { Date(x.attributes?.year)}</small>
                                                  </a>
                                                </div>
                                                <a
                                                  className="h4 d-block mb-3 text-secondary text-uppercase font-weight-bold"
                                                  href=""
                                                >
                                                 {x.attributes?.name}
                                                </a>
                                                <p className="m-0">
                                                  {x.attributes?.desc?.slice(0,200)}
                                                </p>
                                              </div>
                                              <div className="d-flex justify-content-between bg-white border border-top-0 p-4">
                                                <div className="d-flex align-items-center">
                                                  <img
                                                    className="rounded-circle mr-2"
                                                    src={x.attributes?.image?.data ? process.env.REACT_APP_UPLOAD_URL + x.attributes?.image?.data[0]?.attributes?.url : ""}
                                                    width={25}
                                                    height={25}
                                                    alt=""
                                                  />
                                                  <small>      {x.attributes?.name}</small>
                                                </div>

                                              </div>
                                            </div>
                                          </div>
)
                                    }




                  </div>
                  <div className="row" style={{ padding: "3rem" }}>
                  <div  >
                  <div className="row tm-row tm-mt-100 tm-mb-75">
        <div className="tm-prev-next-wrapper  ">
          <a
            href="#"
            className="mb-2 tm-btn tm-btn-primary tm-prev-next   tm-mr-20" onClick={previousPage}
          >
            Prev
          </a>
          <a href="#" className="mb-2 tm-btn tm-btn-primary tm-prev-next" onClick={nextPage}>
            Next
          </a>
        </div>
        <div className="tm-paging-wrapper  ">
          <span className="d-inline-block mr-3">    </span>
          <nav className="tm-paging-nav d-inline-block">
            <ul>
            Page
              <li className="tm-paging-item active">
                <a href="#" className="mb-2 tm-btn tm-paging-link" style={{margin:"10px"}}>
                {currentPage + 1}/{pageCount}
                </a>
              </li>


            </ul>
          </nav>
            </div>


      </div>
                  </div>
                </div>
</div>


</div>
</div>
</div>

</div>

</div>
    )

}

export default Abisiniyaindustrywinners
