import React, { Component } from "react";
import axios from "axios";
import useFetch from "../hooks/useFetch";
import { makeRequest } from "../MakeReq/makeRequest";
import { useState, useEffect } from "react";

import { json, Navigate, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useRef } from "react";
import generatePDF from "react-to-pdf";
import "./register_asset/vendor/bootstrap/css/bootstrap.min.css";
import "./register_asset/fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "./register_asset/vendor/animate/animate.css";
import "./register_asset/vendor/css-hamburgers/hamburgers.min.css";
import "./register_asset/vendor/animsition/css/animsition.min.css";
import "./register_asset/vendor/select2/select2.min.css";
import "./register_asset/vendor/daterangepicker/daterangepicker.css";
import "./register_asset/css/util.css";
import "./register_asset/css/main.css";

import { UserData } from "../helper";
const Profile = () => {
  const targetRef = useRef();
 const navigate = useNavigate()
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    // Add more checkboxes as needed
  });

  const [Eligibility_Information_File, setEligibility_Information_File] =
    useState({ ref: "", refId: "", field: "", files: "" });
  const [allfiles, setallfiles] = useState({
    ref: "",
    refId: "",
    field: "",
    files: "",
  });

  // console.log(Eligibility_Information_File, "Eligibility_Information_File");

  const user = UserData();
  // console.log(user, "user");
  const header_image = useFetch(`/header-image?populate=*`);
  // console.log(header_image, "header_image");

  // Update the document title using the browser API
  const get_profile = useFetch(
    `/profiles?filters[Applicant_Organization_Address_Email][$eq]=${user?.email}`
  );

  // console.log(get_profile, "get_profile");

  const ID = get_profile?.data;
  const our_id = ID ? ID[0]?.id : "";
  // console.log(ID, "the id ");
  // console.log(our_id, "our_id");

  const handlefiles = async (event) => {
    // console.log("hello", event.target.files);

    setEligibility_Information_File({
      files: event.target.files[0],

      ref: "api::profile.profile",
      refId: our_id,
      field: "Eligibility_Information_File",
    });
  };
  const allhandlefiles = async (event) => {
    // console.log("hello", event.target.files);

    setallfiles({
      files: event.target.files[0],

      ref: "api::profile.profile",
      refId: our_id,
      field: "All_Files",
    });
  };
  const [profile, setprofile] = useState({
    Applicant_Organization_Name: "",
    Applicant_Organization_Address: "",
    Applicant_Organization_Address_Phone_No: "",
    Highest_Ranking_Official_Name: "",
    Highest_Ranking_Official_Designation: "",
    Highest_Ranking_Official_Address: "",
    Highest_Ranking_Official_Number: "",
    Highest_Ranking_Official_Email: "",
    Size_and_Location_of_the_Applicant_A1: "",
    Size_and_Location_of_the_Applicant_A2: "",
    Size_and_Location_of_the_Applicant_A3: "",
    Size_and_Location_of_the_Applicant_A4: "",
    Size_and_Location_of_the_Applicant_B1: "",
    Size_and_Location_of_the_Applicant_C1: "",
    Size_and_Location_of_the_Applicant_D1: "",
    Size_and_Location_of_the_Applicant_E1: "",
    Size_and_Location_of_the_Applicant_F1: "",
    Type_of_Industry_Manufacturing: "",
    Type_of_Industry_Business: "",
    Type_of_Industry_Others: "",
    Type_of_Industry_Education: "",
    Type_of_Industry_Agriculture: "",
    Type_of_Industry_Small_business: "",
    Type_of_Industry_Construction: "",
    Type_of_Industry_Industry: "",
    Type_of_Industry_Large_industry_business: "",
    Type_of_Industry_Service: "",
    Type_of_Industry_Indicate_others: "",
    Type_of_Industry_Profit: "",
    Type_of_Industry_Healthcare: "",
    Type_of_Industry_Governmental: "",
    Type_of_Industry_Non_profit: "",
    Type_of_Industry_Bank_transport: "",
    Type_of_Industry_Private: "",
    Application_History_A_Yes: "",
    Application_History_A_No: "",
    Organizational_Profile_A_Vision_Mission: "",
    Organizational_Profile_B_Product_Service: "",
    Organizational_Profile_C_Key_Customers: "",
    Organizational_Profile_D_Indicate_Main_Competitors: "",
    Organizational_Profile_E_Key_suppliers_partners: "",
    Eligibility_Information_A_Y: "",
    Eligibility_Information_A_N: "",
    Eligibility_Information_A_N_Text: "",
    Contact_Person_Name: "",
    Contact_Person_Address: "",
    Contact_Person_Email: "",
    Contact_Person_Designation: "",
    Contact_Person_Phone: "",

    Eligibility_Information_B_N: "",
    Eligibility_Information_B_Y: "",

    Eligibility_Information_C_N: "",
    Eligibility_Information_C_Y: "",
    Eligibility_Information_C_Y_Text: "",

    Eligibility_Information_D_Y: "",
    Eligibility_Information_D_N: "",

    Eligibility_Information_D_1_Text: "",
    Eligibility_Information_D_2_Text: "",
    Eligibility_Information_D_3_Text: "",
    Eligibility_Information_D_4_Text: "",

    Eligibility_Information_D_1: "",
    Eligibility_Information_D_2: "",
    Eligibility_Information_D_3: "",
    Eligibility_Information_D_4: "",

    Eligibility_Information_E_N: "",
    Eligibility_Information_E_Y: "",
    Eligibility_Information_F_N: "",
    Eligibility_Information_F_Y: "",
  });
  // console.log(profile, "profile");
  useEffect(() => {
    setprofile({
      Applicant_Organization_Name: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Applicant_Organization_Name
        : "",
      Applicant_Organization_Address: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Applicant_Organization_Address
        : "",
      Applicant_Organization_Address_Phone_No: get_profile?.data
        ? get_profile?.data[0]?.attributes
            ?.Applicant_Organization_Address_Phone_No
        : "",
      Highest_Ranking_Official_Name: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Highest_Ranking_Official_Name
        : "",
      Highest_Ranking_Official_Designation: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Highest_Ranking_Official_Designation
        : "",
      Highest_Ranking_Official_Address: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Highest_Ranking_Official_Address
        : "",
      Highest_Ranking_Official_Number: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Highest_Ranking_Official_Number
        : "",
      Highest_Ranking_Official_Email: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Highest_Ranking_Official_Email
        : "",
      Size_and_Location_of_the_Applicant_A1: get_profile?.data
        ? get_profile?.data[0]?.attributes
            ?.Size_and_Location_of_the_Applicant_A1
        : "",
      Size_and_Location_of_the_Applicant_A2: get_profile?.data
        ? get_profile?.data[0]?.attributes
            ?.Size_and_Location_of_the_Applicant_A2
        : "",
      Size_and_Location_of_the_Applicant_A3: get_profile?.data
        ? get_profile?.data[0]?.attributes
            ?.Size_and_Location_of_the_Applicant_A3
        : "",
      Size_and_Location_of_the_Applicant_A4: get_profile?.data
        ? get_profile?.data[0]?.attributes
            ?.Size_and_Location_of_the_Applicant_A4
        : "",
      Size_and_Location_of_the_Applicant_B1: get_profile?.data
        ? get_profile?.data[0]?.attributes
            ?.Size_and_Location_of_the_Applicant_B1
        : "",
      Size_and_Location_of_the_Applicant_C1: get_profile?.data
        ? get_profile?.data[0]?.attributes
            ?.Size_and_Location_of_the_Applicant_C1
        : "",
      Size_and_Location_of_the_Applicant_D1: get_profile?.data
        ? get_profile?.data[0]?.attributes
            ?.Size_and_Location_of_the_Applicant_D1
        : "",
      Size_and_Location_of_the_Applicant_E1: get_profile?.data
        ? get_profile?.data[0]?.attributes
            ?.Size_and_Location_of_the_Applicant_E1
        : "",
      Size_and_Location_of_the_Applicant_F1: get_profile?.data
        ? get_profile?.data[0]?.attributes
            ?.Size_and_Location_of_the_Applicant_F1
        : "",
      Type_of_Industry_Manufacturing:false/*  get_profile?.data
        ? get_profile?.data[0]?.attributes?.Type_of_Industry_Manufacturing ? true :false
        : "" */,
      Type_of_Industry_Business: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Type_of_Industry_Business
        : "",
      Type_of_Industry_Others: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Type_of_Industry_Others
        : "",
      Type_of_Industry_Education: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Type_of_Industry_Education
        : "",
      Type_of_Industry_Agriculture: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Type_of_Industry_Agriculture
        : "",
      Type_of_Industry_Small_business: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Type_of_Industry_Small_business
        : "",
      Type_of_Industry_Construction: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Type_of_Industry_Construction
        : "",
      Type_of_Industry_Industry: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Type_of_Industry_Industry
        : "",
      Type_of_Industry_Large_industry_business: get_profile?.data
        ? get_profile?.data[0]?.attributes
            ?.Type_of_Industry_Large_industry_business
        : "",
      Type_of_Industry_Service: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Type_of_Industry_Service
        : "",
      Type_of_Industry_Indicate_others: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Type_of_Industry_Indicate_others
        : "",
      Type_of_Industry_Profit: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Type_of_Industry_Profit
        : "",
      Type_of_Industry_Healthcare: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Type_of_Industry_Healthcare
        : "",
      Type_of_Industry_Governmental: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Type_of_Industry_Governmental
        : "",
      Type_of_Industry_Non_profit: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Type_of_Industry_Non_profit
        : "",
      Type_of_Industry_Bank_transport: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Type_of_Industry_Bank_transport
        : "",
      Type_of_Industry_Private: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Type_of_Industry_Private
        : "",
      Application_History_A_Yes: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Application_History_A_Yes
        : "",
      Application_History_A_No: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Application_History_A_No
        : "",
      Organizational_Profile_A_Vision_Mission: get_profile?.data
        ? get_profile?.data[0]?.attributes
            ?.Organizational_Profile_A_Vision_Mission
        : "",
      Organizational_Profile_B_Product_Service: get_profile?.data
        ? get_profile?.data[0]?.attributes
            ?.Organizational_Profile_B_Product_Service
        : "",
      Organizational_Profile_C_Key_Customers: get_profile?.data
        ? get_profile?.data[0]?.attributes
            ?.Organizational_Profile_C_Key_Customers
        : "",
      Organizational_Profile_D_Indicate_Main_Competitors: get_profile?.data
        ? get_profile?.data[0]?.attributes
            ?.Organizational_Profile_D_Indicate_Main_Competitors
        : "",
      Organizational_Profile_E_Key_suppliers_partners: get_profile?.data
        ? get_profile?.data[0]?.attributes
            ?.Organizational_Profile_E_Key_suppliers_partners
        : "",
      Eligibility_Information_A_Y: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Eligibility_Information_A_Y
        : "",
      Eligibility_Information_A_N: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Eligibility_Information_A_N
        : "",
      Eligibility_Information_A_N_Text: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Eligibility_Information_A_N_Text
        : "",

      Contact_Person_Name: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Contact_Person_Name
        : "",
      Contact_Person_Address: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Contact_Person_Address
        : "",
      Contact_Person_Email: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Contact_Person_Email
        : "",
      Contact_Person_Designation: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Contact_Person_Designation
        : "",

      Contact_Person_Phone: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Contact_Person_Phone
        : "",

      Eligibility_Information_B_N: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Eligibility_Information_B_N
        : "",
      Eligibility_Information_B_Y: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Eligibility_Information_B_Y
        : "",

      Eligibility_Information_C_N: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Eligibility_Information_C_N
        : "",

      Eligibility_Information_C_Y: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Eligibility_Information_C_Y
        : "",

      Eligibility_Information_C_Y_Text: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Eligibility_Information_C_Y_Text
        : "",

      Eligibility_Information_D_1_Text: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Eligibility_Information_D_1_Text
        : "",

      Eligibility_Information_D_2_Text: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Eligibility_Information_D_2_Text
        : "",

      Eligibility_Information_D_3_Text: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Eligibility_Information_D_3_Text
        : "",
      Eligibility_Information_D_4_Text: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Eligibility_Information_D_4_Text
        : "",

      Eligibility_Information_D_1: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Eligibility_Information_D_1
        : "",

      Eligibility_Information_D_2: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Eligibility_Information_D_2
        : "",
      Eligibility_Information_D_3: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Eligibility_Information_D_3
        : "",
      Eligibility_Information_D_4: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Eligibility_Information_D_4
        : "",

      Eligibility_Information_E_N: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Eligibility_Information_E_N
        : "",

      Eligibility_Information_E_Y: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Eligibility_Information_E_Y
        : "",
      Eligibility_Information_F_N: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Eligibility_Information_F_N
        : "",
      Eligibility_Information_F_Y: get_profile?.data
        ? get_profile?.data[0]?.attributes?.Eligibility_Information_F_Y
        : "",
    });
  }, [ID]);
  const profile_url = process.env.REACT_APP_API_URL + `/profiles/${our_id}`;

  const validationSchema = Yup.object().shape({
    Applicant_Organization_Name: Yup.string(),
    Applicant_Organization_Address: Yup.string(),

    Applicant_Organization_Address_Phone_No: Yup.string(),
    Highest_Ranking_Official_Name: Yup.string(),
    Highest_Ranking_Official_Designation: Yup.string(),
    Highest_Ranking_Official_Address: Yup.string(),
    Highest_Ranking_Official_Number: Yup.string(),
    Highest_Ranking_Official_Email: Yup.string(),

    Size_and_Location_of_the_Applicant_A1: Yup.string(),
    Organizational_Profile_A_Vision_Mission: Yup.string(),
    Organizational_Profile_B_Product_Service: Yup.string(),
    Organizational_Profile_C_Key_Customers: Yup.string(),
    Organizational_Profile_D_Indicate_Main_Competitors: Yup.string(),
    Organizational_Profile_E_Key_suppliers_partners: Yup.string(),
    Eligibility_Information_A_Y: Yup.string(),
    Eligibility_Information_A_N: Yup.string(),

    Eligibility_Information_A_N_Text: Yup.string(),
    Contact_Person_Name: Yup.string(),
    Contact_Person_Address: Yup.string(),
    Contact_Person_Email: Yup.string(),
    Contact_Person_Designation: Yup.string(),
    Contact_Person_Phone: Yup.string(),
    Eligibility_Information_B_N: Yup.string(),
    Eligibility_Information_B_Y: Yup.string(),

    Eligibility_Information_C_N: Yup.string(),
    Eligibility_Information_C_Y: Yup.string(),
    Eligibility_Information_C_Y_Text: Yup.string(),
  });
  console.log(profile.
    Type_of_Industry_Manufacturing, "updated profile Type_of_Industry_Manufacturing");

  const handleInputChange = (event) => {

    const { name, value } = event.target;
    setprofile({
      ...profile,
      [name]: value,
    });
  };

  const handleCheckboxChange = (checkboxName) => {
    setprofile({
      ...profile,
      [checkboxName]: !profile[checkboxName],
    });
  };
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const upload_url = process.env.REACT_APP_API_URL + `/upload`;
  // console.log(Eligibility_Information_File.files, "file upload");

  const file_upload = async (event) => {
    const form = new FormData();
    form.append("files", Eligibility_Information_File.files);

    try {
      await axios.post(upload_url, Eligibility_Information_File, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "bearer " + process.env.REACT_APP_API_TOKEN,
        },
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          document.getElementById(
            "percent"
          ).innerHTML = `file uploaded${percentCompleted}%`;
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  const allfile_upload = async (event) => {
    const form = new FormData();

    try {
      await axios.post(upload_url, allfiles, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "bearer " + process.env.REACT_APP_API_TOKEN,
        },
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          document.getElementById(
            "uploadpercent"
          ).innerHTML = `file uploaded${percentCompleted}%`;
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  const onSubmit =   (profile,e) => {
e.preventDefault()
    // display form aa on success
    // console.log(profile, "post data");

    try {
      if (profile) {
        console.log(profile, "post data");
        const { data } =   axios.put(
          profile_url,
          { data: profile },
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: "bearer " + process.env.REACT_APP_API_TOKEN,
            },
          }
        );
        // console.log(data, "data");


      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <div>
      {" "}
      <div>
        <>
          <main id="main">
            {/* ======= Breadcrumbs ======= */}
            <div
              className="breadcrumbs d-flex align-items-center"
              style={{
                backgroundImage: `url(${
                  process.env.REACT_APP_UPLOAD_URL +
                  header_image.data?.attributes?.image?.data?.attributes?.url
                })`,
              }}>
              <div
                className="container position-relative d-flex flex-column align-items-center"
                data-aos="fade">
                <h2>Profile</h2>
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>Profile</li>
                </ol>
              </div>
            </div>

            {/* End Breadcrumbs */}
            {/* ======= Contact Section ======= */}
            {ID ? (
              <section id="contact" className="contact body ">
                <div className="row" style={{ padding: "4rem" }}>
                  <div ref={targetRef}>
                    <form  onSubmit={handleSubmit(onSubmit)} className="row">
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1.3rem",
                        }}>
                        1. Applicant Organization / አመልካቹ ድርጅት
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="wrap-input100 validate-input">
                          <input
                            className="input100"
                            type="text"
                            {...register("Applicant_Organization_Name")}
                            name="Applicant_Organization_Name"
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Applicant_Organization_Name
                                : profile?.Applicant_Organization_Name
                            }
                            onChange={handleInputChange}
                          />
                          <span className="focus-input100" />
                          <span
                            className="label-input100"
                            style={{ padding: "1rem" }}>
                            Name/ስም
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="wrap-input100 validate-input">
                          <input
                            className="input100"
                            type="text"
                            name="Applicant_Organization_Address"
                            {...register("Applicant_Organization_Address")}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Applicant_Organization_Address
                                : profile?.Applicant_Organization_Address
                            }
                            onChange={handleInputChange}
                          />
                          <span className="focus-input100" />
                          <span
                            className="label-input100"
                            style={{ padding: "1rem" }}>
                            Address / አድራሻ
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="wrap-input100 validate-input">
                          <input
                            className="input100"
                            type="text"
                            disabled
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Applicant_Organization_Address_Email
                                : profile?.Applicant_Organization_Address_Email
                            }
                          />
                          <span className="focus-input100" />
                          <span
                            className="label-input100"
                            style={{ padding: "1rem" }}>
                            Email /ኢሜል
                          </span>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div className="wrap-input100 validate-input">
                          <input
                            className="input100"
                            type="text"
                            name="Applicant_Organization_Address_Phone_No"
                            {...register(
                              "Applicant_Organization_Address_Phone_No"
                            )}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Applicant_Organization_Address_Phone_No
                                : profile?.Applicant_Organization_Address_Phone_No
                            }
                            onChange={handleInputChange}
                          />
                          <span className="focus-input100" />
                          <span
                            className="label-input100"
                            style={{ padding: "1rem" }}>
                            Phone Numbers/ ስልክ ቁጥር
                          </span>
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1.3rem",
                        }}>
                        2. Highest-Ranking Official / የድርጅቱ ከፍተኛ ባለስልጣን
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div className="wrap-input100 validate-input">
                          <input
                            className="input100"
                            type="text"
                            name="Highest_Ranking_Official_Name"
                            {...register("Highest_Ranking_Official_Name")}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Highest_Ranking_Official_Name
                                : profile?.Highest_Ranking_Official_Name
                            }
                            onChange={handleInputChange}
                          />
                          <span className="focus-input100" />
                          <span
                            className="label-input100"
                            style={{ padding: "1rem" }}>
                            Name /ስም
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="wrap-input100 validate-input">
                          <input
                            className="input100"
                            type="text"
                            name="Highest_Ranking_Official_Designation"
                            {...register(
                              "Highest_Ranking_Official_Designation"
                            )}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Highest_Ranking_Official_Designation
                                : profile?.Highest_Ranking_Official_Designation
                            }
                            onChange={handleInputChange}
                          />
                          <span className="focus-input100" />
                          <span
                            className="label-input100"
                            style={{ padding: "1rem" }}>
                            Designation / ማእረግ
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="wrap-input100 validate-input">
                          <input
                            className="input100"
                            type="text"
                            name="Highest_Ranking_Official_Address"
                            {...register("Highest_Ranking_Official_Address")}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Highest_Ranking_Official_Address
                                : profile?.Highest_Ranking_Official_Address
                            }
                            onChange={handleInputChange}
                          />
                          <span className="focus-input100" />
                          <span
                            className="label-input100"
                            style={{ padding: "1rem" }}>
                            Address / አድራሻ
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="wrap-input100 validate-input">
                          <input
                            className="input100"
                            type="text"
                            name="Highest_Ranking_Official_Number"
                            {...register("Highest_Ranking_Official_Number")}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Highest_Ranking_Official_Number
                                : profile?.Highest_Ranking_Official_Number
                            }
                            onChange={handleInputChange}
                          />
                          <span className="focus-input100" />
                          <span
                            className="label-input100"
                            style={{ padding: "1rem" }}>
                            Telephone Number / ስልክ ቁጥር
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="wrap-input100 validate-input">
                          <input
                            className="input100"
                            type="text"
                            name="Highest_Ranking_Official_Email"
                            {...register("Highest_Ranking_Official_Email")}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Highest_Ranking_Official_Email
                                : profile?.Highest_Ranking_Official_Email
                            }
                            onChange={handleInputChange}
                          />
                          <span className="focus-input100" />
                          <span
                            className="label-input100"
                            style={{ padding: "1rem" }}>
                            Email/ኢሜል
                          </span>
                        </div>
                      </div>

                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1.3rem",
                        }}>
                        3. Size and Location of the Applicant / የድርጅቱ ደረጃ/መጠን እና
                        ድርጅቱ የሚገኝበት ቦታ
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}>
                        (A) For the preceding fiscal year, the organization had
                        an asset size of Birr (Choose one) / ባለፈው በጀት አመት ድርጅቱ
                        በብር የሚገለጽ ሃብት ነበረው (አንዱን ትክለኛ አማራጭ ይምረጡ)
                      </div>

                      <div className="col-md-3 col-lg-3">
                        <label htmlFor="Size_and_Location_of_the_Applicant_A1">
                          ≤ 3M/ሚ
                        </label>
                        <input
                          type="checkbox"
                          name="Size_and_Location_of_the_Applicant_A1"
                          {...register("Size_and_Location_of_the_Applicant_A1")}
                          checked={
                            profile?.Size_and_Location_of_the_Applicant_A1
                              ? true
                              : false
                          }
                          value={
                            profile?.Size_and_Location_of_the_Applicant_A1
                              ? true
                              : false
                          }
                          onChange={() =>
                            handleCheckboxChange(
                              "Size_and_Location_of_the_Applicant_A1"
                            )
                          }
                        />

                        {}
                      </div>
                      <div className="col-md-3 col-lg-3">
                        {" "}
                        <label htmlFor="Size_and_Location_of_the_Applicant_A2">
                          > 3 M/ሚ but/ ነገር ግን/ ≤ 15 M/ሚ
                        </label>
                        <input
                          type="checkbox"
                          name="Size_and_Location_of_the_Applicant_A2"
                          {...register("Size_and_Location_of_the_Applicant_A2")}
                          checked={
                            profile?.Size_and_Location_of_the_Applicant_A2
                              ? true
                              : false
                          }
                          onChange={() =>
                            handleCheckboxChange(
                              "Size_and_Location_of_the_Applicant_A2"
                            )
                          }
                        />
                      </div>
                      <div className="col-md-3 col-lg-3">
                        {" "}
                        <label htmlFor="Size_and_Location_of_the_Applicant_A3">
                          > 15M/ሚ but/ነገር ግን ≤ 100 M/ሚ
                        </label>
                        <input
                          type="checkbox"
                          name="Size_and_Location_of_the_Applicant_A3"
                          {...register("Size_and_Location_of_the_Applicant_A3")}
                          checked={
                            profile?.Size_and_Location_of_the_Applicant_A3
                              ? true
                              : false
                          }
                          onChange={() =>
                            handleCheckboxChange(
                              "Size_and_Location_of_the_Applicant_A3"
                            )
                          }
                        />
                      </div>
                      <div className="col-md-3 col-lg-3">
                        {" "}
                        <label htmlFor="labSize_and_Location_of_the_Applicant_A4el101">
                          > 100 M/ሚ
                        </label>
                        <input
                          type="checkbox"
                          name="Size_and_Location_of_the_Applicant_A4"
                          {...register("Size_and_Location_of_the_Applicant_A4")}
                          checked={
                            profile?.Size_and_Location_of_the_Applicant_A4
                              ? true
                              : false
                          }
                          onChange={() =>
                            handleCheckboxChange(
                              "Size_and_Location_of_the_Applicant_A4"
                            )
                          }
                        />
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}>
                        B Number of Branches in the Ethiopia/ኢትዮጵያ ውስጥ ያሉት
                        ቅርንጫፎች ብዛት
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div className="wrap-input100 validate-input">
                          <input
                            className="input100"
                            type="text"
                            name="Size_and_Location_of_the_Applicant_B1"
                            {...register(
                              "Size_and_Location_of_the_Applicant_B1"
                            )}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Size_and_Location_of_the_Applicant_B1
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}>
                        C Number of Overseas (if applicable) / ከኢትዮጵያ ውጭ ቅርንጫፎች
                        ካሉት ብዛታቸው
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div className="wrap-input100 validate-input">
                          <input
                            className="input100"
                            type="text"
                            name="Size_and_Location_of_the_Applicant_C1"
                            {...register(
                              "Size_and_Location_of_the_Applicant_C1"
                            )}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Size_and_Location_of_the_Applicant_C1
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}>
                        D Total number of employees / ጠቅላላ የሰራተኞች ብዛት
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div className="wrap-input100 validate-input">
                          <input
                            className="input100"
                            type="text"
                            name="Size_and_Location_of_the_Applicant_D1"
                            {...register(
                              "Size_and_Location_of_the_Applicant_D1"
                            )}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Size_and_Location_of_the_Applicant_D1
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}>
                        E Total number of employees in Ethiopia / ኢትዮጵያ ውስጥ የሚገኙ
                        ጠቅላላ የሰራተኞች ብዛት
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div className="wrap-input100 validate-input">
                          <input
                            className="input100"
                            type="text"
                            name="Size_and_Location_of_the_Applicant_E1"
                            {...register(
                              "Size_and_Location_of_the_Applicant_E1"
                            )}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Size_and_Location_of_the_Applicant_E1
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}>
                        F Percent of physical assets in the Ethiopia / ኢትዮጵያ ውስጥ
                        ያለው የሃብት መጠን በ%
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div className="wrap-input100 validate-input">
                          <input
                            className="input100"
                            type="text"
                            name="Size_and_Location_of_the_Applicant_F1"
                            {...register(
                              "Size_and_Location_of_the_Applicant_F1"
                            )}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Size_and_Location_of_the_Applicant_F1
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1.3rem",
                        }}>
                        4. Type of Industry / የኢንዱስትሪ አይነት
                      </div>

                      <div className="col-md-2 col-lg-2">
                        <div>
                          <label htmlFor="input100">
                            Manufacturing/ አምራች{" "}
                            <input
                              type="checkbox"
                              {...register("Type_of_Industry_Manufacturing")}

                              checked={
                              profile.Type_of_Industry_Manufacturing   }



                              onChange={() =>
                                handleCheckboxChange(
                                  "Type_of_Industry_Manufacturing"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                            {   get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Type_of_Industry_Manufacturing == true ? "Selected" :"Not Selected"
                                : ""}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div>
                          <label htmlFor="input100">
                            Education/ ትምህርት{" "}
                            <input
                              type="checkbox"
                              {...register("Type_of_Industry_Education")}
                              checked={
                                profile?.Type_of_Industry_Education
                                  ? true
                                  : false
                              }
                              defaultValue={profile?.Type_of_Industry_Education}
                              onChange={() =>
                                handleCheckboxChange(
                                  "Type_of_Industry_Education"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div>
                          <label htmlFor="input100">
                            Construction/ ኮንስትራክሽን{" "}
                            <input
                              type="checkbox"
                              {...register("Type_of_Industry_Construction")}
                              checked={
                                profile?.Type_of_Industry_Construction
                                  ? true
                                  : false
                              }
                              defaultValue={
                                profile?.Type_of_Industry_Construction
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Type_of_Industry_Construction"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div>
                          <label htmlFor="input100">
                            Service/ አገልግሎት{" "}
                            <input
                              type="checkbox"
                              {...register("Type_of_Industry_Service")}
                              checked={
                                profile?.Type_of_Industry_Service ? true : false
                              }
                              defaultValue={profile?.Type_of_Industry_Service}
                              onChange={() =>
                                handleCheckboxChange("Type_of_Industry_Service")
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div>
                          <label htmlFor="input100">
                            Healthcare /ጤና{" "}
                            <input
                              type="checkbox"
                              {...register("Type_of_Industry_Healthcare")}
                              checked={
                                profile?.Type_of_Industry_Healthcare
                                  ? true
                                  : false
                              }
                              defaultValue={
                                profile?.Type_of_Industry_Healthcare
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Type_of_Industry_Healthcare"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div>
                          <label htmlFor="input100">
                            Bank , transport{" "}
                            <input
                              type="checkbox"
                              {...register("Type_of_Industry_Bank_transport")}
                              checked={
                                profile?.Type_of_Industry_Bank_transport
                                  ? true
                                  : false
                              }
                              defaultValue={
                                profile?.Type_of_Industry_Bank_transport
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Type_of_Industry_Bank_transport"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div>
                          <label htmlFor="input100">
                            Business / trading{" "}
                            <input
                              type="checkbox"
                              {...register("Type_of_Industry_Business")}
                              checked={
                                profile?.Type_of_Industry_Business
                                  ? true
                                  : false
                              }
                              defaultValue={profile?.Type_of_Industry_Business}
                              onChange={() =>
                                handleCheckboxChange(
                                  "Type_of_Industry_Business"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div>
                          <label htmlFor="input100">
                            Agriculture / እርሻ{" "}
                            <input
                              type="checkbox"
                              {...register("Type_of_Industry_Agriculture")}
                              checked={
                                profile?.Type_of_Industry_Agriculture
                                  ? true
                                  : false
                              }
                              defaultValue={
                                profile?.Type_of_Industry_Agriculture
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Type_of_Industry_Agriculture"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div>
                          <label htmlFor="input100">
                            Industry{" "}
                            <input
                              type="checkbox"
                              {...register("Type_of_Industry_Industry")}
                              checked={
                                profile?.Type_of_Industry_Industry
                                  ? true
                                  : false
                              }
                              defaultValue={profile?.Type_of_Industry_Industry}
                              onChange={() =>
                                handleCheckboxChange(
                                  "Type_of_Industry_Industry"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div>
                          <label htmlFor="input100">
                            Indicate others / ሌሎች ከሆነ ይገለጽ/{" "}
                            <input
                              type="checkbox"
                              {...register("Type_of_Industry_Indicate_others")}
                              checked={
                                profile?.Type_of_Industry_Indicate_others
                                  ? true
                                  : false
                              }
                              defaultValue={
                                profile?.Type_of_Industry_Indicate_others
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Type_of_Industry_Indicate_others"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>

                      <div
                        className="col-md-2 col-lg-2"
                        style={{ paddingTop: "0.8rem" }}>
                        <div>
                          <label htmlFor="input100">
                            Governmental/
                            <input
                              type="checkbox"
                              {...register("Type_of_Industry_Governmental")}
                              checked={
                                profile?.Type_of_Industry_Governmental
                                  ? true
                                  : false
                              }
                              defaultValue={
                                profile?.Type_of_Industry_Governmental
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Type_of_Industry_Governmental"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div>
                          <label htmlFor="input100">
                            Private /
                            <input
                              type="checkbox"
                              {...register("Type_of_Industry_Private")}
                              checked={
                                profile?.Type_of_Industry_Private ? true : false
                              }
                              defaultValue={profile?.Type_of_Industry_Private}
                              onChange={() =>
                                handleCheckboxChange("Type_of_Industry_Private")
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div>
                          <label htmlFor="input100">
                            Others
                            <input
                              type="checkbox"
                              {...register("Type_of_Industry_Others")}
                              checked={
                                profile?.Type_of_Industry_Others ? true : false
                              }
                              defaultValue={profile?.Type_of_Industry_Others}
                              onChange={() =>
                                handleCheckboxChange("Type_of_Industry_Others")
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div>
                          <label htmlFor="input100">
                            Small business /
                            <input
                              type="checkbox"
                              {...register("Type_of_Industry_Small_business")}
                              checked={
                                profile?.Type_of_Industry_Small_business
                                  ? true
                                  : false
                              }
                              defaultValue={
                                profile?.Type_of_Industry_Small_business
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Type_of_Industry_Small_business"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>

                      <div className="col-md-2 col-lg-2">
                        <div>
                          <label htmlFor="input100">
                            Large industry business/
                            <input
                              type="checkbox"
                              {...register(
                                "Type_of_Industry_Large_industry_business"
                              )}
                              checked={
                                profile?.Type_of_Industry_Large_industry_business
                                  ? true
                                  : false
                              }
                              defaultValue={
                                profile?.Type_of_Industry_Large_industry_business
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Type_of_Industry_Large_industry_business"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div>
                          <label htmlFor="input100">
                            Profit /
                            <input
                              type="checkbox"
                              {...register("Type_of_Industry_Profit")}
                              checked={
                                profile?.Type_of_Industry_Profit ? true : false
                              }
                              defaultValue={profile?.Type_of_Industry_Profit}
                              onChange={() =>
                                handleCheckboxChange("Type_of_Industry_Profit")
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div>
                          <label htmlFor="input100">
                            Non-profit/
                            <input
                              type="checkbox"
                              {...register("Type_of_Industry_Non_profit")}
                              checked={
                                profile?.Type_of_Industry_Non_profit
                                  ? true
                                  : false
                              }
                              defaultValue={
                                profile?.Type_of_Industry_Non_profit
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Type_of_Industry_Non_profit"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1.3rem",
                        }}>
                        5. Application History / ቀደምት የማመልከቻ ታሪክ
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}>
                        (a) Has your organization previously submitted an
                        Eligibility and Registration Information Form?/ ድርጅቱ
                        ከአሁን በፊት የብቁነት መወሰኛና የምዝገባ ማመልከቻ አቅርቦ ያውቃልን?
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div style={{ display: "flex", flexDirection: "" }}>
                          <label htmlFor="input100">
                            YES አዎ
                            <input
                              type="checkbox"
                              name="Application_History_A_Yes"
                              {...register("Application_History_A_Yes")}
                              checked={
                                profile?.Application_History_A_Yes
                                  ? true
                                  : false
                              }
                              defaultValue={profile?.Application_History_A_Yes}
                              onChange={() =>
                                handleCheckboxChange(
                                  "Application_History_A_Yes"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div className="wrap-input100 validate-input">
                          <input
                            className="input100"
                            type="text"
                            {...register("Application_History_A_Yes_Text")}
                            name="Application_History_A_Yes_Text"
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Application_History_A_Yes_Text
                                : profile?.Application_History_A_Yes_Text
                            }
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div style={{ display: "flex", flexDirection: "" }}>
                          <label htmlFor="input100">
                            NO / ተሰጥቶት አያውቅም
                            <input
                              type="checkbox"
                              name="Application_History_A_No"
                              {...register("Application_History_A_No")}
                              checked={
                                profile?.Application_History_A_No ? true : false
                              }
                              defaultValue={profile?.Application_History_A_No}
                              onChange={() =>
                                handleCheckboxChange("Application_History_A_No")
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1.3rem",
                        }}>
                        6. Organizational Profile / ድርጅታዊ መግለጫ Provide a brief
                        description of the following key business/organizational
                        factors. Please be concise, but be as specific as
                        possible / በሚከተሉት ንግድ ወይም ድርጅታዊ ነባራዊ ሁኔታ መግለጫ ይቅረብ።
                        መግለጫው አጭር ነገር ግን ቀጥተኛ ይሁን።.
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}>
                        (a) Vision and Mission Statements / የራዕይና ተልእኮ መግለጫዎች
                      </div>
                      <div className="col-md-4 col-lg-8">
                        <div className="wrap-input100 validate-input">
                          <textarea
                            type="text"
                            name="Organizational_Profile_A_Vision_Mission"
                            {...register(
                              "Organizational_Profile_A_Vision_Mission"
                            )}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Organizational_Profile_A_Vision_Mission
                                : ""
                            }
                            style={{
                              width: "100%",
                              height: "150%",
                              padding: "1rem",
                              boxSizing: "border-box",
                              border: "2px solid #FFD700",
                              borderRadius: "4px",
                              backgroundColor: "#f8f8f8",
                              fontSize: "16px",
                              resize: "none",
                            }}></textarea>
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}>
                        (b) Main products and/or services and major markets
                        served (national and international). / ዋና ምርቶች እና/ወይም
                        አገልግሎቶች እንዲሁም ንግድ የሚከውንባቸው ዋና ዋና ገበያዎች (አገር አቀፍ ወይም
                        አለምአቀፍ) ይገለጹ።
                      </div>
                      <div className="col-md-4 col-lg-8">
                        <div className="wrap-input100 validate-input">
                          <textarea
                            type="text"
                            name="Organizational_Profile_B_Product_Service"
                            {...register(
                              "Organizational_Profile_B_Product_Service"
                            )}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Organizational_Profile_B_Product_Service
                                : ""
                            }
                            style={{
                              width: "100%",
                              height: "150%",
                              padding: "1rem",
                              boxSizing: "border-box",
                              border: "2px solid #FFD700",
                              borderRadius: "4px",
                              backgroundColor: "#f8f8f8",
                              fontSize: "16px",
                              resize: "none",
                            }}></textarea>
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}>
                        (c) Key customers/users / ዋና ዋና ደንበኞች ይገለጹ
                      </div>
                      <div className="col-md-4 col-lg-8">
                        <div className="wrap-input100 validate-input">
                          <textarea
                            type="text"
                            name="Organizational_Profile_C_Key_Customers"
                            {...register(
                              "Organizational_Profile_C_Key_Customers"
                            )}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Organizational_Profile_C_Key_Customers
                                : ""
                            }
                            style={{
                              width: "100%",
                              height: "150%",
                              padding: "1rem",
                              boxSizing: "border-box",
                              border: "2px solid #FFD700",
                              borderRadius: "4px",
                              backgroundColor: "#f8f8f8",
                              fontSize: "16px",
                              resize: "none",
                            }}></textarea>
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}>
                        (d) Indicate main competitors / ዋና ዋና ተፎካካሪዎች ይገለጹ
                      </div>

                      <div className="col-md-4 col-lg-8">
                        <div className="wrap-input100 validate-input">
                          <textarea
                            type="text"
                            name="Organizational_Profile_D_Indicate_Main_Competitors"
                            {...register(
                              "Organizational_Profile_D_Indicate_Main_Competitors"
                            )}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Organizational_Profile_D_Indicate_Main_Competitors
                                : ""
                            }
                            style={{
                              width: "100%",
                              height: "150%",
                              padding: "1rem",
                              boxSizing: "border-box",
                              border: "2px solid #FFD700",
                              borderRadius: "4px",
                              backgroundColor: "#f8f8f8",
                              fontSize: "16px",
                              resize: "none",
                            }}></textarea>
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}>
                        (e) Key suppliers/partners / ቁልፍ አቅራቢዎችና ሸሪኮች ይገለጹ
                      </div>
                      <div className="col-md-4 col-lg-8">
                        <div className="wrap-input100 validate-input">
                          <textarea
                            type="text"
                            name="Organizational_Profile_E_Key_suppliers_partners"
                            {...register(
                              "Organizational_Profile_E_Key_suppliers_partners"
                            )}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Organizational_Profile_E_Key_suppliers_partners
                                : ""
                            }
                            style={{
                              width: "100%",
                              height: "150%",
                              padding: "1rem",
                              boxSizing: "border-box",
                              border: "2px solid #FFD700",
                              borderRadius: "4px",
                              backgroundColor: "#f8f8f8",
                              fontSize: "16px",
                              resize: "none",
                            }}></textarea>
                        </div>
                      </div>

                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1.3rem",
                        }}>
                        7. Branch /Site Listing and Descriptions / የቅርንጫፎች ስም
                        ዝርዝርና መግለጫዎች
                      </div>
                      <div className="row">
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              name="phone_number"
                              disabled
                            />
                            <span className="focus-input100" />
                            <span
                              className="label-input100"
                              style={{
                                color: "black",
                                fontSize: "0.8rem",
                              }}>
                              Branch/Site Name የቅርንጫፉ ስም
                            </span>
                          </div>
                        </div>
                        <div
                          className="col-md-6 col-lg-4"
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              name="phone_number"
                              disabled
                            />
                            <span className="focus-input100" />
                            <span
                              className="label-input100"
                              style={{
                                marginTop: "-0.2rem",
                                color: "black",
                                fontSize: "0.8rem",
                              }}>
                              Addresses (Region, City, Sub-City, Woreda, others
                              as appropriate) አድራሻዎች (ክልል ፣ ከተማ ፣ ክ/ከተማ ፣ ወረዳ
                              እንደ አግባብነቱ ሌሎች ይካተት)
                            </span>
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              name="phone_number"
                              disabled
                            />
                            <span className="focus-input100" />
                            <span
                              className="label-input100"
                              style={{
                                color: "black",
                                fontSize: "0.8rem",
                              }}>
                              Employees ሰራተኞች
                            </span>
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              name="phone_number"
                              disabled
                            />
                            <span className="focus-input100" />
                            <span
                              className="label-input100"
                              style={{
                                color: "black",
                                fontSize: "0.8rem",
                              }}>
                              Asset Size የሃብት መጠን
                            </span>
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              name="phone_number"
                              disabled
                            />
                            <span className="focus-input100" />
                            <span
                              className="label-input100"
                              style={{
                                marginTop: "-1rem",
                                color: "black",
                                fontSize: "0.8rem",
                              }}>
                              List Products or Services Being Offered /የምርቶች ወይም
                              የአገልግሎቶች አይነት ዝርዝር
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              value={1}
                              disabled
                              name="phone_number"
                            />
                            <span className="focus-input100" />
                            <span
                              className="label-input100"
                              style={{ padding: "1rem" }}></span>
                          </div>
                        </div>
                        <div
                          className="col-md-6 col-lg-4"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_1_Address")}
                              name="Branch_1_Address"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_1_Address
                                  : profile?.Branch_1_Address
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_1_Employees")}
                              name="Branch_1_Employees"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_1_Employees
                                  : profile?.Branch_1_Employees
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_1_Asset")}
                              name="Branch_1_Asset"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_1_Asset
                                  : profile?.Branch_1_Asset
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_1_List")}
                              name="Branch_1_List"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_1_List
                                  : profile?.Branch_1_List
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              value={2}
                              disabled
                              name="phone_number"
                            />
                            <span className="focus-input100" />
                            <span
                              className="label-input100"
                              style={{ padding: "1rem" }}></span>
                          </div>
                        </div>
                        <div
                          className="col-md-6 col-lg-4"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_2_Address")}
                              name="Branch_2_Address"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_2_Address
                                  : profile?.Branch_2_Address
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_2_Employees")}
                              name="Branch_2_Employees"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_2_Employees
                                  : profile?.Branch_2_Employees
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_2_Asset")}
                              name="Branch_2_Asset"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_2_Asset
                                  : profile?.Branch_2_Asset
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_2_List")}
                              name="Branch_2_List"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_2_List
                                  : profile?.Branch_2_List
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              value={3}
                              disabled
                              name="phone_number"
                            />
                            <span className="focus-input100" />
                            <span
                              className="label-input100"
                              style={{ padding: "1rem" }}></span>
                          </div>
                        </div>
                        <div
                          className="col-md-6 col-lg-4"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_3_Address")}
                              name="Branch_3_Address"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_3_Address
                                  : profile?.Branch_3_Address
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_3_Employees")}
                              name="Branch_3_Employees"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_3_Employees
                                  : profile?.Branch_3_Employees
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_3_Asset")}
                              name="Branch_3_Asset"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_3_Asset
                                  : profile?.Branch_3_Asset
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_3_List")}
                              name="Branch_3_List"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_3_List
                                  : profile?.Branch_3_List
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              value={4}
                              disabled
                              name="phone_number"
                            />
                            <span className="focus-input100" />
                            <span
                              className="label-input100"
                              style={{ padding: "1rem" }}></span>
                          </div>
                        </div>
                        <div
                          className="col-md-6 col-lg-4"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_4_Address")}
                              name="Branch_4_Address"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_4_Address
                                  : profile?.Branch_4_Address
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_4_Employees")}
                              name="Branch_4_Employees"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_4_Employees
                                  : profile?.Branch_4_Employees
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_4_Asset")}
                              name="Branch_4_Asset"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_4_Asset
                                  : profile?.Branch_4_Asset
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_4_List")}
                              name="Branch_4_List"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_4_List
                                  : profile?.Branch_4_List
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              value={5}
                              disabled
                              name="phone_number"
                            />
                            <span className="focus-input100" />
                            <span
                              className="label-input100"
                              style={{ padding: "1rem" }}></span>
                          </div>
                        </div>
                        <div
                          className="col-md-6 col-lg-4"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_5_Address")}
                              name="Branch_5_Address"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_5_Address
                                  : profile?.Branch_5_Address
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_5_Employees")}
                              name="Branch_5_Employees"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_5_Employees
                                  : profile?.Branch_5_Employees
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_5_Asset")}
                              name="Branch_5_Asset"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_5_Asset
                                  : profile?.Branch_5_Asset
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_5_List")}
                              name="Branch_5_List"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_5_List
                                  : profile?.Branch_5_List
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              value={6}
                              disabled
                              name="phone_number"
                            />
                            <span className="focus-input100" />
                            <span
                              className="label-input100"
                              style={{ padding: "1rem" }}></span>
                          </div>
                        </div>
                        <div
                          className="col-md-6 col-lg-4"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_6_Address")}
                              name="Branch_6_Address"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_6_Address
                                  : profile?.Branch_6_Address
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_6_Employees")}
                              name="Branch_6_Employees"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_6_Employees
                                  : profile?.Branch_6_Employees
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_6_Asset")}
                              name="Branch_6_Asset"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_6_Asset
                                  : profile?.Branch_6_Asset
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_6_List")}
                              name="Branch_6_List"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_6_List
                                  : profile?.Branch_6_List
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              value={7}
                              disabled
                              name="phone_number"
                            />
                            <span className="focus-input100" />
                            <span
                              className="label-input100"
                              style={{ padding: "1rem" }}></span>
                          </div>
                        </div>
                        <div
                          className="col-md-6 col-lg-4"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_7_Address")}
                              name="Branch_7_Address"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_7_Address
                                  : profile?.Branch_7_Address
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_7_Employees")}
                              name="Branch_7_Employees"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_7_Employees
                                  : profile?.Branch_7_Employees
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_7_Asset")}
                              name="Branch_7_Asset"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_7_Asset
                                  : profile?.Branch_7_Asset
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_7_List")}
                              name="Branch_7_List"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_7_List
                                  : profile?.Branch_7_List
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              value={8}
                              disabled
                              name="phone_number"
                            />
                            <span className="focus-input100" />
                            <span
                              className="label-input100"
                              style={{ padding: "1rem" }}></span>
                          </div>
                        </div>
                        <div
                          className="col-md-6 col-lg-4"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_8_Address")}
                              name="Branch_8_Address"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_8_Address
                                  : profile?.Branch_8_Address
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_8_Employees")}
                              name="Branch_8_Employees"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_8_Employees
                                  : profile?.Branch_8_Employees
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_8_Asset")}
                              name="Branch_8_Asset"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_8_Asset
                                  : profile?.Branch_8_Asset
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_8_List")}
                              name="Branch_8_List"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_8_List
                                  : profile?.Branch_8_List
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              value={9}
                              disabled
                              name="phone_number"
                            />
                            <span className="focus-input100" />
                            <span
                              className="label-input100"
                              style={{ padding: "1rem" }}></span>
                          </div>
                        </div>
                        <div
                          className="col-md-6 col-lg-4"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_9_Address")}
                              name="Branch_9_Address"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_9_Address
                                  : profile?.Branch_9_Address
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_9_Employees")}
                              name="Branch_9_Employees"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_9_Employees
                                  : profile?.Branch_9_Employees
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_9_Asset")}
                              name="Branch_9_Asset"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_9_Asset
                                  : profile?.Branch_9_Asset
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_9_List")}
                              name="Branch_9_List"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_9_List
                                  : profile?.Branch_9_List
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              value={10}
                              disabled
                              name="phone_number"
                            />
                            <span className="focus-input100" />
                            <span
                              className="label-input100"
                              style={{ padding: "1rem" }}></span>
                          </div>
                        </div>
                        <div
                          className="col-md-6 col-lg-4"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_10_Address")}
                              name="Branch_10_Address"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_10_Address
                                  : profile?.Branch_10_Address
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_10_Employees")}
                              name="Branch_10_Employees"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_10_Employees
                                  : profile?.Branch_10_Employees
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{
                            fontSize: "0.8rem",
                          }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_10_Asset")}
                              name="Branch_10_Asset"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_10_Asset
                                  : profile?.Branch_10_Asset
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-2"
                          style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                          <div className="wrap-input100 validate-input">
                            <input
                              className="input100"
                              type="text"
                              {...register("Branch_10_List")}
                              name="Branch_10_List"
                              defaultValue={
                                get_profile?.data
                                  ? get_profile?.data[0].attributes
                                      ?.Branch_10_List
                                  : profile?.Branch_10_List
                              }
                              onChange={handleInputChange}
                            />
                            <span className="focus-input100" />
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1.3rem",
                        }}>
                        8. Eligibility Information / የመወዳደር ብቁነትን ለመወሰን አስፈላጊ
                        የሆኑ መረጃዎች
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}>
                        (a) Has the applicant officially or legally existed and
                        operational for at least three (3) years? / ድርጅቱ በህግ
                        ተደራጅቶ ቢያንስ ላለፉት ሶስት አመታት በስራ ላይ ነበርን?
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div style={{ display: "flex", flexDirection: "" }}>
                          <label htmlFor="input100">
                            YES አዎ
                            <input
                              type="checkbox"
                              {...register("Eligibility_Information_A_Y")}
                              checked={
                                profile?.Eligibility_Information_A_Y
                                  ? true
                                  : false
                              }
                              defaultValue={
                                profile?.Eligibility_Information_A_Y
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Eligibility_Information_A_Y"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-6">
                        <div style={{ display: "flex", flexDirection: "" }}>
                          <label htmlFor="input100">
                            NO / ተሰጥቶት አያውቅም
                            <input
                              type="checkbox"
                              {...register("Eligibility_Information_A_N")}
                              checked={
                                profile?.Eligibility_Information_A_N
                                  ? true
                                  : false
                              }
                              defaultValue={
                                profile?.Eligibility_Information_A_N
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Eligibility_Information_A_N"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                            <br></br>If No, Briefly explain / ካልነበረ ስለምክንያቱ አጭር
                            መግለጫ ይቅረብ.
                          </label>
                        </div>

                        <div className="col-md-12 col-lg-12">
                          <textarea
                            type="text"
                            name="Eligibility_Information_A_N_Text"
                            {...register("Eligibility_Information_A_N_Text")}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Eligibility_Information_A_N_Text
                                : ""
                            }
                            style={{
                              width: "100%",
                              height: "150%",
                              padding: "1rem",
                              boxSizing: "border-box",
                              border: "2px solid #FFD700",
                              borderRadius: "4px",
                              backgroundColor: "#f8f8f8",
                              fontSize: "16px",
                              resize: "none",
                            }}></textarea>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-12">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "",
                          }}>
                          <label
                            htmlFor="input100"
                            style={{ fontWeight: "bold" }}>
                            (Attach Organizational Chart / ድርጅታዊ መዋቅር ተያያዞ
                            ይቅረብ.)
                            <div
                              id="percent"
                              style={{ padding: "0.1rem" }}></div>
                            <input
                              type="file"
                              name="files"
                              onChange={handlefiles}
                              style={{ marginTop: "1rem" }}
                            />
                            <br></br>
                            <button
                              type="button"
                              class="btn btn-success"
                              style={{
                                marginTop: "1rem",
                                backgroundColor: "#FFB900",
                                border: "1px solid  #FFB900",
                              }}
                              onClick={(e) => {
                                file_upload();
                              }}>
                              UPLOAD FILE
                            </button>
                          </label>
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}>
                        (b) Is your organization a distinct organization or a
                        business unit headquartered and registered in Ethiopia?
                        የጥራት ሽልማት እንዲሰጠው ያመለከተው ድርጅት ራሱን የቻለ ነው ወይስ ዋና መስሪያ ቤቱ
                        ኢትዮጵያ ውስጥ የሚገኝ በህግ የተመዘገበ ድርጅት ንኡስ አካል?
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div style={{ display: "flex", flexDirection: "" }}>
                          <label htmlFor="input100">
                            YES / አዎ
                            <input
                              type="checkbox"
                              {...register("Eligibility_Information_B_Y")}
                              checked={
                                profile?.Eligibility_Information_B_Y
                                  ? true
                                  : false
                              }
                              defaultValue={
                                profile?.Eligibility_Information_B_Y
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Eligibility_Information_B_Y"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div style={{ display: "flex", flexDirection: "" }}>
                          <label htmlFor="input100">
                            NO / አይደለም
                            <input
                              type="checkbox"
                              {...register("Eligibility_Information_B_N")}
                              checked={
                                profile?.Eligibility_Information_B_N
                                  ? true
                                  : false
                              }
                              defaultValue={
                                profile?.Eligibility_Information_B_N
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Eligibility_Information_B_N"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}>
                        (c) Is your organization complying with all relevant
                        statutory and regulatory requirements? / ድርጅቱ የሚመለከቱትን
                        ሁሉንም የህግና ደንብ መስፈርቶች አሟልቶ የሚገኝ ነውን?
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div style={{ display: "flex", flexDirection: "" }}>
                          <label htmlFor="input100">
                            YES አዎ
                            <input
                              type="checkbox"
                              {...register("Eligibility_Information_C_Y")}
                              checked={
                                profile?.Eligibility_Information_C_Y
                                  ? true
                                  : false
                              }
                              defaultValue={
                                profile?.Eligibility_Information_C_Y
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Eligibility_Information_C_Y"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                            <br></br>List down all relevant statutory and
                            regulatory requirements. / ሁሉም አግባብነት ያሏቸው ህግና ደንቦች
                            ዝርዝር ይገለጽ።
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <textarea
                          type="text"
                          name="Eligibility_Information_C_Y_Text"
                          {...register("Eligibility_Information_C_Y_Text")}
                          defaultValue={
                            get_profile?.data
                              ? get_profile?.data[0].attributes
                                  ?.Eligibility_Information_C_Y_Text
                              : ""
                          }
                          style={{
                            width: "100%",
                            height: "150%",
                            padding: "1rem",
                            boxSizing: "border-box",
                            border: "2px solid #FFD700",
                            borderRadius: "4px",
                            backgroundColor: "#f8f8f8",
                            fontSize: "16px",
                            resize: "none",
                          }}></textarea>
                      </div>

                      <div className="col-md-2 col-lg-2">
                        <div style={{ display: "flex", flexDirection: "" }}>
                          <label htmlFor="input100">
                            NO / ተሰጥቶት አያውቅም
                            <input
                              type="checkbox"
                              {...register("Eligibility_Information_C_N")}
                              checked={
                                profile?.Eligibility_Information_C_N
                                  ? true
                                  : false
                              }
                              defaultValue={
                                profile?.Eligibility_Information_C_N
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Eligibility_Information_C_N"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1rem",
                          marginTop: "3rem",
                        }}>
                        (d) Does the organization has employees trained by AQA
                        Application Development Course organized by the AQA? /
                        ድርጅቱ በአቢሲኒያ የጥራት ሽልማት በማመልከቻዎች አሞላልና ራስን በራስ ግምገማ እንዴት
                        እንደሚካሄድ የሚሰጥ ስልጠና የወሰዱ ሰራተኞች አሉትን?
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div style={{ display: "flex", flexDirection: "" }}>
                          <label htmlFor="input100">
                            YES አዎ
                            <input
                              type="checkbox"
                              {...register("Eligibility_Information_D_Y")}
                              checked={
                                profile?.Eligibility_Information_D_Y
                                  ? true
                                  : false
                              }
                              defaultValue={
                                profile?.Eligibility_Information_D_Y
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Eligibility_Information_D_Y"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>

                      <div className="col-md-2 col-lg-2">
                        <div style={{ display: "flex", flexDirection: "" }}>
                          <label
                            htmlFor="input100"
                            style={{ paddingTop: "1rem" }}>
                            NO / ተሰጥቶት አያውቅም
                            <input
                              type="checkbox"
                              {...register("Eligibility_Information_D_N")}
                              checked={
                                profile?.Eligibility_Information_D_N
                                  ? true
                                  : false
                              }
                              defaultValue={
                                profile?.Eligibility_Information_D_N
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Eligibility_Information_D_N"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>

                      <div
                        className="row"
                        style={{ marginTop: "1rem", padding: "1rem" }}>
                        <div className="col-md-12 col-lg-12">
                          <div style={{ display: "flex", flexDirection: "" }}>
                            <label
                              htmlFor="input100"
                              style={{
                                fontWeight: "bold",
                                paddingTop: "1rem",
                                paddingBottom: "1rem",
                              }}>
                              የጥራት ስልጠና ላይ እንዲገኙለት የሚፈልጋቸው የሰራተኞች ክፍል
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-3" style={{ padding: "1rem" }}>
                          ከፍተኛ አመራሮች፡-{" "}
                          <input
                            type="checkbox"
                            {...register("Eligibility_Information_D_1")}
                            checked={
                              profile?.Eligibility_Information_D_1
                                ? true
                                : false
                            }
                            defaultValue={profile?.Eligibility_Information_D_1}
                            onChange={() =>
                              handleCheckboxChange(
                                "Eligibility_Information_D_1"
                              )
                            }
                          />
                          <br></br>
                          ብዛት-{" "}
                          <input
                            type="text"
                            {...register("Eligibility_Information_D_1_Text")}
                            name="Eligibility_Information_D_1_Text"
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Eligibility_Information_D_1_Text
                                : profile?.Eligibility_Information_D_1_Text
                            }
                            onChange={handleInputChange}
                            style={{
                              width: "5rem",
                              border: "1px solid 	#FFD700",
                              padding: "2px",
                              backgroundColor: "#f3f6f4",
                              borderRadius: "6px",
                            }}
                          />
                        </div>
                        <div className="col-sm-3" style={{ padding: "1rem" }}>
                          መከለኛ አመራሮች፡-{" "}
                          <input
                            type="checkbox"
                            {...register("Eligibility_Information_D_2")}
                            checked={
                              profile?.Eligibility_Information_D_2
                                ? true
                                : false
                            }
                            defaultValue={profile?.Eligibility_Information_D_2}
                            onChange={() =>
                              handleCheckboxChange(
                                "Eligibility_Information_D_2"
                              )
                            }
                          />
                          <br></br>
                          ብዛት-{" "}
                          <input
                            type="text"
                            {...register("Eligibility_Information_D_2_Text")}
                            name="Eligibility_Information_D_2_Text"
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Eligibility_Information_D_2_Text
                                : profile?.Eligibility_Information_D_2_Text
                            }
                            onChange={handleInputChange}
                            style={{
                              width: "5rem",
                              border: "1px solid 	#FFD700",
                              padding: "2px",
                              backgroundColor: "#f3f6f4",
                              borderRadius: "6px",
                            }}
                          />
                        </div>
                        <div className="col-sm-3" style={{ padding: "1rem" }}>
                          ቡድን መሪዎች፡-{" "}
                          <input
                            type="checkbox"
                            {...register("Eligibility_Information_D_3")}
                            checked={
                              profile?.Eligibility_Information_D_3
                                ? true
                                : false
                            }
                            defaultValue={profile?.Eligibility_Information_D_3}
                            onChange={() =>
                              handleCheckboxChange(
                                "Eligibility_Information_D_3"
                              )
                            }
                          />
                          <br></br>
                          ብዛት-{" "}
                          <input
                            type="text"
                            {...register("Eligibility_Information_D_3_Text")}
                            name="Eligibility_Information_D_3_Text"
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Eligibility_Information_D_3_Text
                                : profile?.Eligibility_Information_D_3_Text
                            }
                            onChange={handleInputChange}
                            style={{
                              width: "5rem",
                              border: "1px solid 	#FFD700",
                              padding: "2px",
                              backgroundColor: "#f3f6f4",
                              borderRadius: "6px",
                            }}
                          />
                        </div>
                        <div className="col-sm-3" style={{ padding: "1rem" }}>
                          ሰራተኞች፡-{" "}
                          <input
                            type="checkbox"
                            {...register("Eligibility_Information_D_4")}
                            checked={
                              profile?.Eligibility_Information_D_4
                                ? true
                                : false
                            }
                            defaultValue={profile?.Eligibility_Information_D_4}
                            onChange={() =>
                              handleCheckboxChange(
                                "Eligibility_Information_D_4"
                              )
                            }
                          />
                          <br></br>
                          ብዛት-{" "}
                          <input
                            type="text"
                            {...register("Eligibility_Information_D_4_Text")}
                            name="Eligibility_Information_D_4_Text"
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Eligibility_Information_D_4_Text
                                : profile?.Eligibility_Information_D_4_Text
                            }
                            onChange={handleInputChange}
                            style={{
                              width: "5rem",
                              border: "1px solid 	#FFD700",
                              padding: "2px",
                              backgroundColor: "#f3f6f4",
                              borderRadius: "6px",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}>
                        (e) Can your organization respond to all seven (7) AQA
                        Criteria categories?/ ድርጅቱ ራሱን ችሎ ሰባቱን የሽልማት መስፈርቶች መልስ
                        መስጠት ይችላልን?
                      </div>

                      <div className="col-md-2 col-lg-2">
                        <div style={{ display: "flex", flexDirection: "" }}>
                          <label
                            htmlFor="input100"
                            style={{ paddingTop: "1rem" }}>
                            YES / አዎ
                            <input
                              type="checkbox"
                              {...register("Eligibility_Information_E_Y")}
                              checked={
                                profile?.Eligibility_Information_E_Y
                                  ? true
                                  : false
                              }
                              defaultValue={
                                profile?.Eligibility_Information_E_Y
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Eligibility_Information_E_Y"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div style={{ display: "flex", flexDirection: "" }}>
                          <label
                            htmlFor="input100"
                            style={{ paddingTop: "1rem" }}>
                            NO / አይችልም
                            <input
                              type="checkbox"
                              {...register("Eligibility_Information_E_N")}
                              checked={
                                profile?.Eligibility_Information_E_N
                                  ? true
                                  : false
                              }
                              defaultValue={
                                profile?.Eligibility_Information_E_N
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Eligibility_Information_E_N"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}>
                        (f) Should your organization receives AQA or level
                        recognition, are you willing to share your practices?
                        ድርጅቱ የአቢሲኒያ የጥራት ሽልማት ወይም የእውቅና ደረጃ ቢሰጠው በሚዘጋጅ ወርክሾፕ
                        ተሞክሮዎቹን ለሌሎች ለማካፈል ዝግጁ ነውን?
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div style={{ display: "flex", flexDirection: "" }}>
                          <label
                            htmlFor="input100"
                            style={{ paddingTop: "1rem" }}>
                            YES / አዎ
                            <input
                              type="checkbox"
                              {...register("Eligibility_Information_F_Y")}
                              checked={
                                profile?.Eligibility_Information_F_Y
                                  ? true
                                  : false
                              }
                              defaultValue={
                                profile?.Eligibility_Information_F_Y
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Eligibility_Information_F_Y"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div style={{ display: "flex", flexDirection: "" }}>
                          <label
                            htmlFor="input100"
                            style={{ paddingTop: "1rem" }}>
                            NO / አይችልም
                            <input
                              type="checkbox"
                              {...register("Eligibility_Information_F_N")}
                              checked={
                                profile?.Eligibility_Information_F_N
                                  ? true
                                  : false
                              }
                              defaultValue={
                                profile?.Eligibility_Information_F_N
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Eligibility_Information_F_N"
                                )
                              }
                              style={{ marginLeft: "0.8rem" }}
                            />
                          </label>
                        </div>
                      </div>

                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1.3rem",
                        }}>
                        9. Contact Person / ድርጅትዎን በመወከል ከወርልድ ክላስ የጥራት ሽልማት
                        ድርጅት ጋር የስራ ግንኙነት የሚኖረው ባለስልጣን ይሰየም፡፡/
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="wrap-input100 validate-input">
                          <input
                            className="input100"
                            type="text"
                            name="Contact_Person_Name"
                            {...register("Contact_Person_Name")}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Contact_Person_Name
                                : profile?.Contact_Person_Name
                            }
                            onChange={handleInputChange}
                          />
                          <span className="focus-input100" />
                          <span
                            className="label-input100"
                            style={{ padding: "1rem" }}>
                            Name/ስም
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="wrap-input100 validate-input">
                          <input
                            className="input100"
                            name="Contact_Person_Designation"
                            {...register("Contact_Person_Designation")}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Contact_Person_Designation
                                : profile?.Contact_Person_Designation
                            }
                            onChange={handleInputChange}
                          />
                          <span className="focus-input100" />
                          <span
                            className="label-input100"
                            style={{ padding: "1rem" }}>
                            Designation / ማዕረግ
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="wrap-input100 validate-input">
                          <input
                            className="input100"
                            name="Contact_Person_Address"
                            {...register("Contact_Person_Address")}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Contact_Person_Address
                                : profile?.Contact_Person_Address
                            }
                            onChange={handleInputChange}
                          />
                          <span className="focus-input100" />
                          <span
                            className="label-input100"
                            style={{ padding: "1rem" }}>
                            Address / አድራሻ
                          </span>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div className="wrap-input100 validate-input">
                          <input
                            className="input100"
                            {...register("Contact_Person_Phone")}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Contact_Person_Phone
                                : profile?.Contact_Person_Phone
                            }
                            onChange={handleInputChange}
                          />
                          <span className="focus-input100" />
                          <span
                            className="label-input100"
                            style={{ padding: "1rem" }}>
                            Phone Numbers/ ስልክ ቁጥር
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="wrap-input100 validate-input">
                          <input
                            className="input100"
                            {...register("Contact_Person_Email")}
                            defaultValue={
                              get_profile?.data
                                ? get_profile?.data[0].attributes
                                    ?.Contact_Person_Email
                                : profile?.Contact_Person_Email
                            }
                            onChange={handleInputChange}
                          />
                          <span className="focus-input100" />
                          <span
                            className="label-input100"
                            style={{ padding: "1rem" }}>
                            Email / ኢሜል
                          </span>
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          padding: "2rem",
                          fontWeight: "bold",
                          fontSize: "1.3rem",
                        }}>
                        10. Signature of the Highest Ranking Official / የድርጅቱ
                        ከፍተኛ ባለስልጣን ፊርማ
                      </div>
                      <div
                        className="col-12"
                        style={{
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}>
                        I state and attest that to the best of my knowledge,
                        this information includes no untrue statement of a
                        material fact, and no material fact has been omitted.
                        <br></br> እኔ እስከማውቀው ድረስ ይህ ማመልከቻ ትክክለኛነት የጎደለው መረጃ
                        ያልያዘና በቅጹ የሚፈለጉ ማናቸውም መረጃዎች ተነጥለው ያልቀሩ መሆናቸውን አረጋገጣለሁ።
                      </div>
                      <div className="row" style={{ marginTop: "5rem" }}>
                        <div
                          className="col-sm-4 col-md-4 col-lg-4"
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}>
                          <div
                            style={{
                              width: "10rem",
                              borderBottom: "2px solid #FFD700",
                              alignItems: "center",
                            }}></div>
                          <div style={{ marginTop: "1rem" }}> Name/ ስም </div>
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-4">
                          <div
                            style={{
                              width: "10rem",
                              borderBottom: "2px solid #FFD700",
                            }}></div>
                          <div style={{ marginTop: "1rem" }}>
                            {" "}
                            Signature /ፊርማ
                          </div>
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-4">
                          <div
                            style={{
                              width: "10rem",
                              borderBottom: "2px solid #FFD700",
                            }}></div>
                          <div style={{ marginTop: "1rem" }}>Date/ ቀን</div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div
                            style={{ textAlign: "center", marginTop: "8rem" }}>
                            {" "}
                            (Stamp/ማህተም)
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <button

                            type="submit"
                            class="btn btn-primary"
                            style={{
                              marginTop: "1rem",
                              backgroundColor: "#FFB900",
                              border: "1px solid  #FFB900",
                            }}>
                            Save Document
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="col-12"
                    style={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      marginTop: "5rem",
                    }}>
                    Download the above form as Pdf and Upload the signed
                    document with stamp in pdf format. <br></br>
                    ከላይ ያለውን ቅጽ እንደ ፒዲኤፍ ያውርዱ እና የተፈረመውን በፒዲኤፍ ፎርማት ከማህተም ጋር
                    አፕሎድ ያድርጉ።
                  </div>
                  <div style={{ marginTop: "1.4rem" }}>
                    <button
                      type="button"
                      onClick={() =>
                        generatePDF(targetRef, { filename: "page.pdf" })
                      }
                      style={{
                        backgroundColor: "#FFB900",
                        border: "1px solid  #FFB900",
                        color: "white",
                        padding: "0.7rem",
                        borderRadius: "10px",
                      }}>
                      Download As Pdf
                    </button>
                  </div>
                  <div style={{ marginTop: "1.4rem", fontWeight: "bold" }}>
                    <label htmlFor="">Upload Signed Document</label>
                    <div id="uploadpercent"></div>
                    <input
                      type="file"
                      name="files"
                      onChange={allhandlefiles}
                      style={{ marginTop: "1rem" }}
                    />
                    <br></br>
                    <button
                      type="button"
                      class="btn btn-success"
                      style={{
                        marginTop: "1rem",
                        backgroundColor: "#FFB900",
                        border: "1px solid  #FFB900",
                      }}
                      onClick={(e) => {
                        allfile_upload();
                      }}>
                      Upload Signed Files
                    </button>
                  </div>
                </div>
              </section>
            ) : (
              "loading"
            )}

            {/* End Contact Section */}
          </main>
          {/* End #main */}
        </>
      </div>
    </div>
  );
};
export default Profile;
