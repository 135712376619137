import React, { Component } from "react";
import Logo from "../assets/codesphereinnovations.jpg"
const Foot = () => {
  return (
    <div>
      <footer id="footer" className="footer">
        <div className="footer-content position-relative">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="footer-info">
                  <h3>World Class Quality Award</h3>
                  <p>
                    Alemnesh plaza <br />
                    Bole, Addis Ababa, Ethiopia <br />
                    <br />
                    <strong>Phone:</strong>+251 911242388 / +251 911525998
                    <br />
                    <strong>Email:</strong> worldclassqualityaward@gmail.com
                    <br />
                  </p>
                  <div className="social-links d-flex mt-3">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center">
                      <i className="bi bi-twitter" />
                    </a>
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center">
                      <i className="bi bi-facebook" />
                    </a>
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center">
                      <i className="bi bi-instagram" />
                    </a>
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center">
                      <i className="bi bi-linkedin" />
                    </a>
                  </div>
                </div>
              </div>
              {/* End footer info column*/}
              <div className="col-lg-2 col-md-3 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <a href="#">About us</a>
                  </li>
                  <li>
                    <a href="#">Services</a>
                  </li>
                  <li>
                    <a href="#">Terms of service</a>
                  </li>
                  <li>
                    <a href="#">Privacy policy</a>
                  </li>
                </ul>
              </div>
              {/* End footer links column*/}
            </div>
            {/* End footer links column*/}
          </div>
        </div>
        <div className="footer-legal text-center position-relative">
          <div className="container">
            <div className="copyright">
              © Copyright{" "}
              <strong>
                <span>WORLD CLASS QUALITY AWARD</span>
              </strong>
              . All Rights Reserved
            </div>
            <div className="credits">
              Designed by{" "}
              <a href="https://codesphereinnovations.com/">Codesphere Innovations</a>

            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default Foot;
