import logo from "./logo.svg";

import { useState } from "react";
// import useFetch from "../hooks/useFetch";
import useFetch from "../src/hooks/useFetch";
import "./assets/vendor/bootstrap/css/bootstrap.min.css";

import "./assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "./assets/vendor/fontawesome-free/css/all.min.css";
// import "./assets/vendor/aos/aos.css"
import "./assets/vendor/glightbox/css/glightbox.min.css";
import "./assets/vendor/swiper/swiper-bundle.min.css";
import "./assets/css/main.css";

import "./assets/vendor/bootstrap/js/bootstrap.bundle.min.js";
import "./assets/vendor/aos/aos.js";
import "./assets/vendor/glightbox/js/glightbox.min.js";
import "./assets/vendor/isotope-layout/isotope.pkgd.min.js";
import "./assets/vendor/swiper/swiper-bundle.min.js";
import "./assets/vendor/purecounter/purecounter_vanilla.js";
import "./assets/vendor/php-email-form/validate.js";
import "./assets/js/main.js";

// import Advert from "../assets/img/photo_2024-07-19_05-19-19.jpg";
import Advert from "../src/assets/img/photo_2024-07-19_05-19-19.jpg";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActions,
} from "@mui/material";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Profile from "./pages/Profile";
import Abissiniyaawards from "./pages/Abissiniyaawards";
import Gallary from "./pages/Gallary";
import Blogs from "./pages/Blogs";
import Blog from "./pages/Blog";
import News from "./pages/News";
import Tnews from "./pages/Tnews";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Abissiniyaindustryaward from "./pages/Abissiniyaindustryaward";
import Nav from "./component/Nav";
import Foot from "./component/Foot";
import Worldclass from "./pages/Worldclass";
import Abisiniyaindustrywinners from "./pages/Abisiniyaindustrywinners";
import Abisiniyaawardwinners from "./pages/Abisiniyaawardwinners";

import Donate from "./pages/Donate";
import Certificate from "./pages/Certificate";
import { useNavigate, Link } from "react-router-dom";

import donate from "./assets/img/donate.png";

// console.log = console.warn = console.error = () => { };

function App() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const newsletter_highlight = useFetch(`/newsletters?sort=createdAt:desc&populate=*`) ;
  console.log(newsletter_highlight, "newsletter_highlight");

  return (
    <div className="App">
      <BrowserRouter>
        <Nav></Nav>
        <Routes>
          <Route path="/Donate" Component={Donate} />
          <Route path="/Home" Component={Home} />
          <Route path="/Certificate" Component={Certificate} />

          <Route path="/" Component={Abissiniyaindustryaward} />
          <Route path="/Register" Component={Register} />
          <Route path="/login" Component={Login} />
          <Route path="/profile" Component={Profile} />
          <Route path="/news" Component={News} />
          <Route path="/Tnews/:id" Component={Tnews} />
          <Route path="/blogs" Component={Blogs} />
          <Route path="/blog/:id" Component={Blog} />

          <Route path="/about" Component={About} />
          <Route path="/worldclass" Component={Worldclass} />
          <Route
            path="/Abisiniyaindustrywinners"
            Component={Abisiniyaindustrywinners}
          />
          <Route
            path="/Abisiniyaawardwinners"
            Component={Abisiniyaawardwinners}
          />
          <Route path="/abissiniyaawards" Component={Abissiniyaawards} />
          <Route path="/gallary" Component={Gallary} />
          <Route path="/contact" Component={Contact} />
        </Routes>
        {/* donation button  */}
        <div>
          <Link to="/Donate">
            {/* donate now */}
            <img
              src={donate}
              style={{
                maxWidth: 200,
                borderRadius: "10%",
                position: "fixed",
                maxWidth: "8rem",
                bottom: 4,
                right: 20,
                zIndex: 2,
              }}></img>
          </Link>
        </div>
        {/* donation button  */}
        <Link to="/news">


        <Card
          sx={{
            position: "fixed",
            maxWidth: 100,
backgroundColor:"transparent",
            bottom: 5,
            left: 5,
              zIndex: 2,

          }}>
      <div
            className="position-relative overflow-hidden"  style={{padding:10,borderRadius:10,}}

          >
            <img
              className="img-fluid h-1"
              src= { newsletter_highlight?.data? (process.env.REACT_APP_UPLOAD_URL + newsletter_highlight?.data[0]?.attributes?.image1?.data?.attributes?.url):("")}
              style={{ objectFit: "cover",fontSize:"0.5rem",borderRadius:10 }}
            />
            <div className="overlay">
              <div className="mb-2">
                {/* <a
                  className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2"
                  href="" style={{fontSize:"0.5rem"}}
                >
                  {newsletter_highlight?.data? newsletter_highlight?.data[0]?.attributes?.category : "Loading"}
                </a> */}

              </div>
              <a
                className="h3 m-0 text-white text-uppercase font-weight-bold"  style={{fontSize:"0.55rem"}}
                href=""
              >
                {newsletter_highlight?.data? newsletter_highlight?.data[0]?.attributes?.title : "Loading"}
              </a>
            </div>
          </div>
          <div
            className="position-relative overflow-hidden"   style={{padding:10,borderRadius:10}}


          >
            <img
              className="img-fluid h-5"
                src={newsletter_highlight?.data ? (process.env.REACT_APP_UPLOAD_URL + newsletter_highlight?.data[1]?.attributes?.image1?.data?.attributes?.url) : ("")}
                style={{ objectFit: "cover",fontSize:"0.5rem",borderRadius:10 }}

            />
            <div className="overlay">
              <div className="mb-2">
                {/* <a
                  className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2"
                  href="" style={{fontSize:"0.2rem"}}
                >
                  {newsletter_highlight?.data? newsletter_highlight?.data[1]?.attributes?.category : "Loading"}
                </a> */}

              </div>
              <a
                className="h2 m-0 text-white text-uppercase font-weight-bold"  style={{fontSize:"0.6rem"}}
                href=""
              >
                {newsletter_highlight?.data? newsletter_highlight?.data[1]?.attributes?.title : "Loading"}
              </a>
            </div>
          </div>
        </Card>
        </Link>


        <Dialog
          open={open}
          onClose={handleClose}
          style={{ position: "absolute", marginLeft: "0px", height: 800 }}>
          <img src={Advert} style={{ height: 600 }}></img>
          <Button onClick={handleClose}>
            close <CancelIcon></CancelIcon>
          </Button>
        </Dialog>




        <Foot></Foot>
      </BrowserRouter>
    </div>
  );
}

export default App;
