import React, { Component } from "react";
import { useState } from "react";
import useFetch from "../hooks/useFetch";
import axios from "axios";
// import theicon from "./register_asset/images/icons/favicon.ico";
import { storeUser } from "../helper";

import "./register_asset/vendor/bootstrap/css/bootstrap.min.css";
import "./register_asset/fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "./register_asset/vendor/animate/animate.css";
import "./register_asset/vendor/css-hamburgers/hamburgers.min.css";
import "./register_asset/vendor/animsition/css/animsition.min.css";
import "./register_asset/vendor/select2/select2.min.css";
import "./register_asset/vendor/daterangepicker/daterangepicker.css";
import "./register_asset/css/util.css";
import "./register_asset/css/main.css";

import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
const intialUser = {
  password: "",
  email: "",
  company_name: "",
  username: "",
  phone_number: "",
};

const Register = () => {
  const header_image = useFetch(`/header-image?populate=*`);
  // console.log(header_image, "header_image");

  const login_image = useFetch(`/login-image?populate=*`);
  // console.log(login_image, "login_image");

  const [user, SetUser] = useState({
    company_name: "",
    username: "",
    phone_number: "",
    email: "",
    password: "",
  });
  const handle = ({ target: { value } }) => SetUser(value);
  // console.log(user,'user')

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("User Name is required")
      .min(3, "User Name must be minimum 3 characters"),

    company_name: Yup.string()
      .required("Company Name is required")
      .min(3, "Company Name must be minimum 3 characters"),

    phone_number: Yup.string()
      .required("Phone Number is required")
      .min(7, "Phone Number is not valid")
      .max(13, "Phone Number is not valid"),

    email: Yup.string().required("Email is invalid"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  // console.log('register',register)
  const onSubmit = async (aa) => {
    // display form aa on success
    SetUser(aa);
    // console.log('aa',aa)
    const url = process.env.REACT_APP_API_URL + `/auth/local/register`;
    const profile_url = process.env.REACT_APP_API_URL + `/profiles`;

    try {
      if (aa.email && aa.password && aa.username) {
        const { data } = await axios.post(url, aa, {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "bearer " + process.env.REACT_APP_API_TOKEN,
          },
        });
        console.log(data, "data");

        if (data.jwt) {
          storeUser(data);
          SetUser(intialUser);
          if (aa.email) {
            axios.post(
              profile_url,
              { data: { Applicant_Organization_Address_Email: aa.email } },
              {
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  Authorization: "bearer " + process.env.REACT_APP_API_TOKEN,
                },
              }
            );
            console.log(data, "data");
          }
          navigate("/profile");
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  return (
    <div>
      {" "}
      <div>
        <>
          <main id="main">
            {/* ======= Breadcrumbs ======= */}
            <div
              className="breadcrumbs d-flex align-items-center"
              style={{
                backgroundImage: `url(${
                  process.env.REACT_APP_UPLOAD_URL +
                  header_image.data?.attributes?.image?.data?.attributes?.url
                })`,
              }}>
              <div
                className="container position-relative d-flex flex-column align-items-center"
                data-aos="fade">
                <h2>Sign up</h2>
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>Sign up</li>
                </ol>
              </div>
            </div>
            {/* End Breadcrumbs */}
            {/* ======= Contact Section ======= */}
            <section id="contact" className="contact body ">
              <div
                className="limiter"
                style={{ paddingLeft: "10%", paddingRight: "10%" }}>
                <div className="container-login100">
                  <div className="wrap-login100">
                    <form
                      className="login100-form "
                      onSubmit={handleSubmit(onSubmit)}>
                      <span className="login100-form-title p-b-43">
                        Register to continue
                      </span>
                      <div className="wrap-input100  ">
                        <input
                          type="text"
                          className="input100"
                          name="username"
                          {...register("username")}
                        />{" "}
                        <span className="focus-input100" />
                        <span className="label-input100">User Name</span>
                      </div>
                      {errors.username?.message}{" "}
                      <div className="wrap-input100  ">
                        <input
                          type="text"
                          className="input100"
                          name="company_name"
                          {...register("company_name")}
                        />{" "}
                        <span className="focus-input100" />
                        <span className="label-input100">Company Name</span>
                      </div>
                      {errors.company_name?.message}{" "}
                      <div className="wrap-input100  ">
                        <input
                          className="input100"
                          type="text"
                          name="email"
                          {...register("email", {
                            required: true,
                            pattern:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          })}
                        />
                        <span className="focus-input100" />
                        <span className="label-input100">Email</span>
                      </div>
                      {errors.email?.message}
                      <div className="wrap-input100  ">
                        <input
                          type="text"
                          className="input100"
                          name="phone_number"
                          {...register(
                            "phone_number",

                            {
                              required: true,
                            }
                          )}
                        />
                        <span className="focus-input100" />
                        <span className="label-input100">Phone Number</span>
                      </div>
                      {errors.phone_number?.message}
                      <div
                        className="wrap-input100  "
                        data-validate="Password is required">
                        <input
                          className="input100"
                          type="password"
                          name="pass"
                          {...register("password", {
                            required: true,
                            pattern:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          })}
                        />
                        <span className="focus-input100" />
                        <span className="label-input100">Password</span>
                      </div>
                      {errors.password?.message}
                      <div
                        className="wrap-input100  "
                        data-validate="Password is required">
                        <input
                          className="input100"
                          type="password"
                          {...register("confirmPassword")}
                        />
                        <span className="focus-input100" />
                        <span className="label-input100">Confirm Password</span>
                      </div>
                      {errors.confirmPassword?.message}
                      <div className="flex-sb-m w-full p-t-3 p-b-32">
                        <div className="contact100-form-checkbox"></div>
                        <div>
                          <Link to="/login">
                            <div className="txt1">
                              Already have an account? Sign in
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="container-login100-form-btn">
                        <button type="submit" className="login100-form-btn">
                          Register
                        </button>
                      </div>
                    </form>
                    <div
                      className="login100-more"
                      style={{
                        backgroundImage: `url(${
                          process.env.REACT_APP_UPLOAD_URL +
                          login_image?.data?.attributes?.image?.data?.attributes
                            ?.url
                        })`,
                      }}></div>
                  </div>
                </div>
              </div>
            </section>
            {/* End Contact Section */}
          </main>
          {/* End #main */}
        </>
      </div>
    </div>
  );
};

export default Register;
